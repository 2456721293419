import { Box, BoxProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import CircleCheckIcon from 'src/assets/icons/CircleCheckIcon';
import OutlinedCircleCheckIcon from 'src/assets/icons/OutlinedCircleCheckIcon';

interface ICreationFieldsWrapperProps extends BoxProps {
  isValid?: boolean;
  hideIcon?: boolean;
}

const CreationFieldsWrapper: FC<ICreationFieldsWrapperProps> = ({
  isValid,
  title,
  hideIcon = false,
  ...props
}) => {
  return (
    <Box
      {...props}
      className={clsx('p-6 rounded-xl', props.className)}
      bgcolor={isValid ? '#33B27F0D' : 'neutral.ne100'}
    >
      {(title || !hideIcon) && (
        <Box className='flex items-center justify-between mb-6'>
          {!!title && (
            <Typography variant='subtitle4' fontWeight={700} color={'primary.main'}>
              {title}
            </Typography>
          )}
          {hideIcon ? null : isValid ? (
            <CircleCheckIcon width={24} height={24} color='#33B27F' />
          ) : (
            <OutlinedCircleCheckIcon width={24} height={24} />
          )}
        </Box>
      )}

      {props.children}
    </Box>
  );
};

export default CreationFieldsWrapper;
