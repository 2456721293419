/* eslint-disable autofix/no-unused-vars */
import dayjs from 'dayjs';
import { IOptions } from 'src/components/atoms/FormMultiSelect';
import { BaseQuery } from 'src/modules/common/hooks';
import { IUploadFile } from 'src/modules/common/type';

export interface Fund {
  id: string;
  name: string;
  code: string;
  trusteeGPName: string;
  numberOfInvestors: number;
  fum: number;
  status: number;
  statusName: string;
  clientId?: string;
}

export interface IFundDetailParams {
  id?: string;
  name: string;
  abn?: string;
  code: string;
  tfn?: string;
  industryCode?: string;
  commencementDate?: string | dayjs.Dayjs;
  offerType: number | string;
  type: number | string;
  trustType?: number | string;
  subType?: number | string;
  mit?: boolean | number;
  amit?: boolean | number;
  clientId?: string;
  isSaveDraft?: boolean;
  ownershipPercentageLimit?: number | null;
  initialCloseDate?: string | null;
  lateInitialCloseDate?: string | null;
  currency?: number | null;
}

interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  contactNumber: string;
  countryFlagCode: string;
  isPrimary: boolean;
}

export interface IFundContactDetailParams {
  contacts: Contact[];
  investorContact?: IInvestorContact;
}

export interface IInvestorContact {
  id?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  organisationName?: string;
  contactName?: string;
  contactNumber?: string;
  countryFlagCode?: string;
  option?: number | string;
  investorContactType?: number | string;
}

export interface ISignatoryContactParams {
  isSaveDraft?: boolean;
  signatoryContactIds?: string[];
  signatoryPersons?: {
    firstName?: string;
    lastName?: string;
    position?: string;
    email?: string;
    contactNumber?: string;
    countryFlagCode?: string;
  }[];
}

export interface ISignatoryContact {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  contactNumber?: string;
  position?: string;
  countryFlagCode?: string;
  isSuperAdmin?: boolean;
  option?: number | string;
  contactName?: string;
  organisationName?: string;
}

export interface ISignatoryContactForm extends ISignatoryContact {
  isNew?: boolean;
  isSelected?: boolean;
}

export interface ISignatoryContactsForm {
  isSaveDraft?: boolean;
  signatoryContacts: ISignatoryContactForm[];
}

export interface ITrusteeAndServiceProvidersParams {
  isSaveDraft: boolean;
  serviceProviderIds: string[];
}

export interface IServiceProviderType {
  id: number;
  name: string;
}

interface IServiceProviderAddress {
  countryName: string;
  countryId: string;
  stateName: string;
  stateId: string;
  cityName: string;
  cityId: string;
  postcode: string;
  streetAddress: string;
  postalCountryName: string;
  postalCountryId: string;
  postalStateName: string;
  postalStateId: string;
  postalCityName: string;
  postalCityId: string;
  postalPostcode: string;
  postalStreetAddress: string;
  isUseSameAsPhysicalAddress: boolean;
  manualEntry: boolean;
  postalManualEntry: boolean;
}

interface IServiceProviderPrimaryContact {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber: string;
  countryFlagCode: string;
  clientId: string;
}

export interface IUpsertServiceProvider {
  id?: string;
  displayName: string;
  legalName: string;
  abn: string;
  type: number;
  capacities: number[];
  otherCapacity: string;
  address?: IServiceProviderAddress;
  primaryContacts: IServiceProviderPrimaryContact[];
}

export interface IServiceProvider extends IUpsertServiceProvider {}

export interface IExisting {
  id: string;
  displayName: string;
  capacityNames: string[];
  capacityIds: number | number[] | null;
}

export interface ITrusteeAndServiceProvidersCapacitiesRes {
  trusteeGpDetails: IServiceProviderType[];
  investmentManager: IServiceProviderType[];
  serviceProviders: IServiceProviderType[];
}

export enum ServiceProviderTypeEnum {
  TrusteeGpDetails,
  InvestmentManager,
  ServiceProviders,
}

export interface IGetExistingParams {
  serviceProviderType: number;
  clientId?: string;
  capacityIds?: number | string[];
}

export interface INewExisting {
  id: string;
  displayName: string;
  capacityNames: string[];
  capacityIds: number | number[] | null;
}

export interface ITrusteeAndServiceProvidersFormFields {
  trustee: {
    capacity: string;
    existing: string;
    newExisting: INewExisting[];
    isAddNew: boolean;
    type: number;
  };
  manager: {
    capacity: string;
    existing: string;
    newExisting: INewExisting[];
    isAddNew: boolean;
    type: number;
  };
  providers: {
    capacity: string[];
    existing: string[];
    newExisting: INewExisting[];
    type: number;
  };
  isSaveDraft: boolean;
  clientId: string;
}

export interface IUpsertProviderFormFields {
  id?: string;
  capacities: number | number[] | null;
  capacityNames: string[];
  otherCapacity: string;
  displayName: string;
  legalName: string;
  abn: string;
  primaryContacts: {
    firstName: string;
    lastName: string;
    position: string;
    email: string;
    phoneNumber: string;
    countryFlagCode: string;
    clientId: string;
    id?: string;
  }[];
  physicalAddress: {
    country: IOptions;
    state: IOptions;
    city: IOptions;
    postcode: string;
    streetAddress: string;
    manualEntry: boolean;
  };
  postalAddress: {
    country: IOptions;
    state: IOptions;
    city: IOptions;
    postcode: string;
    streetAddress: string;
    manualEntry: boolean;
  };
  isUseSameAsPhysicalAddress: boolean;
  isSaveDraft: boolean;
  serviceProvidersSection?: boolean;
  setAsInvestmentManager?: boolean;
  additionalCapacities?: number;
}

export interface ITrusteeGPParams {
  isSaveDraft: boolean;
  serviceProviders: {
    ids: string[];
    type: number;
    capacities: number[];
  }[];
}
export interface IFundOverviewAndBrading {
  description?: string;
  overview?: string;
  correspondenceDisclaimer?: string;
  fundContactId?: string;
  signatoryPerson?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    contactNumber?: string;
    position?: string;
    countryFlagCode?: string;
  };
  brandingDetails?: IBrandingDetail[];
  documents?: IDocument[];
}

export interface IBrandingDetail extends IUploadFile {
  brandingType: number;
  imgUrl?: string;
  link?: string;
}

export interface IDocument extends IUploadFile {
  id?: string;
  documentType: number;
  otherDescription?: string;
}

export interface FundQueryBody extends BaseQuery {
  statuses?: number[];
  fumRange?: { from?: string; to?: string };
}

interface Status {
  id: number;
  name: string;
}
export interface FundFilter {
  statuses: Status[];
}

export interface BankAccount {
  id?: string;
  nickName: string;
  bankName: string;
  accountName: string;
  bankStateBranchNumber: string;
  accountNumber: string;
  swiftCode: string;
}

export interface BPay {
  id?: string;
  nickName: string;
  billerCode: string;
  customerReferenceNumber: string;
}

export interface IFinancialDetailsParams {
  investorUnit: number;
  unitPrice: number;
  bankAccounts: BankAccount[];
  bPays: BPay[];
  isSaveDraft: boolean;
}

export interface IUpsertFundForm {
  isEditMode?: boolean;
  isViewMode?: boolean;
  isDraft?: boolean;
}

export interface IUnitClass {
  id: string;
  name: string;
  code: string;
  offerType: number;
  offerTypeName: string;
  currency: number;
  currencyName: string;
  numberOfInvestors: number;
  currentUnitPrice: number;
  numberOfUnits: number;
  fum: number;
  status: number;
  statusName: string;
  committedCapital: number;
}

export interface UnitClassQueryBody extends BaseQuery {
  statuses?: number[];
}

export interface UnitClassFilter {
  statuses: Status[];
}

export interface IUpsertFundTeamParams {
  internalTeams?: string[];
  clientTeams?: string[];
}

export interface ITeamUser extends ISignatoryContact {
  userType: number;
}
