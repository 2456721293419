import { Box, BoxProps, styled, Tab, Tabs } from '@mui/material';
import { forwardRef, ReactNode, SyntheticEvent, useImperativeHandle, useState } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  destroyInactivePanel?: boolean;
}

const CustomTabs = styled(Tabs)<{ isError?: boolean }>(({ isError, theme }) => ({
  '.Mui-selected': {
    color: `${isError ? theme.palette.error.main : theme.palette.success.main} !important`,
  },
  '.MuiTabs-indicator': {
    backgroundColor: `${
      isError ? theme.palette.error.main : theme.palette.success.main
    } !important`,
  },
}));

const CustomTab = styled(Tab)<{ isError?: boolean }>(({ isError, theme }) => ({
  fontSize: 16,
  flex: 1,
  color: isError ? theme.palette.error.main : '',
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, destroyInactivePanel, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className='tabpanel'
      {...other}
    >
      {destroyInactivePanel ? (
        value === index && (
          <Box sx={{ p: 3 }} component='div' className='tabpanel-wrapper'>
            {children}
          </Box>
        )
      ) : (
        <Box sx={{ p: 3 }} component='div' className='tabpanel-wrapper'>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface ITab {
  key?: string | number;
  label: React.ReactNode | string;
  content: React.ReactNode;
  isError?: boolean;
}

interface IHorizontalTabsProps extends BoxProps {
  tabs: ITab[];
  destroyInactivePanel?: boolean;
  onTabChange?: (tab: number) => void;
  defaultValue?: number;
}

export interface IHorizontalTabsElement {
  setTab: (tab: number) => void;
}

const HorizontalTabs = forwardRef<IHorizontalTabsElement, IHorizontalTabsProps>(
  ({ tabs, destroyInactivePanel = true, onTabChange, defaultValue, ...containerProps }, ref) => {
    const [value, setValue] = useState(defaultValue || 0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
      onTabChange && onTabChange(newValue);
    };

    useImperativeHandle(ref, () => ({
      setTab: (tab: number) => setValue(tab),
    }));

    return (
      <Box width={'100%'} {...containerProps}>
        <Box className='border-b tab-header' borderColor='divider'>
          <CustomTabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            centered
            isError={tabs?.[value]?.isError}
            indicatorColor='secondary'
            className='custom-horizontal-tab'
          >
            {tabs.map(
              (tab: { label: React.ReactNode | string; content: React.ReactNode }, index) => (
                <CustomTab
                  key={`horizontal-tab-${index}`}
                  label={tab.label}
                  {...a11yProps(index)}
                  isError={tabs?.[index]?.isError}
                />
              ),
            )}
          </CustomTabs>
        </Box>
        {tabs.map((tab: { label: React.ReactNode | string; content: React.ReactNode }, index) => (
          <TabPanel
            key={`horizontal-tabpanel-${index}`}
            value={value}
            index={index}
            destroyInactivePanel={destroyInactivePanel}
          >
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    );
  },
);

export default HorizontalTabs;
