import {
  Button,
  ButtonProps,
  CircularProgress,
  darken,
  styled,
  Theme,
  useTheme,
} from '@mui/material';
import { get, isEmpty, set } from 'lodash';
import { FC, useContext } from 'react';
import { BrandingContext } from 'src/providers/BrandingContextProvider';
import { isHexColor } from 'src/utils/common';
export interface ICustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  branding?: any;
}

type VariantType = 'contained' | 'outlined' | 'text';

const getStyles = (theme: Theme, variant: VariantType, sx: any) => {
  switch (variant) {
    case 'contained':
      return {
        path: {
          fill: sx?.color || theme.palette.neutral.ne400,
        },
        '&.Mui-disabled': {
          color: theme.palette.neutral.ne400,
          backgroundColor: theme.palette.neutral.ne200,
          path: {
            fill: theme.palette.neutral.ne400,
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.pr500,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.pr900,
        },
      };
    case 'outlined':
      return {
        color: theme.palette.primary.main,
        path: {
          fill: sx?.color || theme.palette.neutral.ne400,
        },
        '&.Mui-disabled': {
          color: theme.palette.neutral.ne400,
          backgroundColor: theme.palette.base.white,
          borderColor: theme.palette.neutral.ne400,
          path: {
            fill: theme.palette.neutral.ne400,
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.pr50,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.pr200,
        },
      };
    case 'text':
      return {
        color: theme.palette.primary.main,
        path: {
          fill: sx?.color || theme.palette.primary.main,
        },
        '&.Mui-disabled': {
          color: theme.palette.neutral.ne400,
          path: {
            fill: theme.palette.neutral.ne400,
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.pr50,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.pr200,
        },
      };
    default:
      break;
  }
};

const StyledButton = styled(Button)<{ variant: VariantType }>(({ theme, variant, sx }) => {
  return getStyles(theme, variant, sx);
});

const CustomButton: FC<ICustomButtonProps> = ({
  children,
  size,
  variant = 'contained',
  startIcon = null,
  isLoading,
  disabled = false,
  sx,
  branding: brandingProps,
  ...rest
}) => {
  const theme = useTheme();
  const { branding: brandingContext } = useContext(BrandingContext);
  const branding = !isEmpty(brandingProps) ? brandingProps : brandingContext;
  const defaultSize = startIcon ? 'medium' : 'large';

  let customSx = sx;

  if (typeof customSx === 'function') {
    customSx = customSx(theme);
  }
  const hover = {};
  if (variant === 'contained' && isHexColor(String(branding?.primaryBackground))) {
    set(hover, 'backgroundColor', darken(String(get(branding, 'primaryBackground')), 0.3));
  }
  if (get(customSx, 'backgroundColor') && isHexColor(String(get(customSx, 'backgroundColor')))) {
    set(hover, 'backgroundColor', darken(String(get(customSx, 'backgroundColor')), 0.3));
  }

  const getColor = () => {
    if (
      variant === 'text' &&
      branding?.primaryText?.toLowerCase() === theme.palette.base.white.toLowerCase()
    ) {
      return branding?.primaryBackground;
    }

    return variant === 'outlined' ? branding?.primaryBackground : branding?.primaryText;
  };

  return (
    <StyledButton
      startIcon={startIcon}
      size={size || defaultSize}
      variant={variant}
      disabled={isLoading || disabled}
      sx={{
        backgroundColor: rest.color
          ? 'auto'
          : variant === 'contained'
          ? branding?.primaryBackground
          : 'unset',
        color: getColor(),
        borderColor: variant === 'outlined' ? branding?.primaryBackground : 'unset',
        '&:hover': hover,
        ...customSx,
      }}
      {...rest}
    >
      {isLoading ? <CircularProgress className='text-inherit' size={20} /> : children}
    </StyledButton>
  );
};

export default CustomButton;
