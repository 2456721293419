import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { actionPermissionKeys } from './common';
import { DATE_PICKER_FORMAT_SERVER } from './date';

export const capitalCallStatus = {
  Draft: 'Draft',
  In_Progress: 'In Progress',
  Withdrawn: 'Withdrawn',
  Cancelled: 'Cancelled',
  Archived: 'Archived',
  Finalised: 'Finalised',
};

export const capitalCallActionTypes = {
  view: 'view',
  edit: 'edit',
  delete: 'delete',
  archive: 'Archive',
  undo: 'Undo',
};

export const capitalCallActionMenus = [
  {
    key: capitalCallActionTypes.view,
    label: capitalize(capitalCallActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  {
    key: capitalCallActionTypes.edit,
    label: capitalize(capitalCallActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: capitalCallActionTypes.archive,
    label: capitalize(capitalCallActionTypes.archive),
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: capitalCallActionTypes.undo,
    label: capitalize(capitalCallActionTypes.undo),
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: capitalCallActionTypes.delete,
    label: capitalize(capitalCallActionTypes.delete),
    permissionKey: actionPermissionKeys.isDelete,
  },
];

export enum CapitalCallType {
  CapitalCall,
  SpecialPurposeCall,
  CapitalCallAndSpecialPurposeCall,
}

export const capitalCallDefaultValues = {
  isSaveDraft: false,
  name: '',
  capitalCallDate: dayjs().format(DATE_PICKER_FORMAT_SERVER),
  bankAccount: {
    id: '',
    nickName: '',
    bankName: '',
    accountName: '',
    bankStateBranchNumber: '',
    accountNumber: '',
    swiftCode: '',
  },
  bPay: {
    id: '',
    nickName: '',
    billerCode: '',
    customerReferenceNumber: '',
  },
  investorIds: [],
  type: CapitalCallType.CapitalCall,
  entryUnitPrice: null,
  currentUnitPrice: null,
  isCurrentUnitPrice: false,
  capitalCallDetails: Array.from(new Array(5)).map(() => ({
    description: '',
    percentage: 0,
    fixedAmount: 0,
  })),
  specialPurposeCallDetails: Array.from(new Array(5)).map(() => ({
    description: '',
    percentage: 0,
    fixedAmount: 0,
  })),
  unitPriceRounding: 0,
  isPartnership: false,
  unitClassIds: [],
};

export enum CapitalCallDocumentType {
  CapitalCallLetter,
  DepositConfirmation,
  InvestmentConfirmation,
  WithdrawalNotice,
}

export enum CapitalCallDocumentStatus {
  Created,
  Published,
  Emailed,
}

export const capitalCallDocumentStatusName = {
  Created: 'Created',
  Published: 'Published',
  Emailed: 'Emailed',
};

export enum CallType {
  CapitalCall,
  SPC,
  CapitalCallAndSPC,
}

export const CC_DETAILS_COLUMN_KEYS = {
  investorNumber: 'investorNumber',
  investorName: 'investorName',
  investmentAmount: 'investmentAmount',
  units: 'units',
  paidAmount: 'paidAmount',
  currentCall: 'currentCall',
  unPaidAmount: 'unPaidAmount',
  newUnits: 'newUnits',
  totalUnits: 'totalUnits',
  spCallAmount: 'spCallAmount',
  status: 'status',
};

export enum CAPITAL_CALL_DETAIL_STATUS {
  CREATED,
  PAID,
  FINALIZED,
  WITHDRAWN,
  CANCELLED,
}

export const PARTIALLY_RECORDED_ERROR_MESSAGE = 'Call Details have payment records';
