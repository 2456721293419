import { Undo } from '@mui/icons-material';
import { Box, debounce, FormControl, FormControlLabel, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddIcon } from 'src/assets/icons/AddIcon';
import { ArchiveIcon } from 'src/assets/icons/ArchiveIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { NoteIcon } from 'src/assets/icons/NoteIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import FormInput from 'src/components/atoms/FormInput';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { APPLICATION_ACTION, applicationStatuses } from 'src/constants/applications';
import { ActionType } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useQueryString } from 'src/hooks/useQueryString';
import { useRole } from 'src/hooks/useRole';
import { IAction } from 'src/interfaces/common';
import {
  useArchiveApplication,
  useDeleteApplication,
  useGetApplicationFilters,
  useGetApplicationList,
  useGetApplicationUnitClassFilters,
} from 'src/modules/applications/hooks';
import { IClientApplication } from 'src/modules/applications/type';
import { ConfirmFormat } from 'src/modules/common/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatCurrencyNumber, handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import FundraisingApplicationForm from './fundraising';
import CreateFundraisingApplicationForm from './fundraising/components/CreateFundraisingApplicationForm';

interface IClientApplicationsProps {}

interface ITableData extends IClientApplication {}

const FILTER_BY = {
  investmentEntity: 'investmentEntity',
  status: 'Status',
  unitClass: 'unitClass',
  offer: 'offer',
};

const ClientApplications: FC<IClientApplicationsProps> = () => {
  const theme = useTheme();
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [selectedRow, setSelectedRow] = useState<ITableData>();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();

  const { canCreate, filterActions } = useRole(ROUTES_PATH.FUNDRAISING_APPLICATIONS);

  // View application from transaction
  const { appId = '', unitClassId = '', mode } = useQueryString();

  const rejectModalRef = useRef<IBasicModalElement>(null);
  const rejectForm = useForm({
    defaultValues: { note: '' },
  });

  const {
    data: { items: applicationList = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    params,
    setParams,
    refetch: refetchApplicationList,
  } = useGetApplicationList<IClientApplication>();

  const { data: { statuses = [], offers = [] } = {} } = useGetApplicationFilters();

  const { data: { unitClasses = [] } = {}, setOfferIds } = useGetApplicationUnitClassFilters();

  const { mutate: archiveApplication, isLoading: isArchiving } = useArchiveApplication();
  const { mutate: deleteApplication, isLoading: isDeleting } = useDeleteApplication();

  const fundraisingApplicationModalRef = useRef<IBasicModalElement>(null);

  const { selectedClients, selectedFunds } = useFundPortalContext();

  const isOverarchingFilterEmpty =
    selectedClients === MULTIPLE_SELECT_EMPTY || selectedFunds === MULTIPLE_SELECT_EMPTY;

  const isOneOfFiltersEmpty = Boolean(emptyFilters?.length) || isOverarchingFilterEmpty;

  useEffect(() => {
    if (appId && unitClassId) {
      setSelectedRow({
        id: appId,
        unitClassId,
        statusName: applicationStatuses.Finalised,
      } as ITableData);
      setActionType((mode as ActionType) || 'view');
      handleToggleApplicationModal(true);
    }
  }, [appId, unitClassId]);

  const offersOptions = useMemo(() => {
    return offers
      ?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.label?.localeCompare(b.label));
  }, [offers]);

  const unitClassesOptions = useMemo(() => {
    return unitClasses
      ?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.label?.localeCompare(b.label));
  }, [unitClasses]);

  const statusesOptions = useMemo(() => {
    return statuses
      ?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.label?.localeCompare(b.label));
  }, [statuses]);

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleSearchApplication = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleSetEmptyFilters = (values: string | string[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: (status as string[]).map((item: string) => Number(item)) });
    }

    handleSetEmptyFilters(status, FILTER_BY.status);
  };

  const handleUnitClassChange = (unitClass: string[] | string) => {
    if (unitClass !== MULTIPLE_SELECT_EMPTY) {
      setParams({
        unitClassIds: (unitClass as string[]).map((item: string) => item),
      });
    }

    handleSetEmptyFilters(unitClass, FILTER_BY.unitClass);
  };

  const handleOfferChange = (offer: string[] | string) => {
    if (offer !== MULTIPLE_SELECT_EMPTY) {
      setParams({
        offerIds: (offer as string[]).map((item: string) => item),
      });
    }
    setOfferIds(offer as string[]);
    handleSetEmptyFilters(offer, FILTER_BY.offer);
  };

  const handleArchivedChange = (e: any) => {
    setParams({
      isArchiveIncluded: e.target.checked,
    });
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Application Number',
      key: 'applicationNumber',
      sorter: true,
      sortBy: 'applicationNumber',
      sx: { minWidth: 200, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.applicationNumber || ''} />,
    },
    {
      title: 'Fund',
      key: 'fund',
      sorter: true,
      sortBy: 'fund',
      sx: { minWidth: 110, width: '10%' },
      renderNode: (row) => <StringNodeTable value={row?.fund || ''} />,
    },
    {
      title: 'Unit Class',
      key: 'unitClass',
      sorter: true,
      sortBy: 'unitClass',
      sx: { minWidth: 110, width: '10%' },
      renderNode: (row) => <StringNodeTable value={row?.unitClass || ''} />,
    },
    {
      title: 'Offer',
      key: 'offer',
      sorter: true,
      sortBy: 'offer',
      sx: { minWidth: 110, width: '10%' },
      renderNode: (row) => <StringNodeTable value={row?.offer || ''} />,
    },
    {
      title: 'Investor',
      key: 'investor',
      sorter: true,
      sortBy: 'investor',
      sx: { minWidth: 110, width: '10%' },
      renderNode: (row) => {
        return <StringNodeTable value={row?.investor} />;
      },
    },
    {
      title: 'Investment Amount',
      key: 'investmentAmount',
      sorter: true,
      sortBy: 'investmentAmount',
      sx: { minWidth: 200, width: '10%' },
      renderNode: (row) => (
        <StringNodeTable
          value={`${formatCurrencyNumber(row?.investmentAmount, 2)} ${row?.currency}`}
        />
      ),
    },
    {
      title: 'Submitted Date',
      key: 'submittedDate',
      sorter: true,
      sortBy: 'submittedDate',
      sx: { minWidth: 110 },
      renderNode: (row) => {
        return (
          <StringNodeTable
            value={row?.submittedDate ? utcToLocalTimezone(row?.submittedDate) : ''}
          />
        );
      },
    },
    ...(!params.isArchiveIncluded
      ? ([
          {
            title: 'Status',
            key: 'statusName',
            sx: { minWidth: 110, width: '10%' },
            isSticky: true,
            renderNode: (row) => {
              return (
                <Box className='flex items-center'>
                  <StatusBadge status={row?.statusName || ''} />
                  {row?.statusName === applicationStatuses.Rejected && (
                    <Box
                      className='ml-2 p-1 w-6 h-6 rounded-full flex items-center justify-center cursor-pointer'
                      bgcolor='neutral.ne200'
                      onClick={() => {
                        rejectModalRef.current?.open();
                        rejectForm.setValue('note', row.note);
                        setSelectedRow(row);
                      }}
                    >
                      <NoteIcon />
                    </Box>
                  )}
                </Box>
              );
            },
          },
        ] as ColumnProps<ITableData, 'action'>[])
      : []),
    {
      title: 'Action',
      key: 'action',
      isSticky: true,
      sx: { minWidth: 90, width: 90 },
      renderNode: (row) => {
        const getMenus = {
          [applicationStatuses.Draft]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.delete,
          ],
          [applicationStatuses.In_Progress]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
          [applicationStatuses.Submitted]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.KYC_In_Progress]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.Action_Required]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.KYC_Completed]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.Cancelled]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
          [applicationStatuses.Withdrawn]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
          [applicationStatuses.Awaiting_Deposit]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.Approved]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.archive,
          ],
          [applicationStatuses.Finalised]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
          [applicationStatuses.Rejected]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
          [applicationStatuses.Pending]: [APPLICATION_ACTION.view, APPLICATION_ACTION.archive],
        };

        const actionIcons = {
          [APPLICATION_ACTION.view.key]: <DocumentOnePageIcon />,
          [APPLICATION_ACTION.edit.key]: <EditOutlinedIcon />,
          [APPLICATION_ACTION.archive.key]: (
            <Box component='span' ml={'-2px'}>
              <ArchiveIcon width='16' height='16' color={theme.palette.neutral.ne800} />
            </Box>
          ),
          [APPLICATION_ACTION.undo.key]: (
            <Undo sx={{ color: theme.palette.neutral.ne600, ml: '-4px' }} />
          ),
          [APPLICATION_ACTION.delete.key]: <DeleteIcon />,
        };

        const actions = {
          [APPLICATION_ACTION.view.key]: () => {
            setActionType('view');
            setSelectedRow(row);
            handleToggleApplicationModal(true);
          },
          [APPLICATION_ACTION.edit.key]: () => {
            setActionType('edit');
            setSelectedRow(row);
            handleToggleApplicationModal(true);
          },
          [APPLICATION_ACTION.archive.key]: () => {
            setConfirmFormat({
              title: 'Archive Application?',
              content: `Application will be archived. Are you sure you want to continue?`,
              button: {
                type: 'primary',
                label: 'Continue',
                onAction: () => {
                  archiveApplication(row.id, {
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  });
                },
              },
            });
            modalConfirmRef?.current?.open();
          },
          [APPLICATION_ACTION.undo.key]: () => {
            setConfirmFormat({
              title: 'Reactivate Application?',
              content: `Application will be reactivated.<br />
              Are you sure you want to continue?`,
              button: {
                type: 'primary',
                label: 'Continue',
                onAction: () => {
                  archiveApplication(row.id, {
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  });
                },
              },
            });
            modalConfirmRef?.current?.open();
          },
          [APPLICATION_ACTION.delete.key]: () => {
            setConfirmFormat({
              title: 'Delete Application?',
              content: `Are you sure you want to delete the application?<br/>
              This action cannot be undone.`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () => {
                  deleteApplication(row.id, {
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  });
                },
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const permissionActions: IAction[] = filterActions(
          row.isArchived
            ? [APPLICATION_ACTION.view, APPLICATION_ACTION.undo]
            : getMenus[row.statusName],
          true,
        );

        const menus = permissionActions?.map((menuItem) => ({
          icon: actionIcons?.[menuItem.key],
          label: menuItem.label,
          onAction: actions?.[menuItem.key],
        }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  const handleToggleApplicationModal = (isOpen: boolean) => {
    if (isOpen) {
      fundraisingApplicationModalRef.current?.open();
    } else {
      fundraisingApplicationModalRef.current?.close();
      refetchApplicationList();
      selectedRow && setSelectedRow(undefined);
    }
  };

  const handleCloseRejectModal = () => {
    rejectForm.setValue('note', '');
    rejectModalRef.current?.close();
  };

  return (
    <Box>
      <Box>
        <Box className='flex items-center justify-between'>
          <Box component='div'>
            <Typography variant='h6'>Applications</Typography>
          </Box>
          {canCreate && (
            <Box className='flex items-center gap-4'>
              <CustomButton
                onClick={() => {
                  handleToggleApplicationModal(true);
                  setActionType('create');
                }}
                startIcon={<AddIcon />}
                className='w-fit h-12 flex items-center justify-between px-5'
              >
                <Typography variant='body2'>Create New Application</Typography>
              </CustomButton>
            </Box>
          )}
        </Box>
        <Box className='w-full h-[2px] mt-4' bgcolor='neutral.ne200' />
        <Box className='flex items-center gap-4 mt-6'>
          <Box width='33%'>
            <SearchField
              placeholder='Search by application number, investor'
              onChange={handleSearchApplication}
            />
          </Box>
          <CustomMultiSelect
            onChange={handleUnitClassChange}
            options={isOverarchingFilterEmpty ? [] : unitClassesOptions}
            label='Unit Class'
            showClearAllIcon
          />
          <CustomMultiSelect
            onChange={handleOfferChange}
            options={offersOptions}
            label='Offer'
            showClearAllIcon
          />
          <CustomMultiSelect
            onChange={handleStatusChange}
            options={statusesOptions}
            label='Status'
            customRenderLabel={customRenderStatusLabel}
            showClearAllIcon
          />
          <Box className='flex items-center'>
            <FormControl>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={!!params.isArchiveIncluded}
                    onChange={handleArchivedChange}
                  />
                }
                label={<Typography>Archived</Typography>}
              />
            </FormControl>
          </Box>
        </Box>
        <Box className='mt-10'>
          {(applicationList.length && !isOneOfFiltersEmpty) || isLoading ? (
            <CustomTable
              columns={columns}
              rows={applicationList}
              isFetchingData={isLoading}
              totalResults={totalItem}
              currentPage={page}
              numberItemOnPage={pageSize}
              onChangePagination={handleChangePage}
              onSort={handleSort}
              selectedRows={[]}
            />
          ) : (
            <EmptyData />
          )}
        </Box>
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={''}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
      <BasicModal
        ref={fundraisingApplicationModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleToggleApplicationModal(false)}
      >
        {!selectedRow ||
        (selectedRow?.isFundApplication &&
          selectedRow?.statusName === applicationStatuses.Draft) ? (
          <CreateFundraisingApplicationForm
            onClose={() => handleToggleApplicationModal(false)}
            isEditMode={actionType === 'edit'}
            isViewMode={actionType === 'view'}
            isCreateMode={actionType === 'create'}
            isDraft={true}
            id={selectedRow?.id}
          />
        ) : (
          <FundraisingApplicationForm
            onClose={() => handleToggleApplicationModal(false)}
            isEditMode={actionType === 'edit'}
            isViewMode={actionType === 'view'}
            isDraft={selectedRow?.statusName === applicationStatuses.In_Progress}
            {...selectedRow}
          />
        )}
      </BasicModal>
      <BasicModal ref={rejectModalRef} onClose={handleCloseRejectModal}>
        <FormProvider {...rejectForm}>
          <Box className='flex flex-col p-10 w-[600px]'>
            <Typography variant='h5' mb={3}>
              View Reject Reason
            </Typography>
            <CustomAlert sx={{ mb: 1 }} severity={'error'}>{`Rejected By: ${
              selectedRow?.rejectedBy
            } | Rejected Date: ${
              selectedRow?.rejectedDate && utcToLocalTimezone(selectedRow?.rejectedDate)
            }`}</CustomAlert>
            <FormInput multiline rows={4} name={'note'} label='Note' readOnly />
            <Box className='flex justify-end mt-6'>
              <CustomButton onClick={handleCloseRejectModal}>
                <Typography color='white' variant='body2' fontWeight={500}>
                  Ok
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={isArchiving}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={isArchiving || isDeleting}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default ClientApplications;
