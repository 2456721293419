import { yupResolver } from '@hookform/resolvers/yup';
import { Box, debounce, IconButton, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddIcon } from 'src/assets/icons/AddIcon';
import { CancelIcon } from 'src/assets/icons/CancelIcon';
import { CeaseActionIcon } from 'src/assets/icons/CeaseActionIcon';
import { CeasedIcon } from 'src/assets/icons/CeasedIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { TickIcon } from 'src/assets/icons/TickIcon';
import { WithdrawIcon } from 'src/assets/icons/WithdrawIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import FormInput from 'src/components/atoms/FormInput';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus from 'src/components/molecules/ActionMenus';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import {
  ADMINISTRATION_INVESTOR_ACTIONS,
  administrationInvestorStatuses,
} from 'src/constants/administration-investor';
import { ActionType } from 'src/constants/common';
import { FundType } from 'src/constants/funds-management';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useQueryString } from 'src/hooks/useQueryString';
import { useRole } from 'src/hooks/useRole';
import { IAction } from 'src/interfaces/common';
import {
  useArchiveInvestor,
  useCeaseInvestor,
  useDeleteInvestor,
  useDownloadInvestorProfileCSV,
  useExportInvestorProfileCSV,
  useGetInvestorById,
  useGetInvestorFilters,
  useGetInvestorList,
  useGetInvestorUnitClassesByMultiFunds,
  useInvestorExportCSV,
  useUnarchiveInvestor,
  useUnceaseInvestor,
} from 'src/modules/administration-investor/hooks';
import { IInvestor } from 'src/modules/administration-investor/types';
import { ConfirmFormat } from 'src/modules/common/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatCurrencyNumber, handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import { ceaseSchema } from 'src/validations/administration-investor';
import CeasePartnership from './components/CeasePartnership';
import UpsertInvestorForm, { INVESTMENT_ENTITY_STEP } from './components/UpsertInvestorForm';
interface IInvestorProps {
  switchLayoutDropdown: React.ReactNode;
  bulkUploadButton: React.ReactNode;
}
const FILTER_BY = {
  unitClassIds: 'Unit Class',
  status: 'Status',
};
const Investor: FC<IInvestorProps> = ({ bulkUploadButton, switchLayoutDropdown }) => {
  const investorModalRef = useRef<IBasicModalElement>(null);
  const modalCeaseRef = useRef<IBasicModalElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const modalCeasePartnershipRef = useRef<IBasicModalElement>(null);

  const { canDownload, canCreate, filterActions } = useRole(ROUTES_PATH.ADMINISTRATION_INVESTORS);
  const { selectedClients, selectedFunds, isFmPortal } = useFundPortalContext();

  const [selectedRow, setSelectedRow] = useState<IInvestor>();
  const [ceasedInfo, setCeasedInfo] = useState<
    | {
        ceasedDate?: string;
        ceasedBy?: string;
      }
    | undefined
  >();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();

  const isOverarchingFilterEmpty =
    selectedClients === MULTIPLE_SELECT_EMPTY || selectedFunds === MULTIPLE_SELECT_EMPTY;
  const isOneOfFiltersEmpty = Boolean(emptyFilters?.length) || isOverarchingFilterEmpty;
  const { investorId = '', mode } = useQueryString();

  const {
    data: { items: investors = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    setParams,
    refetch,
    params,
  } = useGetInvestorList<IInvestor>();
  const { data: { statuses = [] } = {} } = useGetInvestorFilters();
  const { data: unitClasses = [] } = useGetInvestorUnitClassesByMultiFunds([]);
  const { mutate: ceaseInvestor, isLoading: ceaseInvestorLoading } = useCeaseInvestor();
  const { mutate: archiveInvestor, isLoading: archivingInvestor } = useArchiveInvestor();
  const { mutate: unarchiveInvestor, isLoading: unArchivingInvestor } = useUnarchiveInvestor();
  const { mutate: unceaseInvestor, isLoading: unCeasingInvestor } = useUnceaseInvestor();
  const { mutate: deleteInvestor, isLoading: deletingInvestor } = useDeleteInvestor();
  const { mutate: exportCSV, isLoading: isExportLoading } = useInvestorExportCSV();
  const { mutate: exportInvestorProfileCSV, isLoading: exportingInvestorProfile } =
    useExportInvestorProfileCSV();
  const { mutate: downloadInvestorProfileCSV, isLoading: downloadingInvestorProfile } =
    useDownloadInvestorProfileCSV();

  const { data: investorDetail } = useGetInvestorById(
    investorId,
    INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS,
    !!investorId,
  );
  const ceaseForm = useForm({
    resolver: yupResolver(ceaseSchema),
  });

  useEffect(() => {
    if (investorId) {
      const isViewMode = mode === 'view';
      isViewMode ? handleView() : handleEdit();
    }
  }, [investorId, mode]);

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: (status as string[]).map((item: string) => Number(item)) });
    }

    handleSetEmptyFilters(status, FILTER_BY.status);
  };

  const handleUnitClassChange = (data: string[] | string) => {
    if (data !== MULTIPLE_SELECT_EMPTY) {
      setParams({ unitClassIds: data as string[] });
    }
    handleSetEmptyFilters(data, FILTER_BY.unitClassIds);
  };

  const handleSetEmptyFilters = (values: string | string[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const handleOnChangeArchived = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.checked;
    setParams({ archived: value });
    setIsArchived(!value);
  };

  const handleToggleUpsertModal = (isOpen: boolean) => {
    if (isOpen) {
      investorModalRef.current?.open();
    } else {
      investorModalRef.current?.close();
      refetch();
    }
  };

  const handleToggleCeasePartnershipModal = (isOpen: boolean) => {
    if (isOpen) {
      modalCeasePartnershipRef.current?.open();
    } else {
      modalCeasePartnershipRef.current?.close();
      setSelectedRow(undefined);
    }
  };

  const handleCeaseInvestor = () => {
    if (ceasedInfo) {
      return handleCloseCeaseModal();
    }
    const formData = ceaseForm.getValues();
    ceaseInvestor(
      { id: selectedRow?.id || '', reason: formData.reason },
      {
        onSuccess: () => {
          refetch();
          handleCloseCeaseModal();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleEdit = (row?: IInvestor) => {
    setActionType('edit');
    setSelectedRow(row);
    handleToggleUpsertModal(true);
  };

  const handleView = (row?: IInvestor) => {
    setActionType('view');
    setSelectedRow(row);
    handleToggleUpsertModal(true);
  };

  const handleCease = (row: IInvestor) => {
    const isPartnershipRow = row?.fundType === FundType.Partnership;
    setSelectedRow(row);

    if (isPartnershipRow) {
      handleToggleCeasePartnershipModal(true);
    } else {
      modalCeaseRef.current?.open();
    }
  };

  const handleCeased = (row: IInvestor) => {
    ceaseForm.setValue('reason', row.ceasedReason);
    setCeasedInfo({
      ceasedDate: row.ceasedDate,
      ceasedBy: row.ceasedBy,
    });
    modalCeaseRef.current?.open();
  };

  const handleCloseCeaseModal = () => {
    modalCeaseRef.current?.close();
    ceaseForm.resetField('reason');
  };

  const handleDownload = () => {
    exportCSV(params, {
      onSuccess: () => {},
      onError: handleErrorFromServer,
    });
  };

  const handleDownloadInvestorProfile = () => {
    exportInvestorProfileCSV(params, {
      onSuccess: (downloadId: string) => {
        downloadInvestorProfileCSV(downloadId);
      },
      onError: handleErrorFromServer,
    });
  };

  const columns: ColumnProps<IInvestor, 'action'>[] = [
    {
      title: 'Investor Number',
      key: 'investorNumber',
      sorter: true,
      sortBy: 'investorNumber',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.investorNumber || ''} />,
    },
    {
      title: 'Investor',
      key: 'investorName',
      sorter: true,
      sortBy: 'investorName',
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.investorName || ''} />,
    },
    {
      title: 'Client',
      key: 'clientName',
      sorter: true,
      sortBy: 'clientName',
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.clientName || ''} />,
      isHidden: isFmPortal,
    },
    {
      title: 'Fund',
      key: 'fund',
      sortBy: 'fund',
      sorter: true,
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.fund} />,
    },
    {
      title: 'Unit Class',
      key: 'unitClass',
      sorter: true,
      sortBy: 'unitClass',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.unitClass} />,
    },
    {
      title: 'Investment Amount',
      key: 'investmentAmount',
      sorter: true,
      sortBy: 'investmentAmount',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => (
        <StringNodeTable
          value={`${formatCurrencyNumber(row?.investmentAmount, 2)} ${row?.currencyName}`}
        />
      ),
    },
    {
      title: 'Units/Ownership %',
      key: 'units',
      sx: { minWidth: 150, width: '15%' },

      renderNode: (row) => {
        const isPartnershipRow = row?.fundType === FundType.Partnership;

        if (isPartnershipRow) {
          return isNumber(row?.ownership) ? (
            <StringNodeTable value={`${formatCurrencyNumber(row?.ownership, 2)}%`} />
          ) : null;
        }

        return isNumber(row?.units) ? (
          <StringNodeTable value={formatCurrencyNumber(row?.units, row?.investmentRounding)} />
        ) : null;
      },
    },
    {
      title: 'Amount Unpaid',
      key: 'unpaidAmount',
      sorter: true,
      sortBy: 'unpaidAmount',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => (
        <StringNodeTable
          value={`${formatCurrencyNumber(row?.unpaidAmount, 2)} ${row?.currencyName}`}
        />
      ),
    },
    {
      title: 'Net Investment Value',
      key: 'netInvestmentValue',
      sorter: true,
      sortBy: 'netInvestmentValue',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => (
        <StringNodeTable
          value={`${formatCurrencyNumber(
            row?.netInvestmentValue > 0 ? row.netInvestmentValue : 0,
            2,
          )} ${row?.currencyName}`}
        />
      ),
    },
    {
      title: 'Status',
      key: 'statusName',
      sx: { minWidth: 110, width: '10%' },
      isSticky: true,
      renderNode: (row) => {
        if (row?.statusName === administrationInvestorStatuses.Ceased) {
          return (
            <Box className='flex items-center'>
              <StatusBadge status={row?.statusName || ''} />
              <IconButton className='ml-[5px]' onClick={() => handleCeased(row)}>
                <CeasedIcon />
              </IconButton>
            </Box>
          );
        }
        return <StatusBadge status={row?.statusName || ''} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      isSticky: true,
      sx: { minWidth: 90, width: 90 },
      renderNode: (row) => {
        const getMenus = {
          [administrationInvestorStatuses.Draft]: [
            ADMINISTRATION_INVESTOR_ACTIONS.view,
            ADMINISTRATION_INVESTOR_ACTIONS.edit,
            ADMINISTRATION_INVESTOR_ACTIONS.delete,
          ],
          [administrationInvestorStatuses.Active]: [
            ADMINISTRATION_INVESTOR_ACTIONS.view,
            ADMINISTRATION_INVESTOR_ACTIONS.edit,
            ADMINISTRATION_INVESTOR_ACTIONS.cease,
          ],
          [administrationInvestorStatuses.Inactive]: [
            ADMINISTRATION_INVESTOR_ACTIONS.view,
            ADMINISTRATION_INVESTOR_ACTIONS.edit,
            ADMINISTRATION_INVESTOR_ACTIONS.archive,
          ],
          [administrationInvestorStatuses.Ceased]: [
            ADMINISTRATION_INVESTOR_ACTIONS.view,
            ADMINISTRATION_INVESTOR_ACTIONS.activate,
            ADMINISTRATION_INVESTOR_ACTIONS.archive,
          ],
          [administrationInvestorStatuses.Archived]: [
            ADMINISTRATION_INVESTOR_ACTIONS.view,
            ADMINISTRATION_INVESTOR_ACTIONS.undo,
          ],
        };

        const actionIcons = {
          [ADMINISTRATION_INVESTOR_ACTIONS.view.key]: <EyeViewIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.edit.key]: <EditOutlinedIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.archive.key]: <CancelIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.activate.key]: <TickIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.undo.key]: <WithdrawIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.delete.key]: <DeleteIcon />,
          [ADMINISTRATION_INVESTOR_ACTIONS.cease.key]: <CeaseActionIcon />,
        };

        const actions = {
          [ADMINISTRATION_INVESTOR_ACTIONS.view.key]: () => {
            setActionType('view');
            setSelectedRow(row);
            handleToggleUpsertModal(true);
          },
          [ADMINISTRATION_INVESTOR_ACTIONS.edit.key]: () => handleEdit(row),
          [ADMINISTRATION_INVESTOR_ACTIONS.cease.key]: () => handleCease(row),
          [ADMINISTRATION_INVESTOR_ACTIONS.archive.key]: () => {
            setConfirmFormat({
              title: 'Archive Investor?',
              content: `Investor will be archived.
              Are you sure you want to continue?`,
              button: {
                type: 'error',
                label: 'Archive',
                onAction: () =>
                  archiveInvestor(row.id, {
                    onSuccess: () => refetch(),
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [ADMINISTRATION_INVESTOR_ACTIONS.undo.key]: () => {
            setConfirmFormat({
              title: 'Reactivate Investor?',
              content: `Investor will be reactivated.
              Are you sure you want to continue?`,
              button: {
                type: 'error',
                label: 'Continue',
                onAction: () =>
                  unarchiveInvestor(row.id, {
                    onSuccess: () => refetch(),
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [ADMINISTRATION_INVESTOR_ACTIONS.delete.key]: () => {
            setConfirmFormat({
              title: 'Delete Investor?',
              content: `Are you sure you want to delete the investor?
              This action cannot be undone.`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () =>
                  deleteInvestor(row.id, {
                    onSuccess: () => refetch(),
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [ADMINISTRATION_INVESTOR_ACTIONS.activate.key]: () => {
            const isPartnershipRow = row?.fundType === FundType.Partnership;
            if (isPartnershipRow) {
              setSelectedRow(row);
              handleToggleCeasePartnershipModal(true);
              return;
            }
            setConfirmFormat({
              title: 'Activate Investor?',
              content: `Investor will be activated.
              Are you sure you want to continue?`,
              button: {
                type: 'error',
                label: 'Continue',
                onAction: () =>
                  unceaseInvestor(row.id, {
                    onSuccess: () => refetch(),
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const permissionActions: IAction[] = filterActions(getMenus[row.statusName], true);

        const menus = permissionActions?.map((menuItem) => ({
          icon: actionIcons?.[menuItem.key],
          label: menuItem.label,
          onAction: actions?.[menuItem.key],
        }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  const statusesOptions = useMemo(() => {
    return statuses
      ?.map((item: (typeof statuses)[0]) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
  }, [statuses]);

  const unitClassesOptions = useMemo(() => {
    return unitClasses
      ?.map((item: (typeof unitClasses)[0]) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.label?.localeCompare(b.label));
  }, [unitClasses]);

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box component='div' className='flex'>
          <Typography variant='h6'>Investor</Typography>
          {switchLayoutDropdown}
        </Box>
        <Box className='flex'>
          {canDownload && (
            <CustomButton
              sx={(theme) => ({
                backgroundColor: theme.palette.neutral.ne200,
                mr: '10px',
                color: theme.palette.neutral.ne800,
              })}
              startIcon={<DownloadIcon />}
              className='w-[193px] h-12 flex items-center justify-center px-5'
              onClick={handleDownload}
              isLoading={isExportLoading}
            >
              <Typography color='neutral.ne800' variant='body2'>
                Download
              </Typography>
            </CustomButton>
          )}
          {canCreate && bulkUploadButton}
          {canCreate && (
            <CustomButton
              onClick={() => {
                investorModalRef.current?.open();
                setActionType('create');
              }}
              startIcon={<AddIcon />}
              className='h-12 flex items-center justify-between px-5'
            >
              <Typography variant='body2'>Create New Investor</Typography>
            </CustomButton>
          )}
        </Box>
      </Box>

      <Box className='flex items-center flex-wrap gap-4 mt-6'>
        <Box width='33%'>
          <SearchField placeholder='Search by Investor Number, Investor' onChange={handleSearch} />
        </Box>
        <CustomMultiSelect
          containerClass='md:w-1/4 2xl:w-auto'
          onChange={handleUnitClassChange}
          options={isOverarchingFilterEmpty ? [] : unitClassesOptions}
          label='Unit Classes'
          showClearAllIcon
        />
        <CustomMultiSelect
          containerClass='md:w-1/4 2xl:w-auto'
          onChange={handleStatusChange}
          options={statusesOptions}
          label='Status'
          customRenderLabel={customRenderStatusLabel}
          showClearAllIcon
        />
        <Box className='flex items-center'>
          <CustomCheckbox
            defaultChecked={isArchived}
            onChange={(event) => handleOnChangeArchived(event)}
            sx={(theme) => ({
              color: theme.palette.neutral.ne500,
            })}
          />
          <Typography variant='body3'>Archived</Typography>
        </Box>
        {canDownload && (
          <Box className='flex justify-end'>
            <CustomButton
              sx={(theme) => ({
                backgroundColor: theme.palette.neutral.ne200,
                color: theme.palette.neutral.ne800,
              })}
              startIcon={<DownloadIcon />}
              className='h-12 flex items-center justify-center px-5'
              onClick={handleDownloadInvestorProfile}
              isLoading={exportingInvestorProfile || downloadingInvestorProfile}
            >
              <Typography color='neutral.ne800' variant='body2'>
                Download Investor Details
              </Typography>
            </CustomButton>
          </Box>
        )}
      </Box>

      <Box className='mt-10'>
        {(investors.length && !isOneOfFiltersEmpty) || isLoading ? (
          <CustomTable
            columns={columns}
            rows={investors}
            isFetchingData={isLoading}
            totalResults={totalItem}
            currentPage={page}
            numberItemOnPage={pageSize}
            onChangePagination={handleChangePage}
            onSort={handleSort}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
      <BasicModal
        ref={investorModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleToggleUpsertModal(false)}
      >
        <UpsertInvestorForm
          onClose={() => {
            handleToggleUpsertModal(false);
            setSelectedRow(undefined);
          }}
          id={selectedRow?.id || investorId}
          isEditMode={actionType === 'edit'}
          isViewMode={actionType === 'view'}
          isDraft={
            selectedRow?.statusName === administrationInvestorStatuses.Draft ||
            investorDetail?.statusName === administrationInvestorStatuses.Draft
          }
        />
      </BasicModal>
      <BasicModal ref={modalCeaseRef}>
        <FormProvider {...ceaseForm}>
          <Box className='w-[600px] p-10'>
            <Box className='flex items-center justify-between'>
              <Typography variant='h5'>{ceasedInfo ? 'Ceased' : 'Cease?'}</Typography>
            </Box>
            <Box className='mt-6'>
              <FormInput name='reason' label='Reason' multiline rows={5} />
              {ceasedInfo && (
                <Box textAlign='right'>
                  <Typography variant='body3' color='neutral.ne800'>{`Ceased by ${
                    ceasedInfo?.ceasedBy
                  } on ${utcToLocalTimezone(ceasedInfo?.ceasedDate || '')}`}</Typography>
                </Box>
              )}
            </Box>
            <Box className='mt-6 flex flex-row-reverse'>
              <Box className='flex gap-2'>
                {!ceasedInfo && (
                  <CustomButton
                    sx={{ color: 'neutral.ne800' }}
                    variant='text'
                    onClick={handleCloseCeaseModal}
                  >
                    Cancel
                  </CustomButton>
                )}
                <CustomButton
                  isLoading={ceaseInvestorLoading}
                  onClick={ceaseForm.handleSubmit(handleCeaseInvestor)}
                >
                  {ceasedInfo ? 'OK' : 'Save'}
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={
                archivingInvestor || unCeasingInvestor || unArchivingInvestor || deletingInvestor
              }
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={
                archivingInvestor || unCeasingInvestor || unArchivingInvestor || deletingInvestor
              }
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal
        ref={modalCeasePartnershipRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center', width: 1120 } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleToggleCeasePartnershipModal(false)}
      >
        <CeasePartnership
          investor={selectedRow}
          onClose={() => handleToggleCeasePartnershipModal(false)}
        />
      </BasicModal>
    </Box>
  );
};

export default Investor;
