/* eslint-disable @typescript-eslint/ban-types */
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { ChevronDownIcon } from 'src/assets/icons/ChevronDownIcon';
import CustomHelperText from './CustomHelperText';
import { CustomTextField } from './FormInput';

export interface OptionType {
  id: any;
  label: string;
  value: string;

  [x: string]: any;
}

interface IFormAutocompleteProps
  extends Omit<AutocompleteProps<OptionType, true, false, false, any>, 'renderInput'> {
  name: string;
  label?: string | React.ReactNode;
  rules?: Record<string, unknown>;
  options: OptionType[];
  defaultMessage?: string;
  dataKey?: any;
  isOptionEqualToValue?: any;
  errorMessage?: string;
  searchIcon?: ReactNode;
  hidePopupIcon?: boolean;
  isLoading?: boolean;
  endAdornment?: ReactNode;
}

export interface IFormAutocompleteElement {
  open: () => void;
}

const StyledAutoComplete = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root': {
    '& input': {
      height: '50%',
      paddingLeft: '0px !important',
      marginTop: '-1px',
    },
  },
})) as typeof Autocomplete;

const FormAutocomplete = forwardRef<IFormAutocompleteElement, IFormAutocompleteProps>(
  (
    {
      name,
      label,
      defaultValue = '',
      rules = {},
      options,
      defaultMessage = '',
      errorMessage = '',
      dataKey,
      placeholder = 'Type here',
      searchIcon,
      hidePopupIcon = false,
      isLoading = false,
      onChange: handleChange,
      endAdornment,
      ...rest
    },
    ref,
  ) => {
    const { control } = useFormContext();
    const [open, setOpen] = useState(false);

    const {
      field: { ref: inputRef, onChange, ...inputProps },
      fieldState: { invalid, error },
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true);
      },
    }));

    return (
      <Stack className='w-full' gap={0.5}>
        {label && (
          <Typography variant='body3' className='flex'>
            {label}
          </Typography>
        )}
        <FormControl fullWidth error={invalid}>
          <StyledAutoComplete
            {...rest}
            {...inputProps}
            open={open}
            options={options}
            isOptionEqualToValue={(option, value) => {
              if (dataKey) {
                return option[dataKey] === value[dataKey] ? true : false;
              }
              return option.id === value.id;
            }}
            onChange={(e, data, reason) => {
              onChange(data);
              handleChange && handleChange(e, data, reason);
            }}
            onOpen={() => {
              if (!rest.disabled) setOpen(true);
            }}
            onClose={(_, reason) => {
              if (reason === 'selectOption' || reason === 'removeOption') return;
              setOpen(false);
            }}
            onKeyDown={(e) => e.stopPropagation()}
            popupIcon={
              hidePopupIcon ? <></> : <ChevronDownIcon width={16} height={16} color='#003E4E' />
            }
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant='outlined'
                inputRef={inputRef}
                error={invalid}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: searchIcon ? (
                    <InputAdornment position='start'>{searchIcon}</InputAdornment>
                  ) : (
                    params.InputProps.startAdornment
                  ),
                  endAdornment: (
                    <>
                      {isLoading && !rest.disabled ? (
                        <Box mr={-2.5}>
                          <CircularProgress color='inherit' size={20} />
                        </Box>
                      ) : null}
                      {endAdornment && endAdornment}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        {invalid && (
          <Box className='ml-2'>
            <CustomHelperText
              variant={invalid ? 'error' : 'default'}
              message={invalid ? errorMessage || (error as FieldError)?.message : defaultMessage}
            />
          </Box>
        )}
      </Stack>
    );
  },
);

export default FormAutocomplete;
