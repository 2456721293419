import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import HorizontalTabs from 'src/components/molecules/HorizontalTabs';
import IndividualBasicInfo, {
  IndividualBasicInfoFieldsType,
} from 'src/components/molecules/individual/IndividualBasicInfo';
import IndividualTaxInfo from 'src/components/molecules/individual/IndividualTaxInfo';
import { useGetApplicationOfferUnitClassDetail } from 'src/modules/applications/hooks';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { getObjectValueByKey } from 'src/utils/common';

interface IIndividualProps extends IUpsertInvestmentApplicationForm {
  keyName: string;
  disabledDetail?: boolean;
  disabledTaxInfo?: boolean;
  additionalDetail?: ReactNode;
  hasPosition?: boolean;
  disabledPosition?: boolean;
  hideTfn?: boolean;
  canDelete?: boolean;
  handleDelete?: () => void;
  taxInfoLogicCheck?: boolean;
  disabledBasicInfoFields?: IndividualBasicInfoFieldsType[];
  autocompleteAddress?: boolean;
  defaultExpanded?: boolean;
  hideDeclaration?: boolean;
}

const IndividualDetail = (props: IIndividualProps) => {
  const {
    keyName,
    canDelete,
    hasPosition,
    disabledDetail,
    disabledTaxInfo,
    additionalDetail,
    handleDelete,
    isViewMode,
    isDisabledEdit,
    offerId = '',
    unitClassId = '',
    hideTfn,
    taxInfoLogicCheck,
    disabledPosition,
    disabledBasicInfoFields,
    isExistingInvestmentEntity,
    autocompleteAddress,
    defaultExpanded = true,
    hideDeclaration,
  } = props;
  const theme = useTheme();
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const firstName = watch(`${keyName}.firstName`);
  const lastName = watch(`${keyName}.lastName`);
  const position = watch(`${keyName}.position`);

  const { data: applicationOfferUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    offerId,
    unitClassId,
  );
  const uboErrors = getObjectValueByKey(errors, keyName);

  const detailsErrors =
    uboErrors?.dob ||
    uboErrors?.email ||
    uboErrors?.firstName ||
    uboErrors?.lastName ||
    (uboErrors?.position && hasPosition) ||
    uboErrors?.residentialAddress ||
    uboErrors?.contactNumber ||
    uboErrors?.residentialPostcode ||
    uboErrors?.residentialStreetName ||
    uboErrors?.residentialStreetNumber ||
    uboErrors?.residentialSuburb;

  const taxInfoErrors = uboErrors?.taxInfo;

  const Tabs = [
    {
      label: 'Details',
      content: (
        <Box mx='-24px' mb='-24px'>
          <IndividualBasicInfo
            keyName={keyName}
            disabled={isViewMode || isDisabledEdit || disabledDetail || isExistingInvestmentEntity}
            position={{
              show: hasPosition,
              disabled:
                isViewMode || isDisabledEdit || disabledPosition || isExistingInvestmentEntity,
            }}
            additionalDetail={additionalDetail}
            disabledFields={disabledBasicInfoFields}
            autocompleteAddress={autocompleteAddress}
          />
        </Box>
      ),
      isError: detailsErrors,
    },
    {
      label: 'Tax Information',
      content: (
        <Box mx='-24px' mb='-24px'>
          <IndividualTaxInfo
            keyName={keyName}
            disabled={disabledTaxInfo || isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            hideTfn={hideTfn}
            allowForeignInvestors={applicationOfferUnitClassDetailData?.allowForeignInvestors}
            logicCheck={taxInfoLogicCheck}
            hideDeclaration={hideDeclaration}
          />
        </Box>
      ),
      isError: taxInfoErrors,
    },
  ];

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className='individual-detail'
      sx={{
        border: `1px solid ${
          detailsErrors || taxInfoErrors
            ? theme.palette.sematic.danger500
            : theme.palette.neutral.ne300
        } !important`,
        '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
          '.MuiAccordionSummary-content': { my: 0 },
          minHeight: 'unset',
        }}
      >
        <Box className='flex justify-between items-center w-full'>
          <Typography variant='body2' fontWeight={500}>
            {`${firstName || ''} ${lastName || ''}${
              hasPosition && position && (firstName || lastName) ? ` - ` : ''
            }${hasPosition ? position || '' : ''}`}
          </Typography>
          {!isExistingInvestmentEntity && !(isViewMode || isDisabledEdit) && canDelete && (
            <IconButton onClick={handleDelete} sx={{ p: 0 }}>
              <DeleteIcon width={24} height={24} color={theme.palette.neutral.ne600} />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className='p-6 pt-0 mt-0'>
        <HorizontalTabs tabs={Tabs} destroyInactivePanel={!hideDeclaration} />
      </AccordionDetails>
    </Accordion>
  );
};

export default IndividualDetail;
