import { lazy } from 'react';
import AdministrationInvestor from 'src/components/pages/administration-investor';
import BulkUpload from 'src/components/pages/bulk-upload';
import InvestorForm from 'src/components/pages/investor-form';

import Applications from 'src/components/pages/applications';
import AuditTrail from 'src/components/pages/audit-trail';
import ClientManagement from 'src/components/pages/client-management';
import Dashboard from 'src/components/pages/dashboard';
import KYCDetail from 'src/components/pages/kyc-back-office/KYCDetail';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { IRouterType } from 'src/interfaces/routes';

const Login = lazy(() => import('src/components/pages/login'));
const FactorAuthentication = lazy(() => import('src/components/pages/authentication'));
const UsersManagement = lazy(() => import('src/components/pages/users-management'));
const ForgotPassword = lazy(() => import('src/components/pages/forgot-password'));
const ResetPassword = lazy(() => import('src/components/pages/reset-password'));
const Signup = lazy(() => import('src/components/pages/signup'));
const SignupInvestor = lazy(() => import('src/components/pages/signup-investor'));
const EmailVerificationInvestor = lazy(
  () => import('src/components/pages/email-verification-investor'),
);
const Impersonate = lazy(() => import('src/components/pages/impersonate'));
const RoleManagement = lazy(() => import('src/components/pages/role-management'));
const FundsManagement = lazy(() => import('src/components/pages/funds-management'));
const Offers = lazy(() => import('src/components/pages/offers'));
const OrganisationSettings = lazy(() => import('src/components/pages/organisation-settings'));
const Forbidden = lazy(() => import('src/components/pages/forbidden'));
const InvestorOfferDetails = lazy(() => import('src/components/pages/investor-offer-details'));
const InvestmentEntity = lazy(() => import('src/components/pages/investment-entity'));
const KYCBackOffice = lazy(() => import('src/components/pages/kyc-back-office'));
const RequestVerify = lazy(() => import('src/components/pages/request-verify/RequestVerify'));
const Documents = lazy(() => import('src/components/pages/documents'));
const KYCBackOfficeGAPortal = lazy(() => import('src/components/pages/kyc-back-office-ga-portal'));
const CapitalCalls = lazy(() => import('src/components/pages/capital-calls'));
const Communication = lazy(() => import('src/components/pages/communication'));
const Billing = lazy(() => import('src/components/pages/billing'));
const FundRegistry = lazy(() => import('src/components/pages/fund-registry'));
const AdministrationReports = lazy(() => import('src/components/pages/administration-reports'));
const AdministrationTransactions = lazy(
  () => import('src/components/pages/administration-transactions/AdministrationTransactions'),
);
const AdministrationEditTransaction = lazy(
  () => import('src/components/pages/administration-transactions/edit-transaction/EditTransaction'),
);

export const appRoutes: IRouterType[] = [
  {
    element: Dashboard,
    path: ROUTES_PATH.HOME,
    isProtected: true,
  },
  {
    element: Login,
    path: ROUTES_PATH.LOGIN,
    isProtected: false,
    isRestricted: true,
  },
  {
    element: FactorAuthentication,
    path: ROUTES_PATH.FACTOR_AUTHENTICATION,
    isProtected: false,
  },
  {
    element: UsersManagement,
    path: ROUTES_PATH.USER_MANAGEMENT,
    isProtected: true,
  },
  {
    element: ForgotPassword,
    path: ROUTES_PATH.FORGOT_PASSWORD,
    isProtected: false,
  },
  {
    element: ResetPassword,
    path: ROUTES_PATH.RESET_PASSWORD,
    isProtected: false,
  },
  {
    element: ClientManagement,
    path: ROUTES_PATH.CLIENT_MANAGEMENT,
    isProtected: true,
  },
  {
    element: Signup,
    path: ROUTES_PATH.SIGN_UP,
    isProtected: false,
  },
  {
    element: ClientManagement,
    path: ROUTES_PATH.CLIENT_DETAIL,
    isProtected: true,
  },
  {
    element: SignupInvestor,
    path: ROUTES_PATH.SIGN_UP_INVESTOR,
    isProtected: false,
  },
  {
    element: EmailVerificationInvestor,
    path: ROUTES_PATH.EMAIL_VERIFICATION_INVESTOR,
    isProtected: false,
  },
  {
    element: Impersonate,
    path: ROUTES_PATH.IMPERSONATE,
    isProtected: false,
  },
  {
    element: RoleManagement,
    path: ROUTES_PATH.ROLE_MANAGEMENT,
    isProtected: true,
  },
  {
    element: RoleManagement,
    path: ROUTES_PATH.ROLE_LIST,
    isProtected: true,
  },
  {
    element: OrganisationSettings,
    path: ROUTES_PATH.ORGINISATION_SETTINGS,
    isProtected: true,
  },
  {
    element: Forbidden,
    path: ROUTES_PATH.FORBIDDEN,
    isProtected: false,
  },
  {
    element: FundsManagement,
    path: ROUTES_PATH.FUNDS,
    isProtected: true,
  },
  {
    element: Offers,
    path: ROUTES_PATH.FUNDRAISING_OFFERS,
    isProtected: true,
  },
  {
    element: InvestorOfferDetails,
    path: ROUTES_PATH.INVESTOR_OFFER_DETAILS,
    isProtected: true,
  },
  {
    element: Applications,
    path: ROUTES_PATH.APPLICATIONS,
    isProtected: true,
  },
  {
    element: Applications,
    path: ROUTES_PATH.FUNDRAISING_APPLICATIONS,
    isProtected: true,
  },
  {
    element: InvestmentEntity,
    path: ROUTES_PATH.INVESTMENT_ENTITY,
    isProtected: true,
  },
  {
    element: InvestmentEntity,
    path: ROUTES_PATH.UNALLOCATED_HOLDING,
    isProtected: true,
  },
  {
    element: CapitalCalls,
    path: ROUTES_PATH.FUNDRAISING_CAPITAL_CALLS,
    isProtected: true,
  },
  {
    element: KYCBackOffice,
    path: ROUTES_PATH.FUNDRAISING_KYC_BACK_OFFICE,
    isProtected: true,
  },
  {
    element: KYCBackOfficeGAPortal,
    path: ROUTES_PATH.KYC_BACK_OFFICE,
    isProtected: true,
  },
  {
    element: KYCDetail,
    path: ROUTES_PATH.KYC_BACK_OFFICE_DETAIL,
    isProtected: true,
  },
  {
    element: KYCDetail,
    path: ROUTES_PATH.FUNDRAISING_KYC_BACK_OFFICE_DETAIL,
    isProtected: true,
  },
  {
    element: RequestVerify,
    path: ROUTES_PATH.REQUEST_VERIFY,
    isProtected: false,
  },
  {
    element: AdministrationInvestor,
    path: ROUTES_PATH.ADMINISTRATION_INVESTORS,
    isProtected: true,
  },
  {
    element: AdministrationInvestor,
    path: ROUTES_PATH.ADMINISTRATION_INVESTMENT_ENTITY,
    isProtected: true,
  },
  {
    element: Documents,
    path: ROUTES_PATH.DOCUMENTS,
    isProtected: true,
  },
  {
    element: Communication,
    path: ROUTES_PATH.COMMUNICATIONS,
    isProtected: true,
  },
  {
    element: BulkUpload,
    path: ROUTES_PATH.BULK_UPLOAD,
    isProtected: true,
  },
  {
    element: InvestorForm,
    path: ROUTES_PATH.INVESTOR_FORMS,
    isProtected: true,
  },
  {
    element: AuditTrail,
    path: ROUTES_PATH.AUDIT_TRAIL,
    isProtected: true,
  },
  {
    element: Billing,
    path: ROUTES_PATH.BILLING,
    isProtected: true,
  },
  {
    element: FundRegistry,
    path: ROUTES_PATH.FUND_REGISTRY,
    isProtected: true,
  },
  {
    element: AdministrationReports,
    path: ROUTES_PATH.REPORTS,
    isProtected: true,
  },
  {
    element: AdministrationTransactions,
    path: ROUTES_PATH.TRANSACTIONS,
    isProtected: true,
  },
  {
    element: AdministrationEditTransaction,
    path: ROUTES_PATH.EDIT_TRANSACTION,
    isProtected: true,
  },
  {
    element: AdministrationEditTransaction,
    path: ROUTES_PATH.VIEW_TRANSACTION,
    isProtected: true,
  },
];
