import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { ITeamContact } from 'src/modules/applications/type';
import { DistributionInstructionType, actionPermissionKeys } from './common';

export const administrationInvestorActionTypes = {
  edit: 'edit',
  view: 'view',
  cease: 'cease',
  archive: 'archive',
  activate: 'activate',
  undo: 'undo',
  delete: 'delete',
};

export const ADMINISTRATION_INVESTOR_ACTIONS = {
  view: {
    key: administrationInvestorActionTypes.view,
    label: capitalize(administrationInvestorActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  edit: {
    key: administrationInvestorActionTypes.edit,
    label: capitalize(administrationInvestorActionTypes.edit),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  cease: {
    key: administrationInvestorActionTypes.cease,
    label: capitalize(administrationInvestorActionTypes.cease),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  archive: {
    key: administrationInvestorActionTypes.archive,
    label: capitalize(administrationInvestorActionTypes.archive),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  activate: {
    key: administrationInvestorActionTypes.activate,
    label: capitalize(administrationInvestorActionTypes.activate),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  undo: {
    key: administrationInvestorActionTypes.undo,
    label: capitalize(administrationInvestorActionTypes.undo),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  delete: {
    key: administrationInvestorActionTypes.delete,
    label: capitalize(administrationInvestorActionTypes.delete),
    permissionKey: actionPermissionKeys.isDelete,
  },
};

export const administrationInvestorStatuses = {
  Active: 'Active',
  Inactive: 'Inactive',
  Archived: 'Archived',
  Ceased: 'Ceased',
  Draft: 'Draft',
};

export const administrationInvestmentEntityActionTypes = {
  view: 'view',
  edit: 'edit',
  archive: 'archive',
  delete: 'delete',
};

export const ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS = {
  view: {
    key: administrationInvestmentEntityActionTypes.view,
    label: capitalize(administrationInvestmentEntityActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  edit: {
    key: administrationInvestmentEntityActionTypes.edit,
    label: capitalize(administrationInvestmentEntityActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  archive: {
    key: administrationInvestmentEntityActionTypes.archive,
    label: capitalize(administrationInvestmentEntityActionTypes.archive),
    permissionKey: [actionPermissionKeys.isEdit, actionPermissionKeys.isCreate],
  },
  delete: {
    key: administrationInvestmentEntityActionTypes.delete,
    label: capitalize(administrationInvestmentEntityActionTypes.delete),
    permissionKey: actionPermissionKeys.isDelete,
  },
};

export const administrationInvestmentEntityStatuses = {
  Linked: 'Linked',
  New: 'New',
  NotLinked: 'Not Linked',
  Draft: 'Draft',
};

export enum InvestmentEntityStatuses {
  Draft,
  New,
  NotLinked,
  Linked,
}

export const teamDefaultValues = {
  teammates: [],
  invitedTeamIds: [],
};

export const investmentDetailDefaultValues = {
  sourceOfFundsType: null,
  sourceOfFundsTypeOther: '',
  distributionInstructionType: DistributionInstructionType.PaymentToTheNominatedBankAccount,
};

export const createTeamContactDefaultValues: ITeamContact = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  position: '',
  userType: 0,
  roleId: null,
};

export const investorDetailsDefaultValue = {
  mannualInvestorNumber: '',
  investorName: '',
  fundId: null,
  fundName: '',
  unitClassId: '',
  unitClassName: '',
  investorNumber: '',
  investorType: null,
  currentUnitPrice: 0,
  issuedUnit: null,
  investmentAmount: null,
  paidAmount: null,
  unpaidAmount: 0,
  currentInvestmentAmount: 0,
  netValue: 0,
  specialPurposeCallAmount: null,
  investmentEntityId: null,
  isSaveDraft: false,
  isOptionalInvestorType: false,
  ownership: 0,
  fundsHeldInReserve: null,
};

export enum ADMINISTRATION_INVESTOR_ACTION_NAME {
  ISSUE = 'Issue',
  REDEMPTION = 'Redemption',
  OFF_MARKET_TRANSFER = 'Off Market Transfer',
  RECORD_PAYMENT = 'Record Payment',
  GENERATE_STATEMENT = 'Generate Statement',
}

export const issueFormDefaultValue = {
  transactionDate: dayjs().toString(),
  isSendMail: false,
  investmentAmount: 0,
  entryUnitPrice: 0,
  currentUnitPrice: 0,
  isUpdateUnitPrice: false,
  capitalCallAmount: 0,
  partiallyPaidUnits: false,
  paidAmount: 0,
  unpaidAmount: 0,
  units: 0,
  capitalCallPercent: 0,
  priceTab: 0,
  unitPriceRounding: 0,
};

export const redemptionFormDefaultValue = {
  option: null,
  transactionDate: dayjs().toString(),
  amount: 0,
  numberOfUnits: 0,
  isSendMail: false,
  entryUnitPrice: 0,
  currentUnitPrice: 0,
  unitPriceRounding: 0,
  selectedPrice: 0,
};

export enum REDEEM_OPTION {
  AMOUNT,
  UNIT,
  FULL_HOLDING,
}
export const offmarketTransferDefaultValue = {
  transactionDate: dayjs().toString(),
  isSendMail: false,
  transferAmount: 0,
  investorId: '',
};
export const recordPaymentDefaultValue = {
  transactionDate: dayjs().toString(),
  isSendMail: false,
  paymentAmount: 0,
};

export const generateStatementDefaultValue = {
  transactionDate: dayjs().toString(),
  isSendMail: false,
  isPreview: false,
  option: 0,
  publishOption: 0,
};

export const MAX_INVESTOR_NUMBER = 10;

export enum GENERATE_STATEMENT_ERROR {
  STATEMENT_MISSING = 'statement-missing',
}
