/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AssociationIcon from 'src/assets/icons/AssociationIcon';
import GovernmentBodyIcon from 'src/assets/icons/GovernmentBodyIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  INVESTMENT_ENTITY_OTHER,
  investmentEntityOtherDefaultValue,
} from 'src/constants/applications';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import AddNew from '../shared/AddNew';
import SelectExisting from '../shared/SelectExisting';
import Association from './Association';
import GovernmentBody from './GovernmentBody';

interface IOtherProps extends IInvestmentEntityProps {}

const otherTypeOptions = [
  {
    label: 'Association',
    value: INVESTMENT_ENTITY_OTHER.Association,
    icon: <AssociationIcon />,
  },
  {
    label: 'Government Body',
    value: INVESTMENT_ENTITY_OTHER.GovernmentBody,
    icon: <GovernmentBodyIcon />,
  },
];

const Other: FC<IOtherProps> = (props) => {
  const {
    isViewMode,
    isDisabledEdit,
    id = '',
    isReviewing,
    isExistingInvestmentEntity,
    isCreateFundApplicationForm,
    investmentEntityId,
  } = props;
  const { watch, reset } = useFormContext<IInvestmentEntityForm>();
  const watchOtherType = watch('otherType');

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.entityType) && !isCreateFundApplicationForm) {
      reset(investmentEntityOtherDefaultValue, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <FormRadioGroup
        name='otherType'
        options={otherTypeOptions}
        isEnumValue
        disabled={
          isViewMode ||
          isDisabledEdit ||
          isExistingInvestmentEntity ||
          Number.isInteger(applicationDetail?.investmentEntity?.otherType)
        }
        sx={{ mb: 3, gap: 0 }}
        formControlLabelSx={{
          '.MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 500,
            maxWidth: '78px',
          },
        }}
        optionGap={4}
        showPickerOnly={isReviewing}
      />
      <SelectExisting {...props} defaultValue={investmentEntityOtherDefaultValue} />
      <AddNew {...props}>
        {Number.isInteger(watchOtherType) && (
          <Accordion
            defaultExpanded
            sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
          >
            <AccordionSummary
              sx={{
                '.MuiSvgIcon-root': { fontSize: '18px' },
                '.MuiAccordionSummary-content': { my: 0 },
                minHeight: 'unset',
              }}
            >
              <Box className='flex justify-between items-center w-full'>
                <Typography variant='body2' fontWeight={600}>
                  {watchOtherType === INVESTMENT_ENTITY_OTHER.Association && 'Association Details'}
                  {watchOtherType === INVESTMENT_ENTITY_OTHER.GovernmentBody &&
                    'Government Body Details'}
                </Typography>
                {isExistingInvestmentEntity && !isReviewing && (
                  <UpdateDetailsButton
                    entityId={investmentEntityId}
                    disabled={isViewMode || isDisabledEdit}
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              className='p-6 mt-0 border-t'
              sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
            >
              {watchOtherType === INVESTMENT_ENTITY_OTHER.GovernmentBody && (
                <GovernmentBody {...props} hideUBODeclaration={isCreateFundApplicationForm} />
              )}
              {watchOtherType === INVESTMENT_ENTITY_OTHER.Association && (
                <Association {...props} hideUBODeclaration={isCreateFundApplicationForm} />
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </AddNew>
    </Box>
  );
};

export default Other;
