import { Box, BoxProps, styled, SxProps, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { DoneImage } from 'src/assets/images/DoneImage';
import { ErrorImage } from 'src/assets/images/ErrorImage';
import WarningImage from 'src/assets/images/WarningImage';
import CustomButton from 'src/components/atoms/CustomButton';

interface ConfirmationAlertProps extends BoxProps {
  title?: string;
  description: string;
  buttonAction: {
    label: string;
    onAction: () => void;
    sx?: SxProps;
  };
  image?: any;
  isError?: boolean;
  hiddenBtn?: boolean;
  children?: JSX.Element;
  errorTitle?: string;
  closeButton?: boolean;
  onClose?: () => void;
  closeButtonLabel?: string;
  isWarning?: boolean;
}

const CustomContent = styled('div')(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.neutral.ne500,
  maxHeight: 250,
  overflow: 'auto',

  /* Works on firefox */
  scrollbarWidth: 'thin',
  scrollbarColor: theme.palette.neutral.ne300,

  /* Works on Chrome, Edge, and Safari */
  '&::-webkit-scrollbar': {
    width: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral.ne300,
    borderRadius: 3,
  },

  '&::-webkit-scrollbar-track': {
    marginTop: 0,
    marginBottom: 50,
  },
}));

const ConfirmationAlert: React.FC<ConfirmationAlertProps> = ({
  title,
  description,
  buttonAction,
  image,
  isError = false,
  hiddenBtn = false,
  children,
  errorTitle = '',
  className = '',
  closeButton = false,
  closeButtonLabel,
  onClose,
  isWarning,
}) => {
  const handleButtonClick = () => {
    buttonAction.onAction();
  };

  const renderImage = () => {
    if (isError) return <ErrorImage />;
    if (isWarning) return <WarningImage />;

    return image ? image : <DoneImage />;
  };

  const renderTitle = () => {
    if (isError) return errorTitle || 'Oops!';

    return title || 'You did it !';
  };

  return (
    <Box
      className={clsx(
        'flex flex-col items-center w-[485px] rounded-[22px] bg-white pb-10',
        className,
      )}
    >
      <Box className='mt-6'>{renderImage()}</Box>
      <Typography variant='h5' className='mt-[6px]'>
        {renderTitle()}
      </Typography>
      <CustomContent
        className='mt-4 text-center px-10'
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Box className='px-10 w-full'>{children}</Box>
      {!hiddenBtn && (
        <Box className='mt-8 flex items-center gap-4'>
          {closeButton && (
            <CustomButton variant='text' onClick={onClose}>
              {closeButtonLabel || 'Close'}
            </CustomButton>
          )}
          <CustomButton className='h-12' onClick={handleButtonClick} sx={buttonAction.sx}>
            {buttonAction.label}
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default ConfirmationAlert;
