/* eslint-disable @typescript-eslint/no-loss-of-precision */

export enum InvestorTransactionType {
  Issue,
  Redemption,
  Transfer,
  Distribution,
  Payment,
}

export enum InvestorTransactionStatusType {
  AwaitingUnitPrice,
  Cancelled,
  Draft,
  Processed,
  ReadyToProcess,
  Voided,
}

export enum TRANSACTION_DOCUMENT_STATUS_TYPE {
  Processed,
  Voided,
  Partially_Voided,
}

export const transactionStatusOptions = [
  {
    value: InvestorTransactionStatusType.AwaitingUnitPrice,
    label: 'Awaiting Unit Price',
  },
  {
    value: InvestorTransactionStatusType.Cancelled,
    label: 'Cancelled',
  },
  {
    value: InvestorTransactionStatusType.Draft,
    label: 'Draft',
  },
  {
    value: InvestorTransactionStatusType.Processed,
    label: 'Processed',
  },
  {
    value: InvestorTransactionStatusType.ReadyToProcess,
    label: 'Ready to Process',
  },
  {
    value: InvestorTransactionStatusType.Voided,
    label: 'Voided',
  },
];

export const transactionTypeOptions = [
  {
    value: InvestorTransactionType.Issue,
    label: 'Issue',
  },
  {
    value: InvestorTransactionType.Redemption,
    label: 'Redemption',
  },
  {
    value: InvestorTransactionType.Transfer,
    label: 'Transfer',
  },
  {
    value: InvestorTransactionType.Distribution,
    label: 'Distribution',
  },
  {
    value: InvestorTransactionType.Payment,
    label: 'Payment',
  },
];

export const transactionStatusName = {
  Awaiting_Unit_Price: 'Awaiting Unit Price',
  Cancelled: 'Cancelled',
  Draft: 'Draft',
  Processed: 'Processed',
  Ready_to_Process: 'Ready to Process',
  Voided: 'Voided',
  Partially_Voided: 'Partially Voided',
};

export const transactionDefaultValue = {
  clientId: '',
  fundId: '',
  unitClassId: '',
  transactionType: undefined,
  investor: {
    id: '',
    value: '',
    label: '',
  },
  comment: '',
  investmentAmount: 0,
  paidAmount: 0,
  saveDraft: false,
  // redemption
  investorTransactionRedemptionType: null,
  option: null,
  partialRedemptionType: null,
  redemptionAmount: null,
  redemptionUnits: null,
  // transfer
  investorTo: {
    id: '',
    value: '',
    label: '',
  },
  transferMethod: null,
  transferUnits: null,
  considerationAmount: null,
};

export const CREATE_NEW_BATCH_PERMISSION_TOOL_TIP = `You don’t have permissions. Please contact administrator for more information.`;

export enum TRANSACTION_DOCUMENT_STATUS {
  Created,
  Published,
}

export enum DUPLICATE_TRANSACTION_OPTION {
  EXISTING,
  NEW,
}

export const TEST_DATA: any = {
  summary: {
    investmentAmount: 520000,
    paidAmount: 0,
    unPaidAmount: 520000,
    units: 0,
    spCallAmount: 0,
    fundsHeldInReserve: 57000,
  },
  previewItems: [],
  partnershipPreviewItems: [
    {
      firstCode: false,
      prefixCode: 'PNS-RVPR1',
      unitClassId: 'd54044ab-b5c0-4462-85b5-f624fb5c93cd',
      unitClassName: 'Partnership-Rule-1',
      fundsHeldInReserve: 11000.0,
      ownership: 19.23076923076923076923076923,
      transactionIds: [],
      investorId: '62f7909a-d1a9-49d0-9055-2ac48ce3272d',
      investorName: 'Debra Sheppard',
      investorNumber: 'PNS-RVPR100008',
      investmentAmount: 100000.0,
      defaultInvestmentAmount: 100000.0,
      paidAmount: 0.0,
      unPaidAmount: 100000.0,
      investorInfo: {
        id: '00000000-0000-0000-0000-000000000000',
        applicationNumber: null,
        sourceOfFundsType: 2,
        sourceOfFundsTypeName: 'Business Activitiess',
        distributionInstructionType: 0,
        distributionInstructionTypeName: 'Payment to the nominated bank account',
        sourceOfFundsTypeOther: '',
        investorType: 0,
        investorTypeName: 'Wholesale',
        transactionNumber: null,
      },
      applicationsInfo: [],
    },
    {
      firstCode: false,
      prefixCode: 'PNS-RVPR1',
      unitClassId: 'd54044ab-b5c0-4462-85b5-f624fb5c93cd',
      unitClassName: 'Partnership-Rule-1',
      fundsHeldInReserve: 17000.0,
      ownership: 28.84615384615384615384615385,
      transactionIds: ['366f5d91-009a-4bde-bf12-3251212a2794'],
      investorId: '6c5a2b9f-852d-4d4e-ae7e-a0c8f49fb418',
      investorName: 'Dahlia Johnson',
      investorNumber: 'PNS-RVPR100009',
      investmentAmount: 150000.0,
      defaultInvestmentAmount: 50000.0,
      paidAmount: 0.0,
      unPaidAmount: 150000.0,
      investorInfo: {
        id: '00000000-0000-0000-0000-000000000000',
        applicationNumber: null,
        sourceOfFundsType: 2,
        sourceOfFundsTypeName: 'Business Activities',
        distributionInstructionType: 0,
        distributionInstructionTypeName: 'Payment to the nominated bank account',
        sourceOfFundsTypeOther: '',
        investorType: 0,
        investorTypeName: 'Wholesale',
        transactionNumber: null,
      },
      applicationsInfo: [
        {
          id: 'e61b9865-5b02-4159-b0e9-955bdf056a86',
          applicationNumber: 'APP Partnership-Rule-1 - 000032',
          sourceOfFundsType: 2,
          sourceOfFundsTypeName: 'Business Activities',
          distributionInstructionType: 0,
          distributionInstructionTypeName: 'Payment to the nominated bank account',
          sourceOfFundsTypeOther: '',
          investorType: 0,
          investorTypeName: 'Wholesale',
          transactionNumber: '366f5d91009a4bdebf123251212a2794',
        },
        {
          id: 'e61b9865-5b02-4159-b0e9-955bdf056a86',
          applicationNumber: 'APP Partnership-Rule-1 - 000032',
          sourceOfFundsType: 2,
          sourceOfFundsTypeName: 'Business Activities',
          distributionInstructionType: 0,
          distributionInstructionTypeName: 'Payment to the nominated bank account',
          sourceOfFundsTypeOther: '',
          investorType: 0,
          investorTypeName: 'Wholesale',
          transactionNumber: '366f5d91009a4bdebf123251212a2794',
        },
      ],
    },
    {
      firstCode: false,
      prefixCode: 'PNS-RVPR3',
      unitClassId: 'e1e1ad21-8934-4a7d-8a90-d68126fecb71',
      unitClassName: 'Partnership-Rule-3',
      fundsHeldInReserve: 21000.0,
      ownership: 38.46153846153846153846153846,
      transactionIds: ['f37b13f9-623b-4971-aa28-43762f503862'],
      investorId: '88c8892a-e17d-4cb4-aa7e-9b5dd993202e',
      investorName: 'Madison Donovan Company',
      investorNumber: 'PNS-RVPR300005',
      investmentAmount: 200000.0,
      defaultInvestmentAmount: 50000.0,
      paidAmount: 0.0,
      unPaidAmount: 200000.0,
      investorInfo: {
        id: '00000000-0000-0000-0000-000000000000',
        applicationNumber: null,
        sourceOfFundsType: 2,
        sourceOfFundsTypeName: 'Business Activities',
        distributionInstructionType: 0,
        distributionInstructionTypeName: 'Payment to the nominated bank account',
        sourceOfFundsTypeOther: '',
        investorType: 0,
        investorTypeName: 'Wholesale',
        transactionNumber: null,
      },
      applicationsInfo: [
        {
          id: '4be3e350-664d-4437-9b26-01b172639203',
          applicationNumber: 'APP Partnership-Rule-3 - 000029',
          sourceOfFundsType: 2,
          sourceOfFundsTypeName: 'Business Activities',
          distributionInstructionType: 0,
          distributionInstructionTypeName: 'Payment to the nominated bank account',
          sourceOfFundsTypeOther: '',
          investorType: 0,
          investorTypeName: 'Wholesale',
          transactionNumber: '366f5d91009a4bdebf123251212a2794',
        },
      ],
    },
    {
      firstCode: false,
      prefixCode: 'PNS-RVPR3',
      unitClassId: 'e1e1ad21-8934-4a7d-8a90-d68126fecb71',
      unitClassName: 'Partnership-Rule-3',
      fundsHeldInReserve: 8000.0,
      ownership: 13.46153846153846153846153846,
      transactionIds: ['7de54589-e3d5-4e06-b7e1-e2175fe0622b'],
      investorId: null,
      investorName: 'Dale Bernard',
      investorNumber: 'PNS-RVPR300006',
      investmentAmount: 70000.0,
      defaultInvestmentAmount: 70000.0,
      paidAmount: 0,
      unPaidAmount: 70000.0,
      investorInfo: null,
      applicationsInfo: null,
    },
  ],
  firstCode: false,
  prefixCode: '',
  issueDate: '0001-01-01T00:00:00',
  entryUnitPrice: false,
  unitPrice: 0,
  isAllTransaction: false,
  hasActiveInvestor: true,
  currency: 0,
  currencyName: 'AUD',
  ownershipPercentageLimit: 100.0,
  initialCloseDate: '2025-01-01T16:59:59',
  lateInitialCloseDate: '2030-04-30T16:59:59',
};

export enum TRANSACTION_TAB {
  DETAILS,
  DOCUMENTS,
}

export enum REDEMPTION_INVESTOR {
  INDIVIDUAL,
  ALL,
}

export enum REDEMPTION_TYPE {
  PARTIAL,
  FULL,
}

export enum REDEMPTION_AMOUNT_UNITS {
  AMOUNT,
  UNITS,
  OWNERSHIP,
}

export const selectRedemptionInvestorOptions = [
  {
    label: 'Select individual investor',
    value: REDEMPTION_INVESTOR.INDIVIDUAL,
  },
  {
    label: 'Select all investors ',
    value: REDEMPTION_INVESTOR.ALL,
  },
];

export const redemptionTypeOptions = [
  {
    label: 'Full Redemption',
    value: REDEMPTION_TYPE.FULL,
  },
  {
    label: 'Partial Redemption',
    value: REDEMPTION_TYPE.PARTIAL,
  },
];

export const amountOrUnitsOptions = (isPartnership?: boolean) => [
  {
    label: 'Amount',
    value: REDEMPTION_AMOUNT_UNITS.AMOUNT,
  },
  ...(isPartnership
    ? [
        {
          label: 'Ownership %',
          value: REDEMPTION_AMOUNT_UNITS.OWNERSHIP,
        },
      ]
    : [
        {
          label: 'Units',
          value: REDEMPTION_AMOUNT_UNITS.UNITS,
        },
      ]),
];

// TRANSFER TRANSACTION
export enum TRANSACTION_TRANSFER_METHOD {
  Full,
  Partial,
}

export const transferMethodOptions = [
  {
    label: 'Full Holding',
    value: TRANSACTION_TRANSFER_METHOD.Full,
  },
  {
    label: 'Partial Holding',
    value: TRANSACTION_TRANSFER_METHOD.Partial,
  },
];

export enum TRANSFER_TYPE {
  FROM,
  TO,
}
