/* eslint-disable no-restricted-imports */
import { Box, Grid, Radio, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import { UPSERT_FUND_STEPS } from 'src/components/pages/funds-management/funds/components/UpsertFundsForm';
import { MAX_INVESTOR_NUMBER } from 'src/constants/administration-investor';
import {
  FUNDRAISING_OVERVIEW_STEP,
  finaliseDetailDefaultValue,
  investorTypeLabel,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { FundType } from 'src/constants/funds-management';
import { UnitClassInvestorType, UnitClassOfferType } from 'src/constants/unit-class';
import { useUpdateCurrentUnitPrice } from 'src/modules/administration-investor/hooks';
import {
  useGetApplicationInvestorTypes,
  useGetApplicationOfferUnitClassDetail,
  useGetApplicationOverview,
} from 'src/modules/applications/hooks';
import { IFinaliseDetail, IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { useGetUnitByDate } from 'src/modules/fund-registry/hooks';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { handleErrorFromServer } from 'src/utils/common';

interface IApplicationFinalise extends IUpsertInvestmentApplicationForm {
  isFinalise?: boolean;
  finaliseApproval?: IFinaliseDetail;
}
const ApplicationFinalise = (props: IApplicationFinalise) => {
  const {
    isViewMode,
    unitClassId = '',
    id = '',
    offerId = '',
    fundId = '',
    isFinalise,
    finaliseApproval,
  } = props;
  const modalUpdateCurrentUnitPrice = useRef<IBasicModalElement>(null);
  const confirmModalRef = useRef<IConfirmModalElement>(null);

  const { watch, reset, setValue, getValues, trigger } = useFormContext<IFinaliseDetail>();
  const investorNumberCode = watch('investorNumberCode');
  const firstInvestorNumber = watch('firstInvestorNumber');
  const isCurrentUnitPrice = watch('isCurrentUnitPrice');
  const partiallyPaidUnits = watch('partiallyPaidUnits');
  const unitPrice = watch('unitPrice');
  const unitPriceDecimalPlaces = Number(unitPrice)?.toString().split('.')?.[1]?.length || 0;

  const { data: { detail: fundDetail } = {} } = useGetFundById(
    fundId,
    UPSERT_FUND_STEPS.FUND_DETAILS,
  );
  const fundType = fundDetail?.type;

  const { data: unitClassInvestorTypes = [] } = useGetApplicationInvestorTypes(unitClassId);
  const { data: { finaliseDetail } = {} } = useGetApplicationOverview(
    id,
    FUNDRAISING_OVERVIEW_STEP.FinaliseApplication,
  );
  const { data: applicationOfferUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    offerId,
    unitClassId,
  );
  const currency = applicationOfferUnitClassDetailData?.currency;
  const { mutate: updateCurrentUnitPrice, isLoading: updatingCurrentUnitPrice } =
    useUpdateCurrentUnitPrice();

  /**
   * Get history price from fund registry by transaction/strike date
   * To fill the unitPrice
   */
  const { mutate: getUnitByDate, data: historyUnitPrice } = useGetUnitByDate();

  const conditional1 =
    !finaliseDetail?.allowCapitalCall && !finaliseDetail?.allowPartiallyPaidUnits;
  const conditional2 = finaliseDetail?.allowCapitalCall && !finaliseDetail?.allowPartiallyPaidUnits;
  const conditional3 = finaliseDetail?.allowCapitalCall && finaliseDetail?.allowPartiallyPaidUnits;

  const investorUnit = finaliseDetail?.investorUnit || 2;
  const unitPriceRounding = finaliseDetail?.unitPriceUnit || 2;

  // Fill data for finalise and issue application form
  useEffect(() => {
    if (!isEmpty(finaliseDetail) && historyUnitPrice && !isFinalise) {
      // Use investor number to detect form is filled or not
      const investorNumber = getValues('investorNumber');
      const { entryPrice, midPrice } = historyUnitPrice;
      /**
       * Use midPrice value for unitPrice field if isCurrentUnitPrice is true ( user select Mid Price tab )
       * else we use the entryPrice value instead ( use select Entry Unit Price tab )
       */
      const unitPrice = isCurrentUnitPrice ? midPrice : entryPrice;
      let numberOfUnits = 0;
      if (conditional1) {
        numberOfUnits = finaliseDetail.investmentAmount / unitPrice;
      } else if (conditional2) {
        numberOfUnits = finaliseDetail.amountPaid / unitPrice;
      }
      /**
       * If form is filled, just reset unitPrice and numberOfUnits
       * Else reset all form with finaliseDetail data
       */
      if (investorNumber) {
        setValue('unitPrice', unitPrice);
        setValue('numberOfUnits', numberOfUnits);
      } else {
        reset({
          ...finaliseDetailDefaultValue,
          ...finaliseDetail,
          ...finaliseApproval,
          investorNumber: finaliseDetail?.investorNumberPrefix || '',
          investorNumberCode: finaliseDetail?.investorNumber?.replace(
            finaliseDetail?.investorNumberPrefix || '',
            '',
          ),
          updateUnitPrice: unitPrice,
          unitPrice,
          numberOfUnits,
          unitPriceRounding: finaliseDetail.unitPriceUnit,
        });
      }
      trigger('unitPrice');
    }
  }, [finaliseDetail, historyUnitPrice, isFinalise]);

  // Fill data for view issue details form
  useEffect(() => {
    if (!isEmpty(finaliseDetail) && !isEmpty(finaliseApproval)) {
      let numberOfUnits = 0;
      if (conditional1) {
        numberOfUnits = finaliseDetail.investmentAmount / finaliseApproval.unitPrice;
      } else if (conditional2) {
        numberOfUnits = finaliseDetail.amountPaid / finaliseApproval.unitPrice;
      }
      reset({
        ...finaliseDetailDefaultValue,
        ...finaliseDetail,
        ...finaliseApproval,
        unitPriceRounding: finaliseDetail.unitPriceUnit,
        investorNumber: finaliseDetail?.investorNumberPrefix || '',
        investorNumberCode: finaliseDetail?.investorNumber?.replace(
          finaliseDetail?.investorNumberPrefix || '',
          '',
        ),
        numberOfUnits,
      });
    }
  }, [finaliseDetail, finaliseApproval]);

  useEffect(() => {
    if (conditional3 && !isEmpty(finaliseDetail) && historyUnitPrice) {
      const { entryPrice, midPrice } = historyUnitPrice;
      const unitPrice = isCurrentUnitPrice ? midPrice : entryPrice;
      if (partiallyPaidUnits) {
        setValue('numberOfUnits', finaliseDetail.investmentAmount / unitPrice);
      } else {
        setValue('numberOfUnits', finaliseDetail.amountPaid / unitPrice);
      }
    }
  }, [partiallyPaidUnits, historyUnitPrice]);

  useEffect(() => {
    if (unitPrice && finaliseDetail) {
      let numberOfUnits = 0;
      if (conditional1) {
        numberOfUnits = finaliseDetail.investmentAmount / unitPrice;
      } else if (conditional2) {
        numberOfUnits = finaliseDetail.amountPaid / unitPrice;
      } else {
        if (partiallyPaidUnits) {
          numberOfUnits = finaliseDetail.investmentAmount / unitPrice;
        } else {
          numberOfUnits = finaliseDetail.amountPaid / unitPrice;
        }
      }
      setValue('numberOfUnits', numberOfUnits);
    }
  }, [unitPrice, finaliseDetail, partiallyPaidUnits]);

  // Get unit price history
  useEffect(() => {
    handleGetHistoryUnitPrice(dayjs(watch('transactionDate')).endOf('day').utc().format());
  }, [watch('transactionDate')]);

  const investorTypeOptions: RadioOption[] = unitClassInvestorTypes
    .map(({ id, name }) => ({
      value: id,
      label: id === UnitClassInvestorType.RetailExemption ? investorTypeLabel.Retail : name,
    }))
    .sort((a, b) => b.label?.localeCompare(a.label));

  const handleGetHistoryUnitPrice = (strikeDate: string) => {
    getUnitByDate(
      {
        strikeDate,
        unitClassId,
      },
      { onError: handleErrorFromServer },
    );
  };

  const renderInvestorTypeOptions = (options: RadioOption[], selectedValue?: string | number) => {
    return (
      <Grid container columnSpacing={3} rowSpacing={2.25}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6}>
            <Box
              className='border rounded-xl px-3 py-[1px] flex justify-between items-center'
              borderColor={option.value === selectedValue ? 'primary.main' : 'neutral.ne400'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  width: '100%',
                }}
                disabled={isViewMode}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const priceOptions = [
    { label: 'Entry Unit Price', value: false },
    { label: 'Mid Price', value: true },
  ];

  const handleChangePriceType = (value: boolean) => {
    if (value === isCurrentUnitPrice) return;
    const { midPrice, entryPrice } = historyUnitPrice || {};
    const unitPrice = value ? midPrice : entryPrice;
    setValue('isCurrentUnitPrice', value);
    setValue('unitPrice', unitPrice || 0);
    trigger('unitPrice');
  };

  const handleCloseModalUpdateCurrentUnitPrice = () => {
    modalUpdateCurrentUnitPrice.current?.close();
  };

  const handleOpenModalUpdateCurrentUnitPrice = () => {
    setValue('updateUnitPrice', getValues()?.unitPrice || 0);
    modalUpdateCurrentUnitPrice.current?.open();
  };

  const handleUpdateCurrentUnitPriceConfirmation = () => {
    confirmModalRef.current?.open();
  };

  const handleUpdateCurrentUnitPrice = () => {
    updateCurrentUnitPrice(
      {
        unitClassId,
        data: { unitPrice: getValues()?.updateUnitPrice || 0 },
      },
      {
        onSuccess: () => {
          setValue('unitPrice', getValues()?.updateUnitPrice || 0);
          handleCloseModalUpdateCurrentUnitPrice();
          confirmModalRef.current?.close();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleTransactionDateChange = (date: any) => {
    trigger('transactionDate');
    /**
     * Handle for manually typing the date:
     * Check the date is valid and the date in the past or current date
     * Then we allow to call the API to get price
     */
    const isValid = dayjs(date).isValid() && !dayjs(date).isAfter();
    if (isValid) {
      handleGetHistoryUnitPrice(dayjs(date).endOf('day').utc().format());
    }
  };

  return (
    <>
      <Grid container spacing={4} mt={0} mb={4}>
        <Grid item xs={12}>
          <Box className='flex items-end'>
            <FormInput
              maxLength={MAX_INVESTOR_NUMBER}
              name='investorNumber'
              label='Investor Number'
              placeholder='Enter investor number'
              disabled={isViewMode || !firstInvestorNumber}
              sx={{
                '.MuiInputBase-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              onChange={() => setValue('isOverwriteInvestorNumber', true)}
            />
            <Box
              className='border px-3.5 flex justify-center items-center border-l-0 rounded-tr-xl rounded-br-xl'
              bgcolor='neutral.ne200'
              borderColor='neutral.ne400'
              color='neutral.ne600'
              sx={{ height: '46px' }}
            >
              {investorNumberCode}
            </Box>
          </Box>
        </Grid>
        {finaliseDetail?.offerType === UnitClassOfferType.Wholesale && (
          <Grid item xs={12}>
            <FormRadioGroup
              name='investorType'
              options={investorTypeOptions}
              label='Investor type'
              renderOptions={renderInvestorTypeOptions}
              isEnumValue
              disabled={isViewMode}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormCurrencyInput
            name='investmentAmount'
            label='Investment Amount'
            endAdornment={
              <Typography variant='body2' lineHeight='23px' sx={{ pr: 1.5 }}>
                {currency}
              </Typography>
            }
            hideCurrency
            disabled
            decimalScale={2}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            name='transactionDate'
            label='Transaction Date'
            datePickerProps={{ format: DATE_PICKER_FORMAT }}
            useServerFormat
            disabled={isViewMode}
            disableFuture
            onChange={handleTransactionDateChange}
          />
        </Grid>
        {((fundType === FundType.Trust && (conditional2 || conditional3)) ||
          fundType === FundType.Partnership) && (
          <Grid item xs={6}>
            <FormCurrencyInput
              name='capitalCallAmount'
              label='Capital Call Amount'
              endAdornment={
                <Typography variant='body2' lineHeight='23px' sx={{ pr: 1.5 }}>
                  {currency}
                </Typography>
              }
              hideCurrency
              disabled
              decimalScale={2}
            />
          </Grid>
        )}
        {((fundType === FundType.Trust && (conditional2 || conditional3)) ||
          fundType === FundType.Partnership) && (
          <Grid item xs={6}>
            <FormCurrencyInput
              name='capitalCallPercent'
              label='Capital Call %'
              endAdornment={
                <Typography variant='body2' lineHeight='23px' sx={{ pr: 1.5 }}>
                  %
                </Typography>
              }
              hideCurrency
              disabled
              decimalScale={3}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormCurrencyInput
            name='amountPaid'
            label='Amount Paid'
            endAdornment={
              <Typography variant='body2' lineHeight='23px' sx={{ pr: 1.5 }}>
                {currency}
              </Typography>
            }
            hideCurrency
            disabled
            decimalScale={2}
          />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            name='amountUnpaid'
            label='Amount Unpaid'
            endAdornment={
              <Typography variant='body2' lineHeight='23px' sx={{ pr: 1.5 }}>
                {currency}
              </Typography>
            }
            hideCurrency
            disabled
            decimalScale={2}
            allowNegative
          />
        </Grid>
        {fundType === FundType.Trust && (
          <Grid item xs={12}>
            <Box
              className='rounded-xl border border-solid flex w-fit overflow-hidden'
              borderColor='neutral.ne300'
            >
              {priceOptions.map((it, index) => {
                const isSelected = it.value === isCurrentUnitPrice;
                return (
                  <CustomButton
                    key={index}
                    className={
                      'py-[7px] px-0 w-[130px] flex items-center justify-center rounded-none'
                    }
                    variant='contained'
                    sx={{
                      bgcolor: isSelected ? 'neutral.ne200' : 'white',
                      color: 'neutral.ne800',
                      '&:hover': { backgroundColor: isSelected ? 'neutral.ne200' : 'white' },
                    }}
                    onClick={() => handleChangePriceType(it.value)}
                    disabled={isViewMode}
                  >
                    <Typography variant='body3' fontWeight={isSelected ? 500 : 400}>
                      {it.label}
                    </Typography>
                  </CustomButton>
                );
              })}
            </Box>
          </Grid>
        )}
        {fundType === FundType.Trust && (
          <Grid item xs={12} mt={-2}>
            <Box className='flex w-full items-end gap-4'>
              <FormCurrencyInput
                name='unitPrice'
                label={priceOptions.find((it) => it.value === isCurrentUnitPrice)?.label}
                placeholder='Enter unit price'
                disabled
                endAdornment={
                  <Typography variant='body2' lineHeight='23px'>
                    {currency}
                  </Typography>
                }
                hideCurrency
                containerSx={{ flex: 1 }}
                decimalScale={
                  unitPriceDecimalPlaces <= unitPriceRounding
                    ? unitPriceRounding
                    : unitPriceDecimalPlaces
                }
              />
            </Box>
          </Grid>
        )}
        {fundType === FundType.Trust && conditional3 && (
          <Grid item xs={12} sx={{ '.MuiFormGroup-root': { mb: -2, ml: 1.5 } }}>
            <FormRadioGroup
              name='partiallyPaidUnits'
              label='Partially Paid Units '
              options={TrueFalseRadioOptions}
              isBooleanValue
              disabled={isViewMode}
              row
            />
          </Grid>
        )}
        {fundType === FundType.Trust && (
          <Grid item xs={12}>
            <FormCurrencyInput
              name='numberOfUnits'
              label='Number of Units'
              disabled
              hideCurrency
              decimalScale={investorUnit}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormCheckbox
            name='isSendToCommunicationList'
            label='Send confirmation of investment letter to the nominated communication list.'
            sx={{ alignSelf: 'flex-start' }}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <BasicModal
        ref={modalUpdateCurrentUnitPrice}
        onClose={handleCloseModalUpdateCurrentUnitPrice}
      >
        <Box className='flex flex-col p-10 w-[600px]'>
          <Typography variant='h5' mb={1}>
            Update Unit Price?
          </Typography>
          <Typography variant='body2' color='neutral.ne800' mb={3}>
            Would you like to update and record new unit price?
          </Typography>
          <FormCurrencyInput
            name='updateUnitPrice'
            label='Current Unit Price'
            placeholder='Enter unit price'
            endAdornment={
              <Typography variant='body2' lineHeight='23px'>
                {currency}
              </Typography>
            }
            hideCurrency
            decimalScale={unitPriceRounding}
          />
          <Box className='flex justify-end mt-6 gap-2'>
            <CustomButton
              variant='text'
              disabled={updatingCurrentUnitPrice}
              onClick={handleCloseModalUpdateCurrentUnitPrice}
            >
              <Typography color='primary' variant='body2' fontWeight={500}>
                No
              </Typography>
            </CustomButton>
            <CustomButton
              isLoading={updatingCurrentUnitPrice}
              onClick={handleUpdateCurrentUnitPriceConfirmation}
            >
              <Typography color='white' variant='body2' fontWeight={500}>
                Yes
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </BasicModal>
      <ConfirmModal
        title='Update Unit Price?'
        content='Are you sure you want to update new Unit Price?'
        ref={confirmModalRef}
        ButtonsComponent={
          <>
            <CustomButton variant='text' onClick={() => confirmModalRef.current?.close()}>
              <Typography color='primary' variant='body2' fontWeight={500}>
                No
              </Typography>
            </CustomButton>
            <CustomButton
              isLoading={updatingCurrentUnitPrice}
              onClick={handleUpdateCurrentUnitPrice}
            >
              <Typography color='white' variant='body2' fontWeight={500}>
                Yes
              </Typography>
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default ApplicationFinalise;
