/* eslint-disable no-restricted-imports */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { difference, isEmpty, isNumber } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CheckCircleFillIcon } from 'src/assets/icons/CheckCircleFillIcon';
import { CrossCircleFillIcon } from 'src/assets/icons/CrossCircleFillIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer } from 'src/components/atoms/CustomDrawer';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  APPLICATION_APPROVE_STEP,
  FUNDRAISING_OVERVIEW_STEP,
  applicationStatuses,
  finaliseDetailDefaultValue,
  investorTypeLabel,
} from 'src/constants/applications';
import { DATE_PICKER_FORMAT, DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import { UnitClassInvestorType, UnitClassOfferType } from 'src/constants/unit-class';
import {
  useApproveApplication,
  useConfirmInvestmentAmount,
  useFinaliseApplication,
  useGetApplicationInvestorTypes,
  useGetApplicationOfferUnitClassDetail,
  useGetApplicationOverview,
  useOverrideApplicationTeam,
  useRevertInvestmentAmountReceived,
  useUpdateInvestmentAmountReceived,
} from 'src/modules/applications/hooks';
import {
  IAmountReceived,
  IFinaliseDetail,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { currencyStringToNumber, handleErrorFromServer, roundNumber } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import { formatTransactionID } from 'src/utils/transactions';
import { applicationSummarySchema, finaliseDetailSchema } from 'src/validations/applications';
import { v4 as uuidv4 } from 'uuid';
import ApplicationFinalise from './ApplicationFinalise';

interface IApplicationSummaryProps extends IUpsertInvestmentApplicationForm {
  statusName: string;
}

interface IFields {
  investmentAmountOutstanding: number;
  investmentAmountReceived: IAmountReceived[];
  acceptedBy: string;
  acceptedDate: string;
}

interface IConfirmAmountForm {
  newAmount: number;
  capitalCallAmount: number;
}

const ApplicationSummary: FC<IApplicationSummaryProps> = (props) => {
  const { id: appId = '', offerId = '', unitClassId = '', isViewMode, statusName } = props;
  const isFinalise = statusName === applicationStatuses.Finalised;

  const theme = useTheme();
  const [showFinaliseButton, setShowFinaliseButton] = useState(false);
  const [overrideInvestor, setOverrideInvestor] = useState('');
  const [isInvestmentAmoutnReceivedDirty, setIsInvestmentAmoutnReceivedDirty] = useState<
    boolean | undefined
  >();
  const [alertDescription, setAlertDescription] = useState('');

  const finaliseForm = useForm<IFinaliseDetail>({
    defaultValues: finaliseDetailDefaultValue,
    resolver: yupResolver(finaliseDetailSchema),
  });

  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const modalConfirmNewInvestmentAmountRef = useRef<IBasicModalElement>(null);
  const finaliseDrawerRef = useRef<IBasicModalElement>(null);
  const alertModalRef = useRef<IBasicModalElement & { description: string }>({
    open: () => null,
    close: () => null,
    description: '',
  });
  const confirmModalRef = useRef<IBasicModalElement>(null);

  const { data: { summary, investmentAmount = 0 } = {} } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
  );
  const isApprovedInvestmentAmountReceived = (summary || [])?.some(
    (it) => it.id === APPLICATION_APPROVE_STEP.InvestmentAmountReceived,
  );
  const { data: { investmentAmountCapitalCall } = {} } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.InvestmentAmountCapitalCall,
  );
  const { data: { finaliseApproval } = {} } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.FinaliseApproval,
    isFinalise,
  );

  const { data: { finaliseDetail } = {} } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.FinaliseApplication,
  );

  const {
    percentage = 0,
    fixedAmount = 0,
    allowCapitalCall,
    capitalCall,
  } = investmentAmountCapitalCall || {};
  const { data: { investmentAmountReceived: investmentAmountReceivedData = [] } = {} } =
    useGetApplicationOverview(appId, FUNDRAISING_OVERVIEW_STEP.InvestmentAmount);

  const { data: unitClassInvestorTypes = [] } = useGetApplicationInvestorTypes(unitClassId);
  const { data: applicationOfferUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    offerId,
    unitClassId,
  );
  const { mutate: approveApplication, isLoading: isApprovingApplication } = useApproveApplication();
  const { mutate: confirmInvestmentAmount, isLoading: isConfirmingInvestmentAmount } =
    useConfirmInvestmentAmount();
  const { mutate: updateInvestmentAmountReceived, isLoading: isUpdatingInvestmentAmountReceived } =
    useUpdateInvestmentAmountReceived();
  const { mutate: revertInvestmentAmountReceived, isLoading: isRevertingInvestmentAmountReceived } =
    useRevertInvestmentAmountReceived();
  const { mutate: finaliseApplication, isLoading: isFinalising } = useFinaliseApplication();
  const { mutate: overrideApplicationTeam, isLoading: isOverriding } = useOverrideApplicationTeam();
  const { data: currentUser } = useGetUserInfo();

  const form = useForm<IFields>({
    defaultValues: {
      investmentAmountOutstanding: 0,
      acceptedBy: '',
      acceptedDate: '',
      investmentAmountReceived: [
        {
          receivedAmount: 0,
          receivedDate: '',
        },
      ],
    },
    resolver: yupResolver(applicationSummarySchema),
  });

  const {
    fields: investmentAmountReceivedArray,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'investmentAmountReceived',
    keyName: 'key',
  });

  const confirmAmountForm = useForm<IConfirmAmountForm>({
    defaultValues: {
      newAmount: 0,
      capitalCallAmount: 0,
    },
  });

  const investmentAmountReceived = form.watch('investmentAmountReceived');
  const investmentAmountReceivedFormFields = investmentAmountReceivedArray.map((field, index) => ({
    ...field,
    ...investmentAmountReceived[index],
  }));

  useEffect(() => {
    confirmAmountForm.setValue('newAmount', getNewInvestmentAmount());
  }, [JSON.stringify(investmentAmountReceived), capitalCall, allowCapitalCall]);

  useEffect(() => {
    if (!isEmpty(investmentAmountReceivedData)) {
      form.setValue('investmentAmountReceived', investmentAmountReceivedData);
    }
  }, [investmentAmountReceivedData]);

  useEffect(() => {
    setShowFinaliseButton(isApprovedInvestmentAmountReceived);
  }, [isApprovedInvestmentAmountReceived]);

  useEffect(() => {
    if (typeof isInvestmentAmoutnReceivedDirty === 'boolean' && isInvestmentAmoutnReceivedDirty) {
      showFinaliseButton && setShowFinaliseButton(false);
    }
  }, [isInvestmentAmoutnReceivedDirty]);

  useEffect(() => {
    const investmentAmountReceivedInfo = (summary || []).find(
      (it) => it.id === APPLICATION_APPROVE_STEP.InvestmentAmountReceived,
    );

    if (!isEmpty(investmentAmountReceivedInfo)) {
      form.setValue('acceptedBy', investmentAmountReceivedInfo.approvedBy);
      form.setValue('acceptedDate', utcToLocalTimezone(investmentAmountReceivedInfo.approvedDate));
    }
  }, [summary]);

  useEffect(() => {
    handleCalcCapitalCallAmount();
  }, [confirmAmountForm.watch('newAmount')]);

  // Fill Finalise form
  useEffect(() => {
    if (finaliseApproval) {
      finaliseForm.setValue('investorType', finaliseApproval.investorType);
    }
  }, [finaliseApproval]);

  const calculateOutstanding = (sumOnly = false) => {
    let depositAmount = 0;

    if (allowCapitalCall && capitalCall) {
      depositAmount = investmentAmount * (percentage / 100) + fixedAmount;
    }
    if (!allowCapitalCall || !capitalCall) {
      depositAmount = investmentAmount;
    }

    const sum = investmentAmountReceived.reduce(
      (acc, curr) => acc + (currencyStringToNumber(curr.receivedAmount) || 0),
      0,
    );

    if (sumOnly) return roundNumber(sum, 2);
    else return roundNumber(depositAmount, 2) - roundNumber(sum, 2);
  };

  const getNewInvestmentAmount = () => {
    const receivedAmount = calculateOutstanding(true);

    if (allowCapitalCall && capitalCall) {
      return (receivedAmount - fixedAmount) / (percentage / 100);
    }

    return receivedAmount;
  };

  const isWholeSale =
    applicationOfferUnitClassDetailData?.offerType === UnitClassOfferType.Wholesale;

  const handleApproveApplication = () => {
    approveApplication(
      { id: appId, step: APPLICATION_APPROVE_STEP.ApplicationApproved },
      {
        onSuccess: () => {},
        onError: handleErrorFromServer,
      },
    );
  };

  const handleCheckOutstanding = () => {
    const outstanding = calculateOutstanding();

    if (outstanding !== 0) {
      modalConfirmRef?.current?.open();
    } else if (outstanding === 0) {
      if (isApprovedInvestmentAmountReceived) {
        revertInvestmentAmountReceived(appId, {
          onSuccess: () => {
            handleUpdateAndConfirmInvestmentAmount();
          },
          onError: handleErrorFromServer,
        });
      } else {
        handleUpdateAndConfirmInvestmentAmount();
      }
    }
  };

  const handleOpenNewInvestmentAmountModal = () => {
    modalConfirmNewInvestmentAmountRef.current?.open();
    modalConfirmRef.current?.close();
  };

  const handleConfirmNewInvestmentAmount = (data: IConfirmAmountForm) => {
    const newAmount = currencyStringToNumber(data.newAmount);
    const { capitalCallAmount } = data;

    if (isApprovedInvestmentAmountReceived) {
      revertInvestmentAmountReceived(appId, {
        onSuccess: () => {
          handleUpdateAndConfirmInvestmentAmount({ newAmount, capitalCallAmount });
        },
        onError: handleErrorFromServer,
      });
    } else {
      handleUpdateAndConfirmInvestmentAmount({ newAmount, capitalCallAmount });
    }
  };

  const handleUpdateAndConfirmInvestmentAmount = (data?: {
    newAmount?: number;
    capitalCallAmount?: number | null;
  }) => {
    const { newAmount, capitalCallAmount } = data || {};
    updateInvestmentAmountReceived(
      {
        appId,
        investmentAmount: investmentAmountReceived.map((it) => ({
          ...it,
          receivedAmount: currencyStringToNumber(it.receivedAmount),
        })),
      },
      {
        onSuccess: () => {
          confirmInvestmentAmount(
            {
              appId,
              investmentAmount: isNumber(newAmount)
                ? allowCapitalCall
                  ? currencyStringToNumber(newAmount || '')
                  : calculateOutstanding(true)
                : investmentAmount,
              capitalCallAmount: isNumber(capitalCallAmount) ? capitalCallAmount : null,
            },
            {
              onSuccess: () => {
                setShowFinaliseButton(true);
                isInvestmentAmoutnReceivedDirty && setIsInvestmentAmoutnReceivedDirty(false);
                form.setValue('acceptedBy', `${currentUser?.firstName} ${currentUser?.lastName}`);
                form.setValue('acceptedDate', dayjs().format(DATE_PICKER_FORMAT));
                modalConfirmNewInvestmentAmountRef.current?.close();
              },
              onError: handleErrorFromServer,
            },
          );
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleReceivedFieldChange = () => {
    !isInvestmentAmoutnReceivedDirty && setIsInvestmentAmoutnReceivedDirty(true);
  };

  const handleCalcCapitalCallAmount = () => {
    const capitalCallAmount = capitalCall
      ? currencyStringToNumber(confirmAmountForm.watch('newAmount')) * (percentage / 100) +
        fixedAmount
      : currencyStringToNumber(confirmAmountForm.watch('newAmount'));

    confirmAmountForm.setValue('capitalCallAmount', capitalCallAmount);
  };

  const handleFinaliseAndSubmitForIssue = () => {
    const { investorType } = finaliseForm.getValues();

    finaliseApplication(
      {
        appId,
        data: {
          investorType:
            finaliseDetail?.offerType === UnitClassOfferType.Wholesale
              ? (investorType as UnitClassInvestorType)
              : null,
        },
      },
      {
        onSuccess: (batchId) => {
          const description = `Transaction added to - Batch ID ${formatTransactionID(
            batchId,
          )} <br /> Please proceed to Administration - Transactions to finalise.`;
          setAlertDescription(description);

          confirmModalRef.current?.close();
          alertModalRef.current.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const summaryList = useMemo(
    () =>
      [
        {
          label: 'Investor type approved',
          value: APPLICATION_APPROVE_STEP.InvestorTypeApproved,
          hidden: !isWholeSale,
        },
        {
          label: 'Investment amount approved',
          value: APPLICATION_APPROVE_STEP.InvestmentAmountApproved,
        },
        { label: 'Application signed', value: APPLICATION_APPROVE_STEP.ApplicationSigned },
        { label: 'AML/KYC approved', value: APPLICATION_APPROVE_STEP.KycApproved },
        { label: 'Application approved', value: APPLICATION_APPROVE_STEP.ApplicationApproved },
        {
          label: 'Investment amount received',
          value: APPLICATION_APPROVE_STEP.InvestmentAmountReceived,
        },
        { label: 'Application finalised', value: APPLICATION_APPROVE_STEP.ApplicationFinalised },
      ].filter((it) => !it.hidden),
    [isWholeSale],
  );

  const currentSummaryStatus = useMemo(() => {
    return summary?.map((it) => it.id) || [];
  }, [summary]);

  const isShowApproveApplication =
    difference(
      [
        APPLICATION_APPROVE_STEP.InvestorTypeApproved,
        APPLICATION_APPROVE_STEP.InvestmentAmountApproved, //note: comment vì ko có real data test, nên uncomment
        APPLICATION_APPROVE_STEP.ApplicationSigned,
        APPLICATION_APPROVE_STEP.KycApproved,
      ],
      currentSummaryStatus,
    )?.length === 0 && !currentSummaryStatus.includes(APPLICATION_APPROVE_STEP.ApplicationApproved);

  const showInvestmentAmountReceived = currentSummaryStatus.includes(
    APPLICATION_APPROVE_STEP.ApplicationApproved,
  );

  const renderAcceptedBy = () => {
    if (!form.watch('acceptedBy') || !form.watch('acceptedDate')) return null;

    return (
      <CustomAlert
        severity='info'
        sx={{
          mt: 3,
        }}
      >{`Accepted by ${form.watch('acceptedBy')} on ${form.watch('acceptedDate')}.`}</CustomAlert>
    );
  };

  const renderInvestorTypeOptions = (options: RadioOption[], selectedValue?: string | number) => {
    return (
      <Grid container columnSpacing={3} rowSpacing={2.25}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6}>
            <Box
              className='border rounded-xl px-3 flex justify-between items-center'
              borderColor={option.value === selectedValue ? 'primary.main' : 'neutral.ne400'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  width: '100%',
                }}
                disabled={isViewMode || isFinalise}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const investorTypeOptions: RadioOption[] = unitClassInvestorTypes
    .map(({ id, name }) => ({
      value: id,
      label: id === UnitClassInvestorType.RetailExemption ? investorTypeLabel.Retail : name,
    }))
    .sort((a, b) => b.label?.localeCompare(a.label));

  const renderConfirmInvestorType = () => {
    if (!showFinaliseButton) return null;
    const isOldFlow = !finaliseApproval?.hasFinaliseTransaction;

    // Skip this for old flow after finalise
    if (isOldFlow && isFinalise) return null;

    return (
      <FormProvider {...finaliseForm}>
        <Box className='flex flex-col mt-3 rounded-[10px] p-6' bgcolor={'neutral.ne100'}>
          {finaliseDetail?.offerType === UnitClassOfferType.Wholesale && (
            <>
              <Typography variant='body2' fontWeight={600} className='pb-6'>
                Confirm Investor Type
              </Typography>
              <Box mb={3}>
                <FormRadioGroup
                  name='investorType'
                  options={investorTypeOptions}
                  label='Investor type'
                  renderOptions={renderInvestorTypeOptions}
                  isEnumValue
                  disabled={isViewMode || isFinalise}
                />
              </Box>
            </>
          )}
          {isFinalise && (
            <CustomAlert
              severity='info'
              sx={{
                mb: 3,
              }}
            >{`Submitted for issue by ${finaliseApproval?.finalisedByName} on ${utcToLocalTimezone(
              finaliseApproval?.finalisedDate,
            )}.`}</CustomAlert>
          )}
          {showFinaliseButton && (
            <CustomButton
              disabled={isFinalise || isViewMode}
              sx={{ alignSelf: 'flex-end' }}
              onClick={() => {
                if (finaliseDetail?.batchId) {
                  confirmModalRef?.current?.open();
                } else handleFinaliseAndSubmitForIssue();
              }}
              isLoading={isFinalising}
            >
              Finalise and Submit for Issue
            </CustomButton>
          )}
        </Box>
      </FormProvider>
    );
  };

  const renderInvestmentAmountReceivedSection = () => {
    if (!showInvestmentAmountReceived) return null;
    const isOldFlow = !finaliseApproval?.hasFinaliseTransaction;

    return (
      <Box className='flex flex-col mt-3 rounded-[10px] p-6' bgcolor={'neutral.ne100'}>
        <FormProvider {...form}>
          <Typography variant='body2' fontWeight={600} className='pb-6'>
            Amount Received
          </Typography>
          <FormCurrencyInput
            name='investmentAmountOutstanding'
            label='Outstanding'
            readOnly
            value={calculateOutstanding()}
            hideCurrency
            endAdornment={
              <Typography variant='body3' fontWeight='medium'>
                {applicationOfferUnitClassDetailData?.currency || ''}
              </Typography>
            }
            allowNegative
            decimalScale={2}
          />
          <Box
            className='flex-1 flex items-center border rounded-xl p-4 mt-4'
            bgcolor={'white'}
            borderColor={'neutral.ne200'}
          >
            <Grid container>
              {investmentAmountReceivedFormFields.map((item, index) => {
                return (
                  <Grid container spacing={2} xs={12} key={item.key}>
                    <Grid item xs className='mb-4'>
                      <FormCurrencyInput
                        name={`investmentAmountReceived.${index}.receivedAmount`}
                        label='Received'
                        hideCurrency
                        endAdornment={
                          <Typography variant='body3' fontWeight='medium'>
                            {applicationOfferUnitClassDetailData?.currency || ''}
                          </Typography>
                        }
                        readOnly={isViewMode || isFinalise}
                        onChange={handleReceivedFieldChange}
                        decimalScale={2}
                      />
                    </Grid>
                    <Grid item xs>
                      <FormDatePicker
                        name={`investmentAmountReceived.${index}.receivedDate`}
                        label='Date Received'
                        datePickerProps={{ format: DATE_PICKER_FORMAT }}
                        useServerFormat
                        disableFuture
                        disabled={isViewMode || isFinalise}
                        onChange={handleReceivedFieldChange}
                      />
                    </Grid>
                    {investmentAmountReceivedFormFields.length > 1 &&
                      !isViewMode &&
                      !isFinalise && (
                        <Grid item xs={1} className={'flex items-center justify-end mt-4'}>
                          <IconButton
                            disabled={isViewMode}
                            onClick={() => {
                              remove(index);
                              !isInvestmentAmoutnReceivedDirty &&
                                setIsInvestmentAmoutnReceivedDirty(true);
                            }}
                            sx={{ p: 0 }}
                          >
                            <DeleteIcon
                              width={24}
                              height={24}
                              color={theme.palette.neutral.ne600}
                            />
                          </IconButton>
                        </Grid>
                      )}
                  </Grid>
                );
              })}
              {investmentAmountReceivedArray?.length <= 2 && !isViewMode && !isFinalise ? (
                <Box
                  className='flex items-center cursor-pointer'
                  onClick={() => {
                    append({
                      receivedAmount: 0,
                      receivedDate: dayjs().format(DATE_PICKER_FORMAT_SERVER),
                      id: uuidv4(),
                    });
                    !isInvestmentAmoutnReceivedDirty && setIsInvestmentAmoutnReceivedDirty(true);
                  }}
                >
                  <PlusPrimaryIcon />
                  <Typography variant='body2' className='font-medium'>
                    Add More
                  </Typography>
                </Box>
              ) : null}
            </Grid>
          </Box>
          {showFinaliseButton && renderAcceptedBy()}
          {!showFinaliseButton && (
            <CustomButton
              disabled={isViewMode}
              sx={{ alignSelf: 'flex-end', mt: 3 }}
              onClick={form.handleSubmit(handleCheckOutstanding)}
              isLoading={
                isConfirmingInvestmentAmount ||
                isUpdatingInvestmentAmountReceived ||
                isRevertingInvestmentAmountReceived ||
                isApprovingApplication
              }
            >
              Accept
            </CustomButton>
          )}
          {isOldFlow && isFinalise && (
            <CustomButton
              sx={{ alignSelf: 'flex-end', mt: 3 }}
              onClick={() => finaliseDrawerRef.current?.open()}
            >
              View Issue Details
            </CustomButton>
          )}
        </FormProvider>
        <ConfirmModal
          ref={modalConfirmRef}
          title={`Vary investment amount?`}
          content={`Received amount differs from investment amount. Would you like to vary the investment and accept?`}
          ButtonsComponent={
            <>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={() => modalConfirmRef?.current?.close()}
              >
                No
              </CustomButton>
              <CustomButton onClick={handleOpenNewInvestmentAmountModal}>Yes</CustomButton>
            </>
          }
        />
      </Box>
    );
  };

  const renderNewInvestmentAmountConfirmContent = () => {
    // logic: if allowCapitalCall = true, show 2 fields and newAmount is editable, else show 1 field readonly
    return (
      <DialogContent sx={{ p: 0, pb: 3 }} className='p-8'>
        <FormProvider {...confirmAmountForm}>
          <FormCurrencyInput
            name='newAmount'
            label='New Investment Amount'
            readOnly={!allowCapitalCall}
            hideCurrency
            endAdornment={
              <Typography variant='body3' fontWeight='medium'>
                {applicationOfferUnitClassDetailData?.currency || ''}
              </Typography>
            }
            allowNegative
            decimalScale={2}
          />
          {allowCapitalCall ? (
            <FormCurrencyInput
              name='capitalCallAmount'
              label='Capital Call Amount'
              hideCurrency
              endAdornment={
                <Typography variant='body3' fontWeight='medium'>
                  {applicationOfferUnitClassDetailData?.currency || ''}
                </Typography>
              }
              decimalScale={2}
            />
          ) : null}
        </FormProvider>
      </DialogContent>
    );
  };

  const handleUpdateInvestorProfile = () => {
    overrideApplicationTeam(
      { appId, investorId: overrideInvestor },
      {
        onSuccess: () => confirmModalRef.current?.close(),
        onError: handleErrorFromServer,
      },
    );
  };

  return (
    <Box className='flex flex-col'>
      <Box
        className='flex flex-col mt-3 border border-solid rounded-[10px]'
        borderColor={'neutral.ne200'}
      >
        {summaryList.map((it) => (
          <Box
            key={it.value}
            display={'flex'}
            sx={{ '&:last-child': { div: { borderBottom: '0 !important' } } }}
          >
            <Box
              className='flex-1 flex items-center p-4 border-b border-r border-r-solid'
              borderColor={'neutral.ne200'}
            >
              {it.label}
            </Box>
            <Box
              className='px-6 flex items-center justify-center border-b border-r-solid'
              borderColor={'neutral.ne200'}
            >
              {currentSummaryStatus.includes(it.value) ? (
                <CheckCircleFillIcon />
              ) : (
                <CrossCircleFillIcon color={theme.palette.neutral.ne400} />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {isShowApproveApplication && (
        <CustomButton
          sx={{ alignSelf: 'flex-end', mt: 3 }}
          onClick={handleApproveApplication}
          disabled={currentSummaryStatus.includes(APPLICATION_APPROVE_STEP.ApplicationApproved)}
          isLoading={isApprovingApplication}
        >
          Approve Application
        </CustomButton>
      )}
      {renderInvestmentAmountReceivedSection()}
      {renderConfirmInvestorType()}
      <BasicModal ref={modalConfirmNewInvestmentAmountRef} title={``} maxWidth='sm' fullWidth>
        {renderNewInvestmentAmountConfirmContent()}
        <DialogActions sx={{ p: 0 }} className='pb-8 pr-8'>
          {
            <>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={() => modalConfirmNewInvestmentAmountRef?.current?.close()}
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={confirmAmountForm.handleSubmit(handleConfirmNewInvestmentAmount)}
                isLoading={
                  isConfirmingInvestmentAmount ||
                  isUpdatingInvestmentAmountReceived ||
                  isRevertingInvestmentAmountReceived ||
                  isApprovingApplication
                }
              >
                Confirm
              </CustomButton>
            </>
          }
        </DialogActions>
      </BasicModal>
      <CustomDrawer
        ref={finaliseDrawerRef}
        title={isFinalise ? 'View Issue Details' : 'Finalise Application and Issue'}
        ButtonComponents={
          <>
            <CustomButton
              variant='text'
              sx={{ color: 'neutral.ne800' }}
              onClick={() => finaliseDrawerRef.current?.close()}
              disabled={isFinalising}
            >
              Cancel
            </CustomButton>
            <CustomButton
              isLoading={isFinalising}
              disabled={props.isViewMode || isFinalise || !isEmpty(finaliseForm.formState.errors)}
            >
              Issue
            </CustomButton>
          </>
        }
      >
        <FormProvider {...finaliseForm}>
          <ApplicationFinalise
            {...props}
            isViewMode={isFinalise || props.isViewMode}
            isFinalise={isFinalise}
            finaliseApproval={finaliseApproval}
          />
        </FormProvider>
      </CustomDrawer>
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title='You did it'
          description={alertDescription}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertModalRef?.current?.close();
              if (overrideInvestor) {
                confirmModalRef.current?.open();
              }
            },
          }}
        />
      </BasicModal>
      <ConfirmModal
        ref={confirmModalRef}
        title={`Pending transaction already exists for this investor in Batch ID ${formatTransactionID(
          finaliseDetail?.batchId,
        )}`}
        content={'Are you sure you want to continue?'}
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '600px !important',
          },
        }}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => confirmModalRef.current?.close()}
              disabled={isFinalising}
            >
              No
            </CustomButton>
            <CustomButton onClick={handleFinaliseAndSubmitForIssue} isLoading={isFinalising}>
              Submit For Issue
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default ApplicationSummary;
