import * as React from 'react';

interface IInfoOutlinedIconProps extends React.SVGProps<SVGSVGElement> {}

const InfoOutlinedIcon: React.FunctionComponent<IInfoOutlinedIconProps> = ({
  width = '20',
  height = '20',
  color = '#33B27F',
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
    >
      <rect opacity='0.01' width='20' height='20' fill={color} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C12.2101 1.66675 14.3297 2.54472 15.8925 4.10752C17.4553 5.67033 18.3333 7.78994 18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6025 1.66663 10.0001ZM3.33329 10.0001C3.33329 13.682 6.31806 16.6667 9.99996 16.6667C11.7681 16.6667 13.4638 15.9644 14.714 14.7141C15.9642 13.4639 16.6666 11.7682 16.6666 10.0001C16.6666 6.31818 13.6819 3.33341 9.99996 3.33341C6.31806 3.33341 3.33329 6.31818 3.33329 10.0001Z'
        fill={color}
      />
      <circle cx='9.99996' cy='13.3333' r='0.833333' fill={color} />
      <path
        d='M9.99996 5.83325C9.53972 5.83325 9.16663 6.20635 9.16663 6.66659V10.8333C9.16663 11.2935 9.53972 11.6666 9.99996 11.6666C10.4602 11.6666 10.8333 11.2935 10.8333 10.8333V6.66659C10.8333 6.20635 10.4602 5.83325 9.99996 5.83325Z'
        fill={color}
      />
    </svg>
  );
};

export default InfoOutlinedIcon;
