import { AutocompleteChangeReason, Box, Grid, Typography, debounce } from '@mui/material';
import { ChangeEvent, ReactNode, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormSelect from 'src/components/atoms/FormSelect';
import FormSwitch from 'src/components/atoms/FormSwitch';
import { AUSTRALIA_STATE_ENUM, AUSTRALIA_STATE_OPTIONS } from 'src/constants/address';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { detectPortalType } from 'src/helpers/common';
import { useCountry, useQueryLoqateAddress } from 'src/modules/common/hooks';

export type IndividualBasicInfoFieldsType =
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'dob'
  | 'residentialAddress'
  | 'contactNumber'
  | 'email'
  | 'position';

interface IIndividualBasicInfo {
  keyName: string;
  disabled?: boolean;
  position?: {
    show?: boolean;
    disabled?: boolean;
  };
  hiddenFields?: IndividualBasicInfoFieldsType[];
  disabledFields?: IndividualBasicInfoFieldsType[];
  additionalDetail?: ReactNode;
  autocompleteAddress?: boolean;
}

const IndividualBasicInfo = (props: IIndividualBasicInfo) => {
  const {
    keyName,
    disabled,
    additionalDetail,
    position,
    hiddenFields,
    disabledFields = [],
    autocompleteAddress,
  } = props;
  const { countryOptions } = useCountry();
  const { isInvestor } = detectPortalType();

  const { setValue, watch, trigger } = useFormContext();
  const watchIsManualResidentialAddress = watch(`${keyName}.residentialManualEntry`);
  const isForeignCountry = watch(`${keyName}.residentialCountry`) !== DEFAULT_COUNTRY;

  const {
    data: loqateAdress,
    isLoading: isSearchingAddress,
    params,
    setParams,
  } = useQueryLoqateAddress();

  const handleSearchAddressInputChange = debounce((_event: ChangeEvent<unknown>, value: string) => {
    setParams({
      fullAddress: value,
    });
  }, 500);

  const handleAddressChange = (
    _event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const address = Array.isArray(data) ? data[0] : data;
    if (reason === 'selectOption') {
      setValue(`${keyName}.residentialAddress`, address?.value || '');
      trigger(`${keyName}.residentialAddress`);
    }
  };

  const handleManualChange = (checked: boolean) => {
    if (checked) {
      !watch(`${keyName}.residentialCountry`) &&
        setValue(`${keyName}.residentialCountry`, DEFAULT_COUNTRY);
      !Number.isInteger(watch(`${keyName}.residentialState`)) &&
        setValue(`${keyName}.residentialState`, AUSTRALIA_STATE_ENUM.ACT);
    }
  };

  const options = useMemo(() => {
    return loqateAdress?.payload?.map((it) => ({
      id: it.id,
      value: it.fullAddress,
      label: it.fullAddress,
    }));
  }, [loqateAdress]);

  const renderManualResidentialAddress = () => {
    return (
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <Box className='header flex justify-between items-center'>
            <Typography variant='body3' className='flex'>
              Residential Address
            </Typography>
            <Box width={150}>
              <FormSwitch
                name={`${keyName}.residentialManualEntry`}
                inputProps={{ 'aria-label': 'controlled' }}
                label={
                  <Typography ml='10px' variant='body3' color='neutral.ne800'>
                    Enter manually
                  </Typography>
                }
                disabled={disabled}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormSelect
            name={`${keyName}.residentialCountry`}
            options={countryOptions}
            label='Country'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4}>
          {!isForeignCountry && (
            <FormSelect
              name={`${keyName}.residentialState`}
              options={AUSTRALIA_STATE_OPTIONS}
              label='State'
              disabled={disabled}
            />
          )}
        </Grid>
        <Grid item xs={8}>
          <FormInput
            name={`${keyName}.residentialSuburb`}
            label={isForeignCountry ? 'City/Suburb' : 'Suburb'}
            placeholder={isForeignCountry ? 'Enter city/suburb' : 'Enter suburb'}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            name={`${keyName}.residentialPostcode`}
            label={isForeignCountry ? 'Postcode/Zip' : 'Postcode'}
            placeholder={isForeignCountry ? 'Enter postcode/zip' : 'Enter postcode'}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            name={`${keyName}.residentialStreetNumber`}
            label='Street Number'
            placeholder='Enter street number'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={8}>
          <FormInput
            name={`${keyName}.residentialStreetName`}
            label='Street Name'
            placeholder='Enter street name'
            disabled={disabled}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2.5}>
      {additionalDetail}
      <Grid item xs={6}>
        <FormInput
          name={`${keyName}.firstName`}
          label='First Name'
          placeholder='Enter first name'
          disabled={disabled || disabledFields.includes('firstName')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name={`${keyName}.middleName`}
          label='Middle Name'
          placeholder='Enter middle name'
          disabled={disabled || disabledFields.includes('middleName')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name={`${keyName}.lastName`}
          label='Last Name'
          placeholder='Enter last name'
          disabled={disabled || disabledFields.includes('lastName')}
        />
      </Grid>
      {!hiddenFields?.includes('dob') && (
        <Grid item xs={6}>
          <FormDatePicker
            name={`${keyName}.dob`}
            label='Date of Birth'
            datePickerProps={{ format: DATE_PICKER_FORMAT }}
            disabled={disabled || disabledFields.includes('dob')}
            disableFuture
            useServerFormat
          />
        </Grid>
      )}
      {!hiddenFields?.includes('residentialAddress') && !watchIsManualResidentialAddress && (
        <Grid item xs={12}>
          <Box className='w-full flex justify-between gap-4'>
            <Box className='flex-1'>
              {autocompleteAddress ? (
                <FormAutocomplete
                  name={`${keyName}.residentialAddress`}
                  options={options}
                  searchIcon={<SearchIcon />}
                  isLoading={isSearchingAddress && !!params.payload[0].fullAddress}
                  label='Residential Address'
                  placeholder='Enter residential address'
                  hidePopupIcon
                  fullWidth
                  filterOptions={(options) => options}
                  onInputChange={handleSearchAddressInputChange}
                  onChange={handleAddressChange}
                  disabled={disabled || disabledFields.includes('residentialAddress')}
                  blurOnSelect
                />
              ) : (
                <FormInput
                  name={`${keyName}.residentialAddress`}
                  label='Residential Address'
                  placeholder='Enter residential address'
                  disabled={disabled || disabledFields.includes('residentialAddress')}
                />
              )}
            </Box>

            <Box width={150}>
              <FormSwitch
                name={`${keyName}.residentialManualEntry`}
                inputProps={{ 'aria-label': 'controlled' }}
                label={
                  <Typography ml='10px' variant='body3' color='neutral.ne800'>
                    Enter manually
                  </Typography>
                }
                handleChange={handleManualChange}
                disabled={disabled}
              />
            </Box>
          </Box>
        </Grid>
      )}
      {watchIsManualResidentialAddress && (
        <Grid item xs={12}>
          {renderManualResidentialAddress()}
        </Grid>
      )}
      {position?.show && (
        <Grid
          item
          xs={
            isInvestor
              ? hiddenFields?.includes('residentialAddress')
                ? 6
                : 12
              : watchIsManualResidentialAddress
              ? 12
              : 6
          }
        >
          <FormInput
            name={`${keyName}.position`}
            label='Position'
            placeholder='Enter position'
            disabled={position?.disabled || disabledFields.includes('position')}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <FormInput
          name={`${keyName}.email`}
          label='Email Address'
          placeholder='Enter email address'
          disabled={disabled || disabledFields.includes('email')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormPhoneCountryInput
          name={`${keyName}.contactNumber`}
          countryFlagCodeName={`${keyName}.countryFlagCode`}
          label='Contact Number'
          size='small'
          disabled={disabled || disabledFields.includes('contactNumber')}
          isIncludeCode
        />
      </Grid>
    </Grid>
  );
};

export default IndividualBasicInfo;
