/* eslint-disable autofix/no-unused-vars */
import dayjs from 'dayjs';
import { ServiceProviderTypeEnum } from 'src/modules/funds-management/type';
import { DEFAULT_COUNTRY, actionPermissionKeys } from './common';

export enum FUND_STATUS {
  Draft,
  Pending,
  Open,
  Active,
  Inactive,
}

export const MAX_APPROVED_SIGNATORIES_USERS = 10;

export enum FundType {
  Trust,
  Partnership,
}

export enum TrustType {
  FixedUnit,
  Discretionary,
}

export enum SubType {
  Standard,
  VCType,
}

export enum Mit {
  Yes,
  No,
}

export enum Amit {
  Yes,
  No,
}

export enum TeamUserType {
  FAUser,
  OurTeam,
  ClientTeam,
}

export enum FundOfferType {
  Wholesale,
  Retail,
  Hybrid,
}

export enum InvestorRelationsContact {
  Individual,
  Organisation,
}

export const fundsDetailDefaultValues = {
  name: '',
  code: '',
  abn: '',
  tfn: '',
  offerType: '',
  industryCode: '',
  type: FundType.Trust,
  trustType: TrustType.FixedUnit,
  subType: SubType.Standard,
  mit: Mit.Yes,
  amit: Amit.Yes,
  commencementDate: dayjs(Date.now()),
  isSaveDraft: false,
  clientId: '',
  currency: 0,
  ownershipPercentageLimit: null,
  initialCloseDate: null,
  lateInitialCloseDate: null,
};
export const contactDetailsDefaultValues = {
  isSaveDraft: false,
  contacts: [
    {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      contactNumber: '',
      isPrimary: true,
      countryFlagCode: DEFAULT_COUNTRY,
    },
  ],
  investorContact: {
    firstName: '',
    lastName: '',
    organisationName: '',
    contactName: '',
    position: '',
    email: '',
    contactNumber: '',
    countryFlagCode: DEFAULT_COUNTRY,
    option: InvestorRelationsContact.Individual,
  },
};

export const signatoryContactDefaultValues = {
  isSaveDraft: false,
  signatoryContacts: [],
};

export const createSignatoryContactDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  position: '',
  countryFlagCode: DEFAULT_COUNTRY,
};

export const fundOverviewAndBrandingDefaultValues = {
  isSaveDraft: false,
  description: '',
  overview: '',
  correspondenceDisclaimer: '',
  fundContactId: '',
  signatoryPerson: {},
  brandingDetails: [],
  documents: [],
};

export const financialDetailsDefaultValues = {
  investorUnit: 4,
  unitPrice: 4,
  bankAccounts: [],
  bPays: [],
  isSaveDraft: false,
};

export const teamDefaultValues = {
  internalTeams: [],
  clientTeams: [],
  isSaveDraft: false,
};

export const FundTypeOptions = [
  {
    label: 'Trust',
    value: FundType.Trust,
  },
  {
    label: 'Partnership',
    value: FundType.Partnership,
  },
];

export const TrustTypeOptions = [
  {
    label: 'Fixed Unit',
    value: TrustType.FixedUnit,
  },
  {
    label: 'Discretionary',
    value: TrustType.Discretionary,
  },
];

export const SubTypeOptions = [
  {
    label: 'Standard',
    value: SubType.Standard,
  },
  {
    label: 'VC Type',
    value: SubType.VCType,
  },
];

export const MitAndAmitOptions = [
  {
    label: 'Yes',
    value: Mit.Yes,
  },
  {
    label: 'No',
    value: Mit.No,
  },
];

export const InvestorRelationsContactOptions = [
  {
    label: 'Individual',
    value: InvestorRelationsContact.Individual,
  },
  {
    label: 'Organisation',
    value: InvestorRelationsContact.Organisation,
  },
];

export const fundStatuses = {
  Active: 'Active',
  Pending: 'Pending',
  Draft: 'Draft',
  Open: 'Open',
  Inactive: 'Inactive',
};

export const fundActionTypes = {
  view: 'view',
  edit: 'edit',
  deactivate: 'deactivate',
  activate: 'activate',
  delete: 'delete',
};

export const fundActionMenus = [
  {
    key: fundActionTypes.view,
    label: 'View',
    permissionKey: actionPermissionKeys.isView,
  },
  {
    key: fundActionTypes.edit,
    label: 'Edit',
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: fundActionTypes.deactivate,
    label: 'Disable',
  },
  {
    key: fundActionTypes.activate,
    label: 'Enable',
  },
  {
    key: fundActionTypes.delete,
    label: 'Delete',
    permissionKey: actionPermissionKeys.isDelete,
  },
];

export const unitClassStatuses = {
  Active: 'Active',
  Pending: 'Pending',
  Draft: 'Draft',
  Archived: 'Archived',
};

export const unitClassActionTypes = {
  view: 'view',
  edit: 'edit',
  delete: 'delete',
  duplicate: 'duplicate',
  archive: 'archive',
};

export const fundTrusteeAndServiceProvidersDefaultValues = {
  trustee: {
    capacity: '',
    existing: '',
    newExisting: [],
    isAddNew: false,
    type: ServiceProviderTypeEnum.TrusteeGpDetails,
  },
  manager: {
    capacity: '',
    existing: '',
    newExisting: [],
    isAddNew: false,
    type: ServiceProviderTypeEnum.InvestmentManager,
  },
  providers: {
    capacity: [],
    existing: [],
    newExisting: [],
    type: ServiceProviderTypeEnum.ServiceProviders,
  },
  isSaveDraft: false,
  clientId: '',
};

export const upsertProviderDefaultValues = {
  id: '',
  capacities: undefined,
  capacityNames: [],
  otherCapacity: '',
  hasOtherCapacity: false,
  displayName: '',
  legalName: '',
  abn: '',
  serviceProvidersSection: false,
  primaryContacts: [
    {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '',
      countryFlagCode: DEFAULT_COUNTRY,
      clientId: '',
    },
  ],
  physicalAddress: {
    country: {
      value: '',
      label: '',
      id: '',
    },
    state: {
      value: '',
      label: '',
      id: '',
    },
    city: {
      value: '',
      label: '',
      id: '',
    },
    postcode: '',
    streetAddress: '',
    manualEntry: false,
  },
  postalAddress: {
    country: {
      value: '',
      label: '',
      id: '',
    },
    state: {
      value: '',
      label: '',
      id: '',
    },
    city: {
      value: '',
      label: '',
      id: '',
    },
    postcode: '',
    streetAddress: '',
    manualEntry: false,
  },
  isUseSameAsPhysicalAddress: false,
  setAsInvestmentManager: false,
};
