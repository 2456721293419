import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  StandardTextFieldProps,
  SxProps,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { isNumber } from 'lodash';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useGetCurrencies } from 'src/modules/unit-class/hooks';
import { currencyStringToNumber, roundNumber } from 'src/utils/common';
import CustomHelperText from './CustomHelperText';
import { CustomMenuItem, CustomSelect } from './CustomSelect';
import { CustomTextField } from './FormInput';

interface IFormCurrencyInputProps extends StandardTextFieldProps {
  name: string;
  label?: string | ReactNode;
  optional?: string | ReactNode;
  defaultMessage?: string;
  readOnly?: boolean;
  hideCurrency?: boolean;
  hideInput?: boolean;
  decimalScale?: number;
  max?: number;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  containerSx?: SxProps;
  allowedNull?: boolean;
  allowNegative?: boolean;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  hideError?: boolean;
  displayEmpty?: boolean;
  focus?: boolean;
}

const CURRENCY_DEFAULT_VALUE = 0;

const FormCurrencyInput: FC<IFormCurrencyInputProps> = ({
  name,
  label = '',
  optional = '',
  defaultMessage = '',
  defaultValue = '',
  type,
  readOnly = false,
  hideCurrency = false,
  hideInput = false,
  decimalScale = 0,
  max,
  startAdornment,
  endAdornment,
  disabled,
  containerSx,
  onChange: handleChange,
  allowedNull = false,
  allowNegative = false,
  fixedDecimalScale = true,
  thousandSeparator = true,
  hideError = false,
  value,
  displayEmpty = false,
  focus,
  ...rest
}) => {
  const { data: currenciesData } = useGetCurrencies();
  const inputRef = useRef<any>(null);

  const { control, register, watch, setValue } = useFormContext();
  const currency = watch('currency');

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  useEffect(() => {
    handleSetDefaulCurrencyName();
  }, [currenciesData]);

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus]);

  const handleSetDefaulCurrencyName = () => {
    const selectedCurrency = currenciesData?.find((item) => item.id === currency);
    const defaultCurrency = currenciesData?.find((item) => item.id === CURRENCY_DEFAULT_VALUE);

    setValue('currencyName', selectedCurrency?.name || defaultCurrency?.name);
  };

  const handleCurrencyChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    const currency = currenciesData?.find((item) => item.id === value);

    setValue('currencyName', currency?.name || '');
    setValue('currency', value);
  };

  const getNumericValue = () => {
    const inputValue = value || inputProps.value;

    if (displayEmpty && allowedNull && inputValue === null) return '';
    if (!isNumber(Number(inputValue)) || inputValue === '' || inputValue === null)
      return inputValue;
    return roundNumber(Number(inputValue), decimalScale);
  };

  const renderAdorment = (adorment: ReactNode, position?: 'start' | 'end') => {
    if (!adorment) return <></>;
    return <InputAdornment position={position || 'start'}>{adorment}</InputAdornment>;
  };

  return (
    <Box sx={containerSx}>
      <Box className='flex justify-between items-center'>
        {!!label && (
          <Box className='flex items-center mb-1'>
            <Typography variant='body3'>{label}</Typography>
          </Box>
        )}
        {optional && (
          <Typography variant='body3' color='neutral.ne800'>
            {optional}
          </Typography>
        )}
      </Box>
      <FormControl fullWidth error={invalid} className={clsx({ 'form-error': invalid })}>
        <Grid container>
          {!hideCurrency && (
            <Grid item xs={hideInput ? 12 : 4}>
              <CustomSelect
                {...register('currency')}
                fullWidth
                sx={{
                  borderTopRightRadius: hideInput ? 12 : 0,
                  borderBottomRightRadius: hideInput ? 12 : 0,
                  '& fieldset': {
                    borderRight: hideInput ? 'initital' : 0,
                  },
                }}
                MenuProps={{
                  BackdropProps: {
                    style: {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
                inputProps={{ readOnly }}
                defaultValue={CURRENCY_DEFAULT_VALUE}
                value={currency}
                disabled={disabled}
                onChange={handleCurrencyChange}
              >
                {currenciesData?.map((item) => (
                  <CustomMenuItem key={item.id} value={item.id}>
                    {item.name}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Grid>
          )}
          {!hideInput && (
            <Grid item xs={hideCurrency ? 12 : 8}>
              <NumericFormat
                {...inputProps}
                {...rest}
                value={getNumericValue()}
                inputRef={(el: any) => {
                  ref(el);
                  inputRef.current = el;
                }}
                isAllowed={(values) => {
                  const { floatValue = 0 } = values;

                  return max ? floatValue <= max : true;
                }}
                name={name}
                fullWidth
                customInput={CustomTextField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderTopLeftRadius: hideCurrency ? 12 : 0,
                    borderBottomLeftRadius: hideCurrency ? 12 : 0,
                  },
                  '.MuiInputBase-adornedEnd.Mui-disabled': {
                    paddingRight: '14px',
                  },
                }}
                decimalScale={decimalScale}
                fixedDecimalScale={!!decimalScale && fixedDecimalScale}
                error={invalid}
                thousandSeparator={thousandSeparator}
                allowNegative={allowNegative}
                inputProps={{ readOnly }}
                disabled={disabled}
                InputProps={{
                  startAdornment: renderAdorment(startAdornment),
                  endAdornment: renderAdorment(endAdornment, 'end'),
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(value ? currencyStringToNumber(value) : allowedNull ? null : value);
                  handleChange && handleChange(e);
                }}
              />
            </Grid>
          )}
        </Grid>
        {invalid && !hideError && (
          <Box className='ml-2 mt-[2px] helper-text'>
            <CustomHelperText
              variant={invalid ? 'error' : 'default'}
              message={invalid ? (error as FieldError)?.message : defaultMessage}
            />
          </Box>
        )}
        <input {...register('currencyName')} hidden />
      </FormControl>
    </Box>
  );
};

export default FormCurrencyInput;
