/* eslint-disable autofix/no-unused-vars */
import {
  Box,
  FormControl,
  IconButton,
  SelectChangeEvent,
  SelectProps,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { ReactNode, useEffect, useRef } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { DisableIcon } from 'src/assets/icons/DisableIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { CustomTooltip, TooltipPlacement } from 'src/components/atoms/CustomTooltip';
import CustomHelperText from './CustomHelperText';
import { CustomMenuItem, CustomSelect } from './CustomSelect';

export type Value = string | number;
export interface IFormSelectOption {
  label: ReactNode;
  value: Value;
  tooltip?: string;
}
interface IFormSelectProps extends SelectProps {
  name: string;
  rules?: Record<string, unknown>;
  options: IFormSelectOption[];
  defaultMessage?: string;
  tooltip?: string;
  labelClass?: string;
  optional?: string;
  onDelete?: (option?: IFormSelectOption) => void;
  onSelectValue?: (value: Value) => void;
  overrideValue?: boolean;
  menuItemSx?: SxProps;
  hideErrorMessage?: boolean;
}

const FormSelect: React.FC<IFormSelectProps> = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  options,
  defaultMessage = '',
  placeholder = '',
  tooltip = '',
  labelClass,
  onDelete,
  optional,
  onSelectValue,
  overrideValue,
  menuItemSx = {},
  hideErrorMessage,
  ...rest
}) => {
  const selectRef = useRef<any>(null);

  const [selectWidth, setSelectWidth] = React.useState<string | number>('auto');

  const { control } = useFormContext();
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  useEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current?.node?.offsetWidth);

      window.addEventListener('resize', () => {
        setSelectWidth(selectRef.current?.node?.offsetWidth);
      });
    }

    return () => {
      window.removeEventListener('resize', () => {
        setSelectWidth(selectRef.current?.node?.offsetWidth);
      });
    };
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    onChange(value);
    onSelectValue?.(value as Value);
  };

  const renderValue = (value: unknown) => {
    const isShowValue =
      typeof value === 'number' ? value >= 0 : typeof value === 'boolean' || !isEmpty(value);

    const option = options.find((option) => option.value === value);
    return isShowValue ? (
      <Box className='flex items-center h-full'>
        <Typography
          component='span'
          color='text.primary'
          variant='body2'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {option?.label}
        </Typography>
        {typeof onDelete === 'function' && (
          <IconButton
            onMouseDown={(e) => {
              e.stopPropagation();
              onDelete?.(option);
            }}
          >
            <DisableIcon />
          </IconButton>
        )}
      </Box>
    ) : (
      <Typography component='span' color='neutral.ne500' variant='body2'>
        {placeholder}
      </Typography>
    );
  };

  const renderTooltip = (tooltip: string, placement?: TooltipPlacement) => {
    if (tooltip) {
      return (
        <CustomTooltip title={tooltip} placement={placement || 'right-end'}>
          <span className='cursor-pointer ml-[6px]'>
            <InfoIcon />
          </span>
        </CustomTooltip>
      );
    }
  };

  return (
    <Stack gap={0.75} className='w-full'>
      <Box className='flex justify-between items-center'>
        <Box className='flex items-center'>
          <Typography variant='body3' className={clsx('flex', labelClass)}>
            {label}
          </Typography>
          {renderTooltip(tooltip)}
        </Box>
        <Typography variant='body3' color='neutral.ne800'>
          {optional}
        </Typography>
      </Box>
      <FormControl fullWidth error={invalid}>
        <CustomSelect
          {...rest}
          {...inputProps}
          // sometime we would like to override the value, .i.e treat it as an array
          {...(overrideValue ? { value: rest.value } : {})}
          MenuProps={{
            BackdropProps: {
              style: {
                backgroundColor: 'transparent',
              },
            },
            PaperProps: {
              style: {
                width: selectWidth,
              },
            },
          }}
          variant='outlined'
          inputRef={(el) => {
            ref(el);
            selectRef.current = el;
          }}
          displayEmpty
          renderValue={renderValue}
          onChange={handleChange}
        >
          {options.map((option) => (
            <CustomMenuItem
              key={option.value}
              value={option.value}
              sx={{ wordBreak: 'break-all', whiteSpace: 'unset', ...menuItemSx }}
            >
              {option.label}
              {renderTooltip(option?.tooltip || '', 'right-start')}
            </CustomMenuItem>
          ))}
        </CustomSelect>
        {invalid && !hideErrorMessage && (
          <Box className='ml-2 mt-[2px]'>
            <CustomHelperText
              variant={invalid ? 'error' : 'default'}
              message={invalid ? (error as FieldError)?.message : defaultMessage}
            />
          </Box>
        )}
      </FormControl>
    </Stack>
  );
};

export default FormSelect;
