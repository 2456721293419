import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import { BANK_ACCOUNT_TYPE, bankAccountTypeOptions } from 'src/constants/applications';

interface IFinancialDetailsContentProps {
  keyName?: string;
  disabled?: boolean;
  action?: React.ReactNode;
}

const FinancialDetailsContent: FC<IFinancialDetailsContentProps> = ({
  keyName = '',
  disabled,
  action,
}) => {
  const form = useFormContext();
  const isAustralianBankAccount =
    form.watch(`${keyName}bankAccountType`) === BANK_ACCOUNT_TYPE.AUSTRALIAN;

  console.log('isAustralianBankAccount', isAustralianBankAccount);

  console.log('form.watch(`${keyName}bankAccountType`)', form.watch(`${keyName}bankAccountType`));

  return (
    <Box className='pt-4'>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className='flex items-center justify-between'>
            <LabelWithTooltip
              label={
                <Typography component='span' variant='body2' fontWeight={500}>
                  Bank Account Details
                </Typography>
              }
              tooltip='Provide bank account details for distribution and redemption payments. The bank account must be held in the same name as the Investment Entity'
              placement='right'
            />
            {action}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name={`${keyName}bankAccountType`}
            options={bankAccountTypeOptions}
            isEnumValue
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`${keyName}bankName`}
            label='Bank Name'
            placeholder='Enter bank name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`${keyName}accountName`}
            label='Account Name'
            placeholder='Enter account name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`${keyName}bankStateBranch`}
            label={isAustralianBankAccount ? 'Bank State Branch' : 'BSB/SWIFT/BIC/IBAN'}
            placeholder={
              isAustralianBankAccount ? 'Enter bank state branch' : 'Enter bsb/swift/bic/iban'
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`${keyName}accountNumber`}
            label='Account Number'
            placeholder='Enter account number'
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialDetailsContent;
