/* eslint-disable no-restricted-imports */
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { RadioOption } from 'src/components/atoms/FormRadioGroup';
import {
  APPLICATION_APPROVE_STEP,
  APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE,
  APPLICATION_SIGNATORY_STATUS,
  INVESTMENT_ENTITY_APPOINTER_TYPE,
  INVESTMENT_ENTITY_DELEGATE_ROLE,
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE,
  INVESTMENT_ENTITY_ROLE,
  INVESTMENT_ENTITY_SMSF_TRUSTTYPE,
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE,
  InvestmentEntityCompanyRegionType,
  RejectApplicationType,
  SUPER_FUND_STATUS,
  SUPER_FUND_TYPE,
} from 'src/constants/applications';
import { UnitClassInvestorType, UnitClassOfferType } from 'src/constants/unit-class';
import { BaseQuery } from 'src/modules/common/hooks';
import { IDocument } from 'src/modules/common/type';
import { IOfferPreview, IOfferUnitClass } from 'src/modules/offer/type';
import { InvestmentEntity, Status } from 'src/modules/users-management/type';
import { IUploadFile } from '../common/type';

export interface IUpsertInvestmentApplicationForm {
  isDuplicate?: boolean;
  isEditMode?: boolean;
  isViewMode?: boolean;
  isCreateMode?: boolean;
  isDisabledEdit?: boolean;
  isReviewing?: boolean;
  isDraft?: boolean;
  id?: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  step?: number;
  currentStep?: number;
  isExistingInvestmentEntity?: boolean;
  investmentEntityId?: string;
  verifyCode?: string;
  isInvestmentEntitySetting?: boolean;
  investmentEntityInfo?: IInvestmentEntityTypeInfo;
  isCreateFundApplicationForm?: boolean;
  isFundApplication?: boolean;
  hasQuestionnaire?: boolean;
}

export interface IInvestmentEntityTypeInfo {
  entityType?: number;
  trustType?: number;
  otherType?: number;
  companyRegionType?: number;
  trusteeType?: number;
  appointerType?: number;
}

export interface IApplication {
  id: string;
  fundName: string;
  offerName: string;
  unitClassName: string;
  status: number;
  statusName: string;
  investmentEntity: string;
  investmentAmount: number;
  progress: number;
  currency?: string;
  hasQuestionnaire: boolean;
}

export interface IClientApplication {
  id: string;
  applicationNumber: string;
  fund: string;
  unitClass: string;
  offer: string;
  investor: string;
  submittedDate?: string;
  status: number;
  statusName: string;
  investmentAmount: number;
  currency: string;
  offerId: string;
  fundId: string;
  unitClassId: string;
  isArchived: boolean;
  note: string;
  isFundApplication: boolean;
  rejectedDate: string;
  rejectedBy: string;
}

export interface ApplicationFilter {
  statuses: Status[];
  investmentEntities?: InvestmentEntity[];
  offers?: { id: string; name: string }[];
  unitClasses?: { id: string; name: string }[];
}

export interface ApplicationQueryBody extends BaseQuery {
  statuses?: number[];
  investmentEntities?: string[];
  unitClassIds?: string[];
  offerIds?: string[];
  isArchiveIncluded?: boolean;
}

export interface IApplicationDetails {
  isSaveDraft: boolean;
  fundId: string;
  offerId: string;
  unitClassId: string;
  isExistingInvestorInFund: boolean;
  investorNumber: string | null;
  investorType: number | null;
  investorId?: string | null;
  investmentAmount: number;
  isForeignTaxResident: boolean;
  isOptionalInvestorType: boolean;
  rejectedNote: string;
  rejectedType: RejectApplicationType;
  rejectedDate: string;
  rejectedById: string;
  rejectedBy: string;
  approvedSteps: [
    {
      step: APPLICATION_APPROVE_STEP;
      approvedDate: string;
      approvedById: string;
      approvedBy: string;
    },
  ];
  investmentEntityId?: string | null;
}
export interface IApplicationAcceptance {
  declaration: string;
  signatories: IAcceptanceSignatory[];
  isGenerateDepositLetter: boolean;
}

export interface IApplicationInvestorType {
  id: number;
  name: string;
}

export interface IInvestorOfferDetails extends IOfferPreview {
  fundId: string;
}

export interface IInvestorOfferUnitClass extends IOfferUnitClass {
  entityType: number;
}

export interface IInvestorOfferInfo {
  fundType: number;
  id: string;
  unitClasses: IOfferUnitClass[];
}

export interface IIndividualDetail {
  id?: string;
  refId?: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dob: string | null;
  contactNumber: string;
  countryFlagCode: string;
  position: string;
  residentialAddress: string;
  residentialManualEntry?: boolean;
  residentialCountry?: string;
  residentialState?: number;
  residentialSuburb: string;
  residentialPostcode: string;
  residentialStreetNumber: string;
  residentialStreetName: string;
  taxInfo: {
    isTaxResidentAustralia: null | boolean;
    tfnExemptionCode: string;
    isTaxResidentOtherCountry: null | boolean;
    country: string;
    hasTIN: null | boolean;
    tin: string;
    reasonType: number | null;
    isDeclarationChecked: boolean;
  };
  overallStatus?: number;
  overallStatusName?: string;
  verificationMethod?: number;
  verificationMethodName?: string;
  isKYCVerified?: boolean;
  populatedDirector?: boolean;
  uboSourceType?: number;
  kycVerifiedDate?: string;
  dataFound?: string;
  uboId?: string;
  verifiedIdDocuments?: IDocument[];
}

interface ICompany {
  companyName: string;
  countryOfEstablishment: string;
  countryOfTaxResident: string;
  registeredAddress: string;
  principalPlaceOfBusiness: string;
  nameOfStockExchangeName: string;
  listedTickerCode: string;
  directors: IIndividualDetail[];
  ubOs: IIndividualDetail[];
}

export interface IAustralianCompany extends ICompany {
  abn: string;
  acn: string;
  specialCompanyType: number | null;
  asxTickerCode: string;
  nameOfListedCompany: string;
  regulatorName: string;
  licenceName: string;
  isSharesHeldBehalfOfTrust: boolean | null;
  redFlagComment: string;
  certifiedAgreementFile?: ICertifiedFile;
  certifiedAgreementFileDescription?: string;
  certifiedAgreementFileId?: string;
  companyTypeSelect: number | null;
  SKYCApplicationId?: string;
}

export interface IForeignCompany extends ICompany {
  foreignEIN: string;
  nameOfRelevantForeignRegistrationBody: string;
  registeredWithASIC: boolean;
  isCompanyListed: boolean;
  arbn: string;
  companyFullNameASIC: string;
  companyType: string;
}

export interface ICertifiedFile extends IUploadFile {
  id?: string;
  uploadedDate: string;
  uploadedBy: string;
  uploadedById: string;
  description: string;
}

export interface ITrustee {
  trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE;
  trusteeCompanyRegionType: InvestmentEntityCompanyRegionType;
  numberOfIndividuals: number | null;
  trusteeIndividuals: IIndividualDetail[];
  trusteeAustralianCompanyDetails: IAustralianCompany;
  trusteeForeignCompanyDetails: IForeignCompany;
}

export interface IGovernmentBody {
  abn: string;
  nameOfGovernmentBody: string;
  principlePlaceOfOperation: string;
  typeString: string;
  type: number;
  otherType: string;
  govermentBodyStateName: string;
  govermentBodyStateId: string;
  countryId: string | null;
  countryName: string | null;
  publicOfficers: IIndividualDetail[];
  ubOs: IIndividualDetail[];
}

export interface IAssociation {
  abn: string;
  associationName: string;
  associationNumber: string;
  countryOfEstablishment: string;
  countryOfTaxResident: string;
  registeredOffice: string;
  typeString: string;
  type: number;
  otherType: string;
  certifiedAgreementFile: ICertifiedFile;
  certifiedAgreementFileId?: string;
  certifiedAgreementFileDescription?: string;
  publicOfficers: IIndividualDetail[];
  ubOs: IIndividualDetail[];
}

export interface IPartner extends IIndividualDetail {
  partnerType?: number | null;
  entityName?: string;
  entityType?: number | null;
  abn?: string;
  businessAddress?: string;
  beVerified?: boolean;
}

export interface IPartnership {
  abn: string;
  partnershipName: string;
  businessName: string;
  countryOfEstablishment: string;
  countryOfTaxResident: string;
  typeString: string;
  type: number | null;
  otherType: string;
  partnershipRegulated: boolean | null;
  principalPlaceOfBusiness: string;
  partners: IPartner[];
  ubOs: IIndividualDetail[];
  certifiedAgreementFileId?: string;
  certifiedAgreementFile?: ICertifiedFile;
  certifiedAgreementFileDescription?: string;
  certifiedPartnershipAgreementFileId?: string;
  certifiedPartnershipAgreementFile?: ICertifiedFile;
  certifiedPartnershipAgreementFileDescription?: string;
  partnerType?: number;
  nameOfAssociation: string;
  membershipDetails: string;
}
export interface ITrust<T> {
  trustType: T;
  name: string;
  abn: string;
  acn: string;
  countryOfEstablishment: string;
  countryOfTaxResident: string;
  trustTypeString: string;
  otherTrustType: string;
  registeredOffice: string;
}

interface IEditorDetails {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dob: dayjs.Dayjs | null;
  contactNumber: string;
  countryFlagCode: string;
  hasAuthorityToComplete: boolean | null;
  userType: INVESTMENT_ENTITY_ROLE;
  capacity: INVESTMENT_ENTITY_DELEGATE_ROLE | null;
  otherCapacity: string;
  companyName: string;
  licenseeName: string;
  afslNumber: string;
  position?: string;
  roleId?: string;
}

export interface IInvestmentEntityForm {
  isSaveDraft: boolean;
  entityType: INVESTMENT_ENTITY_TYPE;
  isAddNewEntityDetail: boolean;
  isEntityDetailKYCCompleted: boolean;
  companyRegionType: InvestmentEntityCompanyRegionType;
  trustType: INVESTMENT_ENTITY_TRUST;
  otherType: INVESTMENT_ENTITY_OTHER;
  editorDetails: IEditorDetails;
  investmentEntityDetails: {
    numberOfIndividuals: number | null;
    individuals?: IIndividualDetail[];
    allowEditAbn?: boolean;
    soleTrader?: {
      abn: string;
      businessName: string;
      principalPlaceOfBusiness: string;
    } & IIndividualDetail;
    regulatedTrust: {
      enterManually: boolean;
      arsn: string;
      trustUBOs: IIndividualDetail[];
    } & ITrust<INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE | null> &
      ITrustee;
    unregulatedTrust: {
      abn: string;
      acn: string;
      isSettlorMoreThanMax: boolean | null;
      settlor: IIndividualDetail;
      hasAppointer: boolean;
      appointerType: INVESTMENT_ENTITY_APPOINTER_TYPE | null;
      isAppointerSameAsTrustee: boolean;
      appointerCompanyRegionType: InvestmentEntityCompanyRegionType;
      appointerAustralianCompanyDetails: {
        isManualEntry?: boolean;
        skycApplicationId?: string;
      } & IAustralianCompany;
      appointerForeignCompanyDetails: IForeignCompany;
      selectedExistingIndividuals?: string[];
      appointerIndividuals: IIndividualDetail[];
      trustUBOs: IIndividualDetail[];
      certifiedTrustDeedId?: string;
      certifiedTrustDeed?: ICertifiedFile;
      certifiedTrustDeedDescription?: string;
    } & ITrust<INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE | null> &
      ITrustee;
    australianCompany: IAustralianCompany;
    foreignCompany: IForeignCompany;
    governmentBody: IGovernmentBody;
    association: IAssociation;
    smsf: {
      abn: string;
      acn: string;
      superFundStatus: string;
      certifiedAgreementFile?: ICertifiedFile;
      certifiedAgreementFileDescription?: string;
      certifiedAgreementFileId?: string;
      smsfubOs: IIndividualDetail[];
    } & ITrust<INVESTMENT_ENTITY_SMSF_TRUSTTYPE | null> &
      ITrustee;
    partnership: IPartnership;
    skycApplicationId?: string;
    isManualEntry?: boolean | null;
    isSKYCPopulating: boolean | null;
  };
  investmentEntityId?: string;
}

export interface IApplicationFinancialDetail {
  isSaveDraft: boolean;
  bankName?: string;
  accountName?: string;
  bankStateBranch?: number;
  accountNumber?: number;
  bankAccountType?: number;
}

export interface IExecutionMethod {
  id: number;
  name: string;
}

export interface IApplicationOfferUnitClassDetail {
  allowDRP: boolean;
  allowForeignInvestors: boolean;
  currency: string;
  offerType: number;
  minimumInvestmentAmount: number;
  acceptInvestmentAmountUnderMinimum: boolean;
  hardFloor: number;
  minimumSubsequentInvestmentAmount: number;
  allowCapitalCall: boolean;
  allowPartiallyPaidUnits: boolean;
  executionMethods: IExecutionMethod[];
}

export interface IApplicationQuestionnaireDetails {
  signedByName: string;
  signedTime: string;
  items: {
    id: string;
    order: number;
    question: string;
    type: number;
    yesNoPass?: boolean;
    answers: {
      id: string;
      selected: boolean;
      answer: string;
    }[];
  }[];
}

export interface IApplicationById {
  detail: IApplicationDetails;
  investmentEntity: IInvestmentEntityForm;
  acceptance: IApplicationAcceptance;
  additional: IApplicationAddtional;
  fundId: string;
  unitClassId: string;
  offerId: string;
  team: ITeamContact[];
  financialDetails: IApplicationFinancialDetail;
  financialTax: IFinancialTaxDetailsForm;
  investmentEntityId?: string;
  trusteeType?: number;
  trustType?: number;
  otherType?: number;
  entityType?: number;
  verifyCode?: string;
  isAddNewEntityDetail: boolean;
  isFundApplication?: boolean;
  questionnaire: IApplicationQuestionnaireDetails;
}
export interface ITeamContact {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber?: string;
  countryFlagCode?: string;
  id?: string;
  roleId?: string | null;
  roleName?: string | null;
  userType?: number;
  teamTypes?: number[];
  isCurrentUser?: boolean;
  isNew?: boolean;
  isInvited?: boolean;
  notUpdate?: boolean;
  isAddFromTeam?: boolean;
  isAddTo?: boolean;
  entityStatus?: number;
  userStatus?: number;
}

export interface ITeam {
  isSaveDraft?: boolean;
  teammates: ITeamContact[];
  invitedTeamIds: string[];
}

export interface ITeamError {
  isPrimaryContactError: boolean;
  isTeamError?: boolean;
  isCommunicationListError: boolean;
  errorMessageFromServer?: string;
}
export interface ApplicationOfferQueryBody extends BaseQuery {
  ListType?: number;
}

export interface IApplicationOffer {
  id: string;
  name: string;
  fundName: string;
  expirationDate: string;
  description: string;
  overview: string;
  thumbnail: string;
  logo: string;
  isOpenEnded: boolean;
  isFavourite: boolean;
  isInvitation: boolean;
  type: number;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export interface IAcceptanceForm {
  isSaveDraft: boolean;
  validateCase1?: boolean;
  validateCase2?: boolean;
  signatories: IAcceptanceSignatory[];
}

export interface IFundKYCAMLForm {
  comment: string;
  kycSubmitType: number | null;
  isSaveDraft?: boolean;
}

export interface IAcceptanceSignatory {
  id?: string;
  contactId: string;
  method: number;
  email: string;
  isSendMail: boolean;
  signature: string;
  firstName?: string;
  lastName?: string;
  isCurrentUser?: boolean;
  isSelected?: boolean;
  status?: APPLICATION_SIGNATORY_STATUS;
  statusName?: string;
  signedDate?: string;
  markSignedBy?: string;
  markSignedDate?: string;
  receivedType?: APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE;
  receivedTypeName?: string;
  notRemoved?: boolean;
  signatoryFile?: ISignatoryFile;
}
export interface ISignatoryFile {
  fileName: string;
  path: string;
  fileSize: number;
  fileType: number;
}
export interface IAddtionalDocument extends IDocument {
  isAddNew?: boolean;
  isExisting?: boolean;
  documentType: number;
}

export interface IApplicationAddtional {
  isSaveDraft: boolean;
  sourceOfFundsType: number | null;
  distributionInstructionType: number;
  otherDescription?: string;
  documents: IAddtionalDocument[];
  existingDocuments: IAddtionalDocument[];
  fundApplicationDocuments: IAddtionalDocument[];
  description?: string;
  verifiedIdDocuments: IAddtionalDocument[];
}
export interface SearchSkycQueryBody {
  search?: string;
  entityType?: number;
  companyRegionType?: number;
}

export interface SearchSuperFundQueryBody {
  search?: string;
}

export interface ISkyc {
  matchRank: number;
  entityName: string;
  entityType: string;
  bn: string;
  cn: string;
  searchId: string;
  status: {
    type: string;
    value: string;
  };
}

export interface ISuperFund {
  abn: {
    value: string;
  };
  fundName: {
    name: string;
  };
}

export interface ISuperFundDetail {
  fundType: {
    code: keyof typeof SUPER_FUND_TYPE;
  };
  complyingStatus: {
    description: keyof typeof SUPER_FUND_STATUS;
  };
  identifier: {
    value: string;
    identifierStatus: {
      description: string;
    };
  };
  organisationName: {
    name: string;
  };
}

export interface ISkycCreateAppBody {
  bn: string;
  cn: string;
  searchId?: string;
  id: string;
}

export interface IFlagsToInvestigate {
  colour: string | null;
  name?: string | null;
  flagDetail: string | null;
  descriptor: string | null;
  message?: string | null;
}

export interface ISkycGetApp {
  data: {
    businessOverview: {
      countryOfEstablishment: string;
      typeOfCompany: string;
      registeredOffice: {
        unformattedAddress: string;
      };
      principalPlaceOfBusiness: {
        unformattedAddress: string;
      };
    };
    flagsToInvestigate: IFlagsToInvestigate[];
    interestedPersons: {
      firstName: string;
      middleName: string;
      lastName: string;
      roles: string;
      dob: string;
      address: {
        unformattedAddress: string;
      };
      pepAndSanctions: string;
      statusMediaCheck: string;
    }[];
    entitiesDetail: {
      sources: {
        extractDate: string;
        source: string;
        status: string;
      }[];
    }[];
    weblinks?: {
      report?: string;
    };
  };
}
export interface ISignatoriesMethod extends RadioOption {
  icon: ReactNode;
  caption: string;
  bgColor: string;
  color: string;
}
export interface IVerifyIndividualItem {
  individual?: IIndividualDetail;
  individualId: string;
  appId: string;
  refetch?: () => void;
  code?: string;
  disabled?: boolean;
  isVerified?: boolean;
  isRefetchingKYCInfo?: boolean;
  uboId?: string;
  verifiedIdDocuments?: IDocument[];
}

export enum IKYCMethod {
  DOCUMENT_ID,
  BIOMETRIC,
  CONNECTION_ID,
}

export enum IOverallStatus {
  NOT_REQUIRED,
  REQUIRED,
  PENDING,
  VERIFIED,
}

export interface IInvestmentEntitySelection {
  id: string;
  name: string;
  userType: number;
  isSelected?: boolean;
}

export interface IInvestorCompanyFlagDetailConfig {
  [x: string]: {
    allowedPhrases?: string[];
    replaceMessage?: string;
    isAllowAll?: boolean;
    notAllowedPhrases?: string[];
  };
}

export interface IFinancialTaxDetailsForm {
  isSaveDraft: boolean | null;
  isTaxResidentOtherCountryThanAustralia: boolean | null;
  taxFileNumber: string;
  directorNameMakeDeclaration: string;
  isAllowDeclarationTermOfUse: boolean | null;
  taxStatus?: {
    taxStatusType: number | null;
    hasGIIN: boolean | null;
    giin: string;
    reasonNotHavingGIIN: number | null;
    isAllowCRSAndManagedByAnotherFI: boolean | null;
    fatcaStatus: string;
    otherDescription?: string;
  };
  foreignControllingPersons?: {
    hasControllingPersonsOtherThanAustralia?: boolean | null;
    directors?: IDirector[];
    directorIds: string[];
  };
  entityCountryOfTaxResidency?: {
    hasEntityTaxOtherThanAustralia: boolean | null;
    hasTIN: boolean | null;
    tin: string;
    reasonNotHavingTIN: number | null;
    countryOfTax: string;
    countryHasTIN: boolean | null;
    countryTIN: string;
    countryReasonNotHavingTIN: number | null;
  };
  declarationDirectorId: string | null;
  isRequiredDeclarationOnly?: boolean;
  financialDetails?: IApplicationFinancialDetail;
}
export interface IDirector {
  id: string;
  fullName: string;
  position?: string;
}

export interface ITaxNoGIINReason {
  id: string;
  name: string;
}

export interface ITaxNoTINReasons extends ITaxNoGIINReason {}

export interface ITaxFilter {
  taxNoGIINReasons: ITaxNoGIINReason[];
  taxNoTINReasons: ITaxNoTINReasons[];
  directors: IDirector[];
  foreignControllingPersons: IDirector[];
  allowForeignInvestors: boolean;
  isForeignTaxResident: boolean;
  isAnyIndividualContacts: boolean;
  isTaxResidentOtherThanAustralia: boolean;
  individualTaxPersons: string[];
}

export interface IAmountReceived {
  id?: string;
  receivedDate: string;
  receivedAmount: number;
}
export interface IFinaliseDetail {
  investorNumber: string;
  firstInvestorNumber: boolean;
  offerType?: UnitClassOfferType;
  investorType?: UnitClassInvestorType;
  investmentAmount: number;
  amountPaid: number;
  amountUnpaid: number;
  currentUnitPrice: number;
  capitalCall: boolean;
  allowCapitalCall: boolean;
  allowPartiallyPaidUnits: boolean;
  capitalCallAmount: string;
  capitalCallPercent: string;
  transactionDate: string;
  investorNumberCode: string;
  isOverwriteInvestorNumber: boolean;
  unitPrice: number;
  updateUnitPrice: number;
  isCurrentUnitPrice: boolean;
  numberOfUnits: number;
  partiallyPaidUnits: boolean;
  isSendToCommunicationList: boolean;
  investorUnit?: number;
  unitPriceUnit?: number;
  investorNumberPrefix?: string;
  unitPriceRounding?: number;
  transactionDateLocal?: string;
  hasFinaliseTransaction?: boolean;
  batchId?: string;
  finalisedByName?: string;
  finalisedDate?: string;
}
export interface IKycReview {
  investorDetailsVerification: [
    {
      ref: string;
      questionType: number;
      question: string;
      yesNo: boolean;
      comment: string;
      date: string;
      singleSelect: string;
    },
  ];
  ubOsVerification: [
    {
      ref: string;
      questionType: number;
      question: string;
      yesNo: boolean;
      comment: string;
      date: string;
      singleSelect: string;
    },
  ];
  wholesaleInvestorStatus: [
    {
      ref: string;
      questionType: number;
      question: string;
      yesNo: boolean;
      comment: string;
      date: string;
      singleSelect: string;
    },
  ];
  additionalComments: {
    ref: string;
    questionType: number;
    question: string;
    yesNo: boolean;
    comment: string;
    date: string;
    singleSelect: string;
  };
  offerType: number;
  offerTypeName: string;
  investmentAmount: number;
  approvedBy: string;
  approvedByName: string;
  approvedDate: string;
  requestMoreInfoNoted: string;
  requestMoreInfoNotedBy: string;
  requestMoreInfoNotedByName: string;
  requestMoreInfoNotedDate: string;
  externalCompletedBy: string;
  externalCompletedByName: string;
  externalCompletedDate: string;
  externaCompletedComment: string;
  kycId: string;
  caseId: string;
  caseTeamMember: string;
  caseTeamMemberId: string;
  statusName?: string;
  isKYCCompletedExternally: boolean;
  investorType: UnitClassInvestorType;
}

export interface IApplicationOverview {
  id: string;
  applicationNumber: string;
  entityName: string;
  status: number;
  statusName: string;
  investmentAmount: number;
  summary?: {
    id: APPLICATION_APPROVE_STEP;
    name: string;
    approvedBy: string;
    approvedDate: string;
  }[];
  investmentAmountReceived?: IAmountReceived[];
  investmentAmountCapitalCall?: {
    capitalCall: boolean;
    allowCapitalCall: boolean;
    totalInvestmentAmountReceived: number;
    capitalCallAmount: number;
    percentage: number;
    fixedAmount: number;
    investmentAmount: number;
  };
  finaliseDetail?: IFinaliseDetail;
  finaliseApproval?: IFinaliseDetail;
  documents?: IDocument[];
  kycReview?: IKycReview;
  offerId: string;
  fundId: string;
  unitClassId: string;
}
export interface ICompleteConnectId {
  appId: string;
  individualId: string;
  code?: string;
  body: {
    name: string;
    givenName: string;
    middleName: string;
    familyName: string;
    email: string;
    birthdate: string;
    phoneNumber: string;
    address: {
      country: string;
      locality: string;
      postalCode: number;
      region: string;
      streetAddress: string;
    };
    transactionId: string;
    bankName?: string;
    issuer: string;
  };
}
export enum ELetterType {
  deposit,
  application,
  applicationForm,
}

export interface IDuplicatedEntity {
  firstName: string;
  lastName: string;
  entityName: string;
  entityId?: string;
  editorDetails: IEditorDetails;
}

export interface ISubmitApplicationQuestionnaireBody {
  fundId: string;
  offerId: string;
  unitClassId: string;
  questionnaires: {
    id: string;
    answers: {
      id: string | null;
      yesNoAnswer?: boolean | null;
      answer?: string;
    }[];
  }[];
}

export interface IApplicationQuestionnaireAnswer {
  order: number;
  answer: string;
  pass: boolean;
  id: string;
}

export interface IApplicationQuestionnaire {
  order: number;
  type: number;
  question: string;
  yesNoPass: boolean;
  maximumFreeTextLength: number;
  id: string;
  answers: IApplicationQuestionnaireAnswer[];
}

export interface IApplicationQuestionnaireResponse {
  errorMessage: string;
  questionnaireDetails: IApplicationQuestionnaire[];
}

export interface IApplicationQuestionnaireForm {
  questionnaires: {
    id: string;
    type: number;
    question: string;
    maximumFreeTextLength: number | null;
    answers: {
      id: string | null;
      yesNoAnswer: boolean | null;
      answer?: string;
    }[];
  }[];
  isConfirmed: boolean;
}

export interface IVerifyForeignDocumentBody {
  uboId: string;
  verifiedIdDocument: {
    fileName: string;
    path: string;
    fileSize: 0;
    fileType: 0;
    modifiedById: string;
  };
}
