export const ROUTES_PATH = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  FACTOR_AUTHENTICATION: '/factor-authentication',
  USER_MANAGEMENT: '/user-management',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CLIENT_MANAGEMENT: '/client-management',
  ACCOUNT_COMPLETION: '/account-completion',
  CLIENT_DETAIL: '/client-management/:clientId',
  KYC_BACK_OFFICE: '/kyc-back-office',
  COMMUNICATIONS: '/communications',
  AUDIT_TRAIL: '/audit-trail',
  ROLE_MANAGEMENT: '/role-management',
  ROLE_LIST: '/role-list',
  APPLICATIONS: '/applications',
  ADMINISTRATION_INVESTORS: '/administration/investors',
  ADMINISTRATION_INVESTMENT_ENTITY: '/administration/investment-entity',
  FUNDS: '/administration/funds',
  TRANSACTIONS: '/administration/transactions',
  EDIT_TRANSACTION: '/administration/transactions/edit-transaction',
  VIEW_TRANSACTION: '/administration/transactions/view-transaction',
  REPORTS: '/administration/reports',
  DOCUMENTS: '/documents',
  ORGINISATION_SETTINGS: '/organisation-settings',
  INVESTOR_FORMS: '/investor-forms',
  ACCOUNT_DETAILS: '/accounts-details',
  INVESTMENT_ENTITY: '/investment-entity',
  UNALLOCATED_HOLDING: '/unallocated-holding',
  SIGN_UP_INVESTOR: '/signup-investor',
  EMAIL_VERIFICATION_INVESTOR: '/email-verification-investor',
  IMPERSONATE: '/impersonate',
  PROFILE: '/profile',
  FORBIDDEN: '/403',
  INVESTOR_OFFER_DETAILS: '/applications/offer-details',
  KYC_BACK_OFFICE_DETAIL: '/kyc-back-office/case-details',
  FUNDRAISING_CAPITAL_CALLS: '/fundraising/capital-calls',
  FUNDRAISING_OFFERS: '/fundraising/offers',
  FUNDRAISING_KYC_BACK_OFFICE: '/fundraising/kyc-back-office',
  FUNDRAISING_KYC_BACK_OFFICE_DETAIL: '/fundraising/kyc-back-office/case-details',
  FUNDRAISING_APPLICATIONS: '/fundraising/applications',
  REQUEST_VERIFY: '/request-verify',
  BULK_UPLOAD: '/administration/investors/bulk-upload',
  BILLING: '/billing',
  FUND_REGISTRY: '/registry/fund-registry',
};
