/* eslint-disable autofix/no-unused-vars */
import { Pagination, Stack, styled } from '@mui/material';
import React, { FC } from 'react';

interface CustomPaginationProps {
  count: number;
  defaultPage?: number;
  page: number;
  siblingCount?: number;
  boundaryCount?: number;
  onChange: (value: number) => void;
  size?: 'small' | 'medium' | 'large';
}

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiButtonBase-root.Mui-selected': {
    color: theme.palette.primary.pr900,
    backgroundColor: theme.palette.primary.pr50,
    borderRadius: '6px',
  },

  '& .Mui-selected:hover': {
    backgroundColor: theme.palette.neutral.ne300,
  },

  '& .MuiButtonBase-root': {
    borderRadius: '6px',
    color: theme.palette.neutral.ne500,
    fontSize: '14px',
  },
}));

const CustomPagination: FC<CustomPaginationProps> = ({
  count,
  page,
  siblingCount = 1,
  boundaryCount = 1,
  onChange,
  size,
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChange(value - 1);
  };

  return (
    <Stack spacing={1}>
      <StyledPagination
        count={count}
        page={page + 1}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        onChange={handleChange}
        size={size}
      />
    </Stack>
  );
};

export default CustomPagination;
