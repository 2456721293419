import dayjs from 'dayjs';
import { isEqual, isNumber } from 'lodash';
import {
  InvestorTransactionType,
  transactionDefaultValue,
} from 'src/constants/administration-transactions';
import {
  ICreateNewTransactionBody,
  ICreateNewTransactionForm,
  IPartnershipPreviewBeforeProcessItem,
  IPreviewBeforeProcessItem,
  ITransactionFilterCreationResponse,
  ITransactionInvestorDetails,
  ITransactionIssueDetails,
} from 'src/modules/administration-transactions/types';
import { getEndOfDateUTC, utcToLocalTimezone } from './time';

export const formatTransactionID = (id?: string) => {
  if (!id) return '';
  return id.slice(0, 9);
};

export const getCreateTransactionBodyByType = (
  data: ICreateNewTransactionForm,
  investorDetails?: ITransactionInvestorDetails,
) => {
  const {
    saveDraft,
    type,
    investor,
    comment,
    investmentAmount,
    paidAmount,
    clientId,
    fundId,
    unitClassId,
    partialRedemptionType,
    redemptionApprovalReceived,
    investorTransactionRedemptionType,
    unitClassIds,
    isPartnership,
    transactionDate,
    transferMethod,
    transferUnits,
    investorTo,
    considerationAmount,
    isSendConfirmationLetter,
    transferOwnership,
    includeFundsHeldInReserve,
    transferFundsHeldInReserve,
    ...rest
  } = data;

  const body: ICreateNewTransactionBody = {
    saveDraft,
    type,
    investorId: (investor?.value as string) || null,
    clientId,
    fundId,
    unitClassId,
  };

  switch (type) {
    case InvestorTransactionType.Issue:
      body.transactionIssue = {
        investmentAmount: isNumber(investmentAmount) ? investmentAmount : null,
        paidAmount: isNumber(paidAmount) ? paidAmount : null,
        comment,
      };
      break;
    case InvestorTransactionType.Redemption:
      body.transactionRedemption = {
        comment,
        type: partialRedemptionType,
        ...rest,
        redemptionAmount: isNumber(rest.redemptionAmount) ? rest.redemptionAmount : null,
        redemptionUnits: isNumber(rest.redemptionUnits) ? rest.redemptionUnits : null,
        redemptionOwnership: isNumber(rest.redemptionOwnership) ? rest.redemptionOwnership : null,
      };
      body.redemptionApprovalReceived = redemptionApprovalReceived;
      body.investorTransactionRedemptionType = investorTransactionRedemptionType;

      if (isPartnership) {
        body.unitClassId = investorDetails?.unitClassId || null;
        body.unitClassIds = unitClassIds;
      }
      break;
    case InvestorTransactionType.Transfer:
      body.transactionTransfer = {
        transactionDate: transactionDate ? getEndOfDateUTC(transactionDate) : null,
        transactionDateLocal: transactionDate
          ? dayjs(transactionDate).endOf('date').format()
          : null,
        toInvestorId: investorTo?.value || null,
        transferMethod: Number.isInteger(transferMethod) ? (transferMethod as number) : null,
        transferUnits: isNumber(transferUnits) ? transferUnits : null,
        considerationAmount: isNumber(considerationAmount) ? considerationAmount : null,
        isSendConfirmationLetter: isSendConfirmationLetter || false,
        comment: comment || '',
        transferOwnership: isNumber(transferOwnership) ? transferOwnership : null,
        includeFundsHeldInReserve: includeFundsHeldInReserve || false,
        transferFundsHeldInReserve: isNumber(transferFundsHeldInReserve)
          ? transferFundsHeldInReserve
          : null,
      };

      if (isPartnership) {
        body.unitClassId = null;
        body.unitClassIds = unitClassIds;
      }
  }

  return body;
};

export const checkIsDifferenceInvestorInfoWithApplications = (
  item: IPreviewBeforeProcessItem | IPartnershipPreviewBeforeProcessItem,
) => {
  const applicationsInfo = (item?.applicationsInfo || [])?.map((applicationInfo) => ({
    investorTypeName: applicationInfo.investorTypeName,
    sourceOfFundsTypeName: applicationInfo.sourceOfFundsTypeName,
    distributionInstructionTypeName: applicationInfo.distributionInstructionTypeName,
    sourceOfFundsTypeOther: applicationInfo.sourceOfFundsTypeOther,
  }));
  const investorInfo = {
    investorTypeName: item?.investorInfo?.investorTypeName,
    sourceOfFundsTypeName: item?.investorInfo?.sourceOfFundsTypeName,
    distributionInstructionTypeName: item?.investorInfo?.distributionInstructionTypeName,
    sourceOfFundsTypeOther: item?.investorInfo?.sourceOfFundsTypeOther,
  };

  const isDifferenceInvestorInfo = applicationsInfo?.some((info) => !isEqual(info, investorInfo));

  return isDifferenceInvestorInfo;
};

export const generateFormDataToFillForm = (transactionDetails: ITransactionIssueDetails) => {
  const { transactionRedemption, transactionTransfer } = transactionDetails || {};
  const formData: ICreateNewTransactionForm = {
    clientId: transactionDetails.clientId,
    fundId: transactionDetails.fundId,
    unitClassId: transactionDetails.unitClassId,
    saveDraft: false,
    type: transactionDetails.type,
    investor: transactionDetails?.investorId
      ? {
          id: transactionDetails.investorId,
          label: `${transactionDetails.investorNumber}  -  ${transactionDetails.investorName}`,
          value: transactionDetails.investorId,
        }
      : transactionDefaultValue.investor,
    investmentAmount: transactionDetails.investmentAmount,
    paidAmount: transactionDetails.paidAmount,
    comment: transactionDetails.comment,
    // Redemption
    option: Number.isInteger(transactionRedemption?.option) ? transactionRedemption?.option : null,
    partialRedemptionType: Number.isInteger(transactionRedemption?.type)
      ? transactionRedemption?.type
      : null,
    redemptionAmount: transactionRedemption?.redemptionAmount,
    redemptionUnits: transactionRedemption?.redemptionUnits,
    redemptionOwnership: transactionRedemption?.redemptionOwnership,
    redemptionApprovalReceived: transactionDetails?.redemptionApprovalReceived,
    investorTransactionRedemptionType: Number.isInteger(
      transactionDetails?.investorTransactionRedemptionType,
    )
      ? transactionDetails?.investorTransactionRedemptionType
      : null,
    unitClassIds: transactionDetails?.unitClassIds,
    //Transfer
    transactionDate: transactionDetails?.transactionDate
      ? utcToLocalTimezone(transactionDetails?.transactionDate, '')
      : null,
    transferMethod: Number.isInteger(transactionTransfer?.transferMethod)
      ? transactionTransfer?.transferMethod
      : null,
    transferUnits: transactionTransfer?.transferUnits,
    considerationAmount: transactionTransfer?.considerationAmount,
    isSendConfirmationLetter: transactionDetails?.isSendConfirmationLetter,
    investorTo: transactionTransfer?.toInvestor?.id
      ? {
          id: transactionTransfer?.toInvestor?.id,
          label: `${transactionTransfer?.toInvestor?.investorNumber} - ${transactionTransfer?.toInvestor?.name}`,
          value: transactionTransfer?.toInvestor?.id,
        }
      : transactionDefaultValue.investor,
    ...(transactionDetails?.type === InvestorTransactionType.Transfer
      ? {
          investor: transactionTransfer?.fromInvestor?.id
            ? {
                id: transactionTransfer?.fromInvestor?.id,
                label: `${transactionTransfer?.fromInvestor?.investorNumber} - ${transactionTransfer?.fromInvestor?.name}`,
                value: transactionTransfer?.fromInvestor?.id,
              }
            : transactionDefaultValue.investor,
        }
      : {}),
    units: transactionTransfer?.fromInvestor?.units,
    ownership: transactionTransfer?.fromInvestor?.ownership,
    fundsHeldInReserve: transactionTransfer?.fromInvestor?.fundsHeldInReserve,
    transferOwnership: transactionTransfer?.transferOwnership,
    includeFundsHeldInReserve: transactionTransfer?.includeFundsHeldInReserve,
    transferFundsHeldInReserve: transactionTransfer?.transferFundsHeldInReserve,
  };

  console.log('formData', formData);

  return formData;
};

export const validateDataBeforeSubmit = (
  data: ICreateNewTransactionForm,
  filterCreation?: ITransactionFilterCreationResponse,
) => {
  let isValid = true;
  let errorMessage = '';
  const transactionType = data.type;

  if (data.saveDraft) {
    return {
      isValid,
      errorMessage,
    };
  }

  switch (transactionType) {
    case InvestorTransactionType.Transfer:
      if (filterCreation?.investorDetail?.latestTransactionDate) {
        isValid = dayjs(data.transactionDate)
          ?.endOf('date')
          ?.utc()
          .isAfter(
            dayjs(filterCreation?.investorDetail?.latestTransactionDate || '')
              .endOf('date')
              .utc(),
          );

        if (!isValid) {
          errorMessage = `The selected Transfer Date is earlier than the latest transaction date for ${
            filterCreation?.investorDetail?.name || ''
          }. Please review the Transfer Date and investor transactions before processing.`;
        }
      }
      break;
    default:
      break;
  }

  return {
    isValid,
    errorMessage,
  };
};
