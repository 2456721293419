import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { isNumber } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import InfoOutlinedIcon from 'src/assets/icons/InfoOutlinedIcon';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import FormInput from 'src/components/atoms/FormInput';
import ScrollTableRowsBaseContainer from 'src/components/atoms/ScrollTableRowsBaseContainer';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import CreationFieldsWrapper from 'src/components/pages/administration-transactions/create-new-transaction/shared/CreationFieldsWrapper';
import { administrationInvestorStatuses } from 'src/constants/administration-investor';
import {
  useCeaseInvestor,
  useGetPreviewOwnership,
  useUnceaseInvestor,
} from 'src/modules/administration-investor/hooks';
import { IInvestor } from 'src/modules/administration-investor/types';
import { IPreviewOwnershipItem } from 'src/modules/investment-entity/types';
import { formatCurrencyNumber, handleErrorFromServer } from 'src/utils/common';
interface ICeasePartnershipProps {
  investor?: IInvestor;
  onClose: () => void;
}
const CeasePartnership: React.FunctionComponent<ICeasePartnershipProps> = ({
  investor,
  onClose,
}) => {
  const isActiveAction = investor?.statusName === administrationInvestorStatuses.Ceased;
  const form = useForm<{ reason: string }>({
    defaultValues: {
      reason: '',
    },
  });
  const theme = useTheme();

  const { data, isLoading: gettingPreviewOwnership } = useGetPreviewOwnership(
    investor?.id || '',
    investor?.fundId || '',
  );
  const { mutate: ceaseInvestor, isLoading: ceaseInvestorLoading } = useCeaseInvestor();
  const { mutate: unceaseInvestor, isLoading: unCeasingInvestor } = useUnceaseInvestor();

  const handleSubmit = () => {
    if (isActiveAction) {
      unceaseInvestor(investor.id, {
        onError: handleErrorFromServer,
        onSuccess: onClose,
      });
      return;
    }
    ceaseInvestor(
      { id: investor?.id || '', reason: form.getValues('reason') },
      {
        onSuccess: onClose,
        onError: handleErrorFromServer,
      },
    );
  };

  const columns: ColumnProps<IPreviewOwnershipItem>[] = [
    {
      title: 'Financial Rule',
      key: 'unitClassName',
      sorter: true,
      sortBy: 'unitClassName',
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row, index) => <StringNodeTable value={row?.unitClassName} />,
    },
    {
      title: 'Investor Number',
      key: 'investorNumber',
      sorter: true,
      sortBy: 'investorNumber',
      sx: { minWidth: 160, width: '20%' },
      renderNode: (row, index) => <StringNodeTable value={row?.investorNumber} />,
    },
    {
      title: 'Investor Name',
      key: 'investorName',
      sortBy: 'investorName',
      sorter: true,
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.investorName} />,
    },
    {
      title: 'Ownership % (before)',
      key: 'currentOwnership',
      sortBy: 'currentOwnership',
      sorter: true,
      sx: { minWidth: 190, width: '20%' },
      renderNode: (row) => (
        <StringNodeTable
          value={
            isNumber(row?.currentOwnership)
              ? `${formatCurrencyNumber(row?.currentOwnership, 2)}%`
              : ''
          }
        />
      ),
    },
    {
      title: 'Ownership % (after)',
      key: 'afterOwnership',
      sortBy: 'afterOwnership',
      sorter: true,
      sx: { minWidth: 190, width: '20%' },
      renderNode: (row) => (
        <StringNodeTable
          value={
            isNumber(row?.afterOwnership) ? `${formatCurrencyNumber(row?.afterOwnership, 2)}%` : ''
          }
        />
      ),
    },
  ];

  return (
    <Box className='cease-investor flex flex-col h-full'>
      <Box className='relative py-9 border-b' borderColor='neutral.ne400'>
        <Typography variant='h5' fontWeight={600} align='center'>
          Cease Investor
        </Typography>
        <IconButton
          className='absolute top-1/2 -translate-y-1/2 right-19'
          sx={{ p: 0 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className='py-10 px-20 flex flex-col flex-1 overflow-auto'>
        <CustomAlert
          className='mb-6'
          severity='warning'
          customColor={theme.palette.warning.dark}
          customIcon={<InfoOutlinedIcon color={theme.palette.warning.dark} />}
        >
          <span className='block'>
            {isActiveAction
              ? 'Ownership % will be recalculated for all investors in the fund. Are you sure you want to activate this investor?'
              : `Ownership % will be recalculated for all investors in the fund. Are you sure you want to
            cease this investor?`}
          </span>
          <span className='block'>This action cannot be undone.</span>
        </CustomAlert>
        <CreationFieldsWrapper
          title={
            (
              <Typography variant='subtitle4' color={'primary.main'}>
                Ownership Details
              </Typography>
            ) as any
          }
          isValid={isActiveAction || !!form.watch('reason').trim()}
        >
          <ScrollTableRowsBaseContainer maxRows={10} records={data || []}>
            <CustomTable
              columns={columns}
              rows={
                // Sort the data array to prioritize the current investor's record
                // If data exists, create a sorted copy where the current investor appears first
                // If data doesn't exist, return an empty array
                data
                  ? [...data].sort((a, b) =>
                      a.id === investor?.id ? -1 : b.id === investor?.id ? 1 : 0,
                    )
                  : []
              }
              isFetchingData={gettingPreviewOwnership}
              sx={{
                borderColor: 'neutral.ne200',
                bgcolor: 'white',
              }}
              displayEmpty
              hasPagination={false}
            />
            {!!data?.length && (
              <Typography className='block mt-5' variant='body3'>
                {`Showing 1-${data?.length} of ${data?.length} results`}
              </Typography>
            )}
          </ScrollTableRowsBaseContainer>
          {!isActiveAction && (
            <FormProvider {...form}>
              <FormInput
                containerSx={{
                  mt: 3,
                }}
                name='reason'
                label='Reason'
                placeholder='Enter reason'
                multiline
                rows={3}
                isShowCharactersLeft
                maxLength={280}
              />
            </FormProvider>
          )}
        </CreationFieldsWrapper>
      </Box>
      <Box
        className='footer py-6 border-t sticky bottom-0 flex justify-end gap-4 px-20 bg-white shadow-md'
        borderColor={'neutral.ne200'}
      >
        <CustomButton
          variant='text'
          onClick={onClose}
          disabled={ceaseInvestorLoading || unCeasingInvestor}
        >
          Cancel
        </CustomButton>
        <CustomButton
          type='submit'
          color={isActiveAction ? 'primary' : 'error'}
          disabled={isActiveAction ? false : !form.watch('reason')?.trim()}
          isLoading={ceaseInvestorLoading || unCeasingInvestor}
          onClick={handleSubmit}
        >
          {isActiveAction ? 'Activate' : 'Cease'}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CeasePartnership;
