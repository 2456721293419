/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { SelectChangeEvent, SelectInputProps } from '@mui/material/Select/SelectInput';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import DismissCircleIcon from 'src/assets/icons/DismissCircleIcon';
import { useCustomPopoverPosition } from 'src/hooks/useCustomPopoverPosition';
import { CustomCheckbox } from './CustomCheckbox';

export interface ICustomMultiSelectOptionItem {
  label: string | JSX.Element;
  value: string | number;
}

interface ICustomMultiSelectProps extends Partial<SelectInputProps<unknown>> {
  options: ICustomMultiSelectOptionItem[];
  onChange?: (values: (string | number)[] | any) => void;
  renderItem?: (label: string | JSX.Element) => JSX.Element;
  customRenderLabel?: (label: string | JSX.Element) => JSX.Element;
  label?: string;
  containerClass?: string;
  inputClassName?: string;
  icon?: JSX.Element;
  showClearAllIcon?: boolean;
  customSx?: SxProps;
  seletedItem?: (string | number)[] | string;
  shouldSelectAll?: boolean;
  fixedPaperOnScroll?: boolean;
}

type StyleKeys = 'menuItem' | 'checkBox';

export const styles: {
  [name in StyleKeys]?: SxProps;
} = {
  menuItem: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: 0,
    marginRight: '12px',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
  },
};

export const MULTIPLE_SELECT_EMPTY = 'empty';
const MULTIPLE_SELECT_ALL = 'all';

const CustomMultiSelect: FC<ICustomMultiSelectProps> = ({
  options = [],
  onChange,
  renderItem,
  customRenderLabel,
  label,
  icon,
  showClearAllIcon = false,
  containerClass,
  inputClassName = '',
  customSx,
  seletedItem,
  shouldSelectAll = true,
  fixedPaperOnScroll = false,
  ...rest
}) => {
  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const paperRef = useRef<HTMLDivElement>(null);

  useCustomPopoverPosition({
    anchorEl,
    onPositionChange: handleSetPaperPosition,
  });

  useEffect(() => {
    if (seletedItem === MULTIPLE_SELECT_EMPTY) setSelectedValues([]);
    if (Array.isArray(seletedItem) && seletedItem.length) {
      setSelectedValues(seletedItem);
    }
  }, [seletedItem]);

  useEffect(() => {
    if (shouldSelectAll) {
      const allValues = options.map((opt) => opt.value);
      setSelectedValues(allValues);
    }
  }, [options.length, shouldSelectAll]);

  useEffect(() => {
    if (containerRef.current) {
      setAnchorEl(containerRef.current);
    }
  }, [containerRef.current]);

  function handleSetPaperPosition(top: number) {
    if (paperRef.current && !fixedPaperOnScroll) {
      (paperRef.current as HTMLElement).style.top = `${top}px`;
    }
  }

  const handleSelectAllValues = () => {
    const allValues = options.map((opt) => opt.value);
    setSelectedValues(allValues);
    onChange?.([]); // "All": Call API without params
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const { value } = event.target;

    if (!value || (!value && selectedValues.length === options.length)) {
      setSelectedValues([]);
      onChange?.([]);
      return;
    }

    if ((value as string[]).includes(MULTIPLE_SELECT_ALL) || (value as string[]).length === 0) {
      if (
        (selectedValues.length > 0 &&
          !(selectedValues as string[]).includes(MULTIPLE_SELECT_ALL)) ||
        (value as string[]).length === 0
      ) {
        setSelectedValues([]);
        onChange?.(MULTIPLE_SELECT_EMPTY); // Unchecked "All": for showing empty table
      } else {
        handleSelectAllValues();
      }
      return;
    }

    if (value && value?.length === options.length) {
      handleSelectAllValues();
      return;
    }

    setSelectedValues(value as (string | number)[]);
    onChange?.(value);
  };

  const handleClearAllSelection = () => {
    if (!selectedValues.length) return;
    setSelectedValues([]);
    onChange?.(MULTIPLE_SELECT_EMPTY); // Unchecked "All": for showing empty table
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const renderValue = (selected: (string | number)[]) => {
    if (selected.length === 0) {
      return '';
    } else if (selected.length === options.length) return 'All';

    if (renderItem) {
      return selected.map((s) => {
        const option = options.find((opt) => opt.value === s);
        return renderItem(option?.label || '');
      });
    }

    return selected
      .map((s) => {
        const option = options.find((opt) => opt.value === s);
        return option?.label || '';
      })
      .map((value) => {
        return typeof value === 'string' ? (
          value
        ) : (
          <div dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToString(value as any) }} />
        );
      })
      .join(', ');
  };

  const renderLabel = (label: string | JSX.Element) => {
    if (customRenderLabel) {
      return customRenderLabel(label);
    }

    if (renderItem) {
      return renderItem(label);
    }
    return (
      <Typography variant='body3' overflow='hidden' textOverflow='ellipsis'>
        {label}
      </Typography>
    );
  };

  const renderClearAllIcon = () => {
    if (!showClearAllIcon || !selectedValues.length || selectedValues.length === options.length)
      return null;

    return (
      <IconButton sx={{ p: 0, mr: 0.5 }} onClick={handleClearAllSelection}>
        <DismissCircleIcon />
      </IconButton>
    );
  };

  const renderIconComponent = (props: any) => {
    return (
      <Box className='flex items-center pr-2'>
        {renderClearAllIcon()}
        <Box onClick={() => setIsOpen(!isOpen)}>
          {props.className.includes('MuiSelect-iconOpen') ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
    );
  };

  return (
    <Box ref={containerRef} className={clsx('flex items-center', containerClass)}>
      {label && (
        <Box
          className='flex items-center justify-center border border-e-0 rounded-s-lg min-w-fit h-[46px] py-[11px] px-4'
          bgcolor='neutral.ne100'
          borderColor='neutral.ne400'
          sx={{ ...customSx }}
        >
          {icon && icon}
          <Typography variant='body3' color='neutral.ne600'>
            {label}
          </Typography>
        </Box>
      )}
      <Select
        {...rest}
        multiple
        value={selectedValues}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        input={
          <OutlinedInput
            className={clsx(
              'h-[46px] rounded-e-lg flex-1',
              {
                'w-[150px]': icon,
                'w-[200px]': !icon,
                'rounded-s-lg': !label,
              },
              inputClassName,
            )}
            sx={{
              borderRadius: '0px',
              ...customSx,
            }}
          />
        }
        renderValue={rest.renderValue || renderValue}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '12px',
            },
            ref: paperRef,
          },
          BackdropProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
          sx: {
            width: '150px',
            height: '300px',
            backgroundColor: 'transparent',
            marginTop: '5px',
            ...(!fixedPaperOnScroll && {
              // Set z-index below the header to avoid being overlap with the header
              zIndex: 1099,
            }),
          },
          MenuListProps: {
            style: {
              borderRadius: '12px',
              padding: 0,
              margin: 0,
            },
          },
          // disable scroll lock
          disableScrollLock: true,
        }}
        IconComponent={renderIconComponent}
      >
        <MenuItem key={MULTIPLE_SELECT_ALL} value={MULTIPLE_SELECT_ALL} sx={styles.menuItem}>
          <CustomCheckbox checked={selectedValues.length === options.length} sx={styles.checkBox} />
          {renderLabel('All')}
        </MenuItem>
        {options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value} sx={styles.menuItem}>
            <CustomCheckbox checked={selectedValues.includes(opt.value)} sx={styles.checkBox} />
            {renderLabel(opt.label)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CustomMultiSelect;
