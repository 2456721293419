import { BoxProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CustomTableContainer from './CustomTableContainer';

interface IScrollTableRowsBaseContainerProps extends BoxProps {
  maxRows: number;
  records: any[];
}

const ScrollTableRowsBaseContainer: React.FunctionComponent<IScrollTableRowsBaseContainerProps> = (
  props,
) => {
  const [maxHeight, setMaxheight] = useState(500);
  const containerRef = useRef<HTMLDivElement>(null);

  const shouldScroll = props.records?.length > props.maxRows;

  useEffect(() => {
    if (containerRef.current && props.records?.length > 0 && props.maxRows && shouldScroll) {
      // Get all rendered rows
      const rows: any = containerRef.current.querySelectorAll('tbody tr');
      let totalHeight = 0;

      console.log('rows', rows);

      // Calculate total height of first 10 rows (or all if less than 10)
      const rowsToMeasure = Math.min(props.maxRows, rows.length);
      for (let i = 0; i < rowsToMeasure; i++) {
        totalHeight += rows[i].offsetHeight;
      }

      // Add header height if it exists
      const header = containerRef.current.querySelector('thead');
      if (header) {
        totalHeight += header.offsetHeight;

        console.log('header', header);
      }

      setMaxheight(totalHeight);
    }
  }, [props.maxRows, props.records, containerRef.current, shouldScroll]);

  return shouldScroll ? (
    // Set maxHeight to make table scrollable
    <CustomTableContainer className='max-h-unset' ref={containerRef} maxHeight={maxHeight}>
      {props.children}
    </CustomTableContainer>
  ) : (
    <>{props.children}</>
  );
};

export default ScrollTableRowsBaseContainer;
