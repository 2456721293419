/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
  debounce,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE,
  INVESTMENT_ENTITY_TYPE,
  abnAcnNotAllowedStatus,
  trusteeNameRegexp,
} from 'src/constants/applications';
import { showToast } from 'src/helpers/toast';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, ISkyc } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import TrustTee from '../shared/TrustTee';
import TrustUbos from '../shared/TrustUbos';

interface IRegulatedTrustProps extends IInvestmentEntityProps {
  hideUBODeclaration?: boolean;
  enabledClearCorporateDetails?: boolean;
  disabledAbn?: boolean;
  isSuperAdmin?: boolean;
}

const RegulatedTrust = (props: IRegulatedTrustProps) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    isInvestmentEntitySetting,
    hideAbnSearch,
    hideUbo,
    disabledAbn,
  } = props;
  const { countryOptions } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { setValue, watch, setError, clearErrors } = useFormContext<IInvestmentEntityForm>();
  const watchTrustType = watch('investmentEntityDetails.regulatedTrust.trustType');
  const watchEnterManually = watch('investmentEntityDetails.regulatedTrust.enterManually');

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Trust,
  });

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    const isEntityTypeMatch = handleCheckEntityTypeMatch(skyc as OptionType & ISkyc, 'trust');
    const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

    if (reason === 'selectOption') {
      if (isEntityTypeMatch) {
        setValue('investmentEntityDetails.regulatedTrust.abn', skyc.value);
        setValue(
          'investmentEntityDetails.regulatedTrust.name',
          skyc.label?.replaceAll(trusteeNameRegexp, ''),
        );
        if (isNotAllowed) {
          setError('investmentEntityDetails.regulatedTrust.abn', {
            message: 'ABN is invalid',
            type: 'invalidAbn',
          });
        }
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const options = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  const trustTypeOptions = [
    {
      label: 'Registered managed investment scheme',
      value: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Registered,
    },
    {
      label: 'Unregistered managed investment scheme ',
      value: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Unregistered,
    },
    {
      label: 'Government-managed superannuation fund',
      value: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Government,
    },
    {
      label: 'Superannuation fund that is registered and regulated by APRA',
      value: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Superannuation,
    },
    { label: 'Other regulated trust', value: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Other },
  ];

  return (
    <Box>
      <Grid container spacing={3}>
        {!isExistingInvestmentEntity && !isInvestmentEntitySetting && !hideAbnSearch && (
          <Grid item xs={12}>
            <FormAutocomplete
              name=''
              dataKey='id'
              options={options}
              searchIcon={<SearchIcon />}
              isLoading={isSearchSkycLoading}
              placeholder='Search by Name or ABN'
              hidePopupIcon
              renderOption={(props, option) => (
                <li {...props}>
                  <Box className='flex flex-col'>
                    <Typography variant='body3' fontWeight='medium'>
                      {option.label}
                    </Typography>
                    <Typography variant='caption1' color='neutral.ne500' paddingTop='4px'>
                      {`ABN: ${option.value}`}
                    </Typography>
                  </Box>
                </li>
              )}
              renderTags={(selected) => (
                <Typography variant='body2'>
                  <span className='line-clamp-1'>
                    {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
                  </span>
                </Typography>
              )}
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.value || ''}
              onInputChange={handleSearchSkycInputChange}
              onChange={handleSkycChange}
              onFocus={() => clearErrors('investmentEntityDetails.regulatedTrust.abn')}
              disabled={disabled}
              blurOnSelect
            />
          </Grid>
        )}
        {!isExistingInvestmentEntity && !isInvestmentEntitySetting && !hideAbnSearch && (
          <Grid xs={12} display='flex' justifyContent='flex-end' mb={-1}>
            <FormCheckbox
              name='investmentEntityDetails.regulatedTrust.enterManually'
              size='small'
              label={<Typography variant='body3'>Enter manually</Typography>}
              disabled={disabled}
              controlLabelSx={{
                mt: 1,
                alignItems: 'center',
              }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.regulatedTrust.name'
            label='Name'
            placeholder='Enter name'
            disabled={disabled || (!watchEnterManually && !isInvestmentEntitySetting)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.regulatedTrust.abn'
            label='ABN'
            placeholder='Enter ABN'
            maxLength={14}
            formatValue={(value) => formatValueWithSpaces(value)}
            disabled={
              disabled || (!watchEnterManually && !isInvestmentEntitySetting) || disabledAbn
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.regulatedTrust.arsn'
            label='ARSN'
            placeholder='Enter ARSN'
            maxLength={11}
            formatValue={(value: string) => {
              if (Number.isNaN(+value) && value?.length === 1) return '';
              return formatValueWithSpaces(value, 3) || value;
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.regulatedTrust.registeredOffice'
            label='Registered Office'
            placeholder='Enter registered office'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.regulatedTrust.countryOfEstablishment'
            options={countryOptions}
            label='Country of Establishment'
            placeholder='Select country of establishment'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.regulatedTrust.countryOfTaxResident'
            options={countryOptions}
            label='Country of Tax Residence'
            placeholder='Select country of tax residence'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.regulatedTrust.trustType'
            options={trustTypeOptions}
            label='Trust Type'
            placeholder='Select trust type'
            disabled={disabled}
          />
        </Grid>
        {watchTrustType === INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Other && (
          <Grid item xs={6}>
            <FormInput
              name='investmentEntityDetails.regulatedTrust.otherTrustType'
              label='Other Type'
              placeholder='Enter other type'
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      <TrustTee {...props} hideUbo={false} keyName='investmentEntityDetails.regulatedTrust' />
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      {!hideUbo && <TrustUbos {...props} keyName='investmentEntityDetails.regulatedTrust' />}
    </Box>
  );
};

export default RegulatedTrust;
