import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DATE_PICKER_FORMAT } from 'src/constants/date';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const utcToLocalTimezone = (time?: string, formatType: string = DATE_PICKER_FORMAT): string => {
  const utcTime = dayjs.utc(time);
  const timezone = dayjs.tz.guess();

  if (!time) return dayjs.utc().tz(timezone).format(formatType);
  return utcTime.tz(timezone).format(formatType);
};

const checkIsExpired = (expiredTime: string | undefined) => {
  if (!expiredTime) return false;

  return dayjs.utc().isAfter(dayjs.utc(expiredTime));
};

const getRelativeTimeFromNow = (time: string) => {
  const timezone = dayjs.tz.guess();
  const utcTime = dayjs.utc(time);

  return dayjs(utcTime.tz(timezone)).fromNow();
};

const getEndOfDateUTC = (dateStr?: string) => {
  if (!dateStr) return dayjs().endOf('date').utc().format();

  return dayjs(dateStr).endOf('date').utc().format();
};

export const getEndOfDateLocal = (dateStr?: string) => {
  if (!dateStr) return dayjs().endOf('date').format();

  return dayjs(dateStr).endOf('date').format();
};

const isCurrentDate = (date?: string) => {
  if (!date) return false;
  const isUTC = dayjs(date).isUTC();

  if (isUTC)
    return dayjs(utcToLocalTimezone(date, '')).startOf('date').isSame(dayjs().startOf('date'));

  return dayjs(date).startOf('date').isSame(dayjs().startOf('date'));
};

export {
  checkIsExpired,
  dayjs,
  getEndOfDateUTC,
  getRelativeTimeFromNow,
  isCurrentDate,
  utcToLocalTimezone,
};
