import { AccordionDetails, Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Accordion, AccordionSummary } from 'src/components/atoms/CustomAccordion';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import AddNew from 'src/components/pages/applications/components/InvestmentEntity/shared/AddNew';
import SelectExisting from 'src/components/pages/applications/components/InvestmentEntity/shared/SelectExisting';
import { investmentEntityPartnershipDefaultValue } from 'src/constants/applications';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import PartnershipDetailsForm from './PartnershipDetailsForm';

interface IPartnershipProps extends IInvestmentEntityProps {}

const Partnership: FC<IPartnershipProps> = (props) => {
  const {
    id = '',
    isExistingInvestmentEntity,
    isReviewing,
    isViewMode,
    isDisabledEdit,
    isCreateFundApplicationForm,
    investmentEntityId,
  } = props;
  const { reset } = useFormContext<IInvestmentEntityForm>();
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.entityType) && !isCreateFundApplicationForm) {
      reset(investmentEntityPartnershipDefaultValue, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <SelectExisting {...props} defaultValue={{}} />
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                Partnership Details
              </Typography>
              {isExistingInvestmentEntity && !isReviewing && (
                <UpdateDetailsButton
                  entityId={investmentEntityId}
                  disabled={isViewMode || isDisabledEdit}
                />
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <PartnershipDetailsForm {...props} hideUBODeclaration={isCreateFundApplicationForm} />
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default Partnership;
