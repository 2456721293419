import * as React from 'react';

interface IWarningImageProps {}

const WarningImage: React.FunctionComponent<IWarningImageProps> = (props) => {
  return (
    <svg
      width='238'
      height='98'
      viewBox='0 0 238 98'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='238' height='98' fill='white' />
      <path
        d='M131.5 32V28.25C131.5 27.2835 132.284 26.5 133.25 26.5C134.216 26.5 135 25.7165 135 24.75V23.75C135 22.2312 133.769 21 132.25 21H132C130.343 21 129 19.6569 129 18V17C129 15.3431 130.343 14 132 14H153.5C155.157 14 156.5 15.3431 156.5 17V18.5C156.5 20.1569 155.157 21.5 153.5 21.5H148.5C147.119 21.5 146 22.6193 146 24C146 25.3807 147.119 26.5 148.5 26.5H166C167.657 26.5 169 27.8431 169 29.5V32C169 33.6569 167.657 35 166 35H134.5C132.843 35 131.5 33.6569 131.5 32Z'
        fill='#FFF6CC'
      />
      <ellipse cx='168' cy='21' rx='5' ry='4' fill='#FFF6CC' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M197.477 42.0691V44.4043H198.432V42.0691H200.767V41.1145H198.432V38.7793H197.477V41.1145H195.142V42.0691H197.477ZM197.477 42.0691H198.432V41.1145H197.477V42.0691Z'
        fill='#9A6C00'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M137.744 68C135.286 77.5344 127.112 84.6176 117.302 84.985C117.203 84.9949 117.102 85 117 85H116.5H38C36.3431 85 35 83.6569 35 82V79.1915C35 77.5346 36.3431 76.1915 38 76.1915H68.0492C69.6708 76.1915 70.9854 74.8769 70.9854 73.2553C70.9854 71.6337 69.6708 70.3191 68.0492 70.3191H56.5C54.8431 70.3191 53.5 68.976 53.5 67.3192V65C53.5 63.3431 54.8431 62 56.5 62H94.5C94.5 58.8061 95.1227 55.7643 96.2481 53H90C88.3431 53 87 51.6569 87 50V47C87 45.3431 88.3431 44 90 44H101V45.6778C104.977 41.5502 110.453 39 116.5 39C122.208 39 127.409 41.2728 131.319 45H179C180.657 45 182 46.3431 182 48V49.3404C182 50.9973 180.657 52.3404 179 52.3404H156.174C154.517 52.3404 153.174 53.6836 153.174 55.3404V56.6809C153.174 58.3377 154.517 59.6809 156.174 59.6809H169.576C171.233 59.6809 172.576 61.024 172.576 62.6809V65C172.576 66.6569 171.233 68 169.576 68H137.744Z'
        fill='#FFF6CC'
      />
      <ellipse cx='195.5' cy='51.5' rx='4.5' ry='3.5' fill='#FFF6CC' />
      <circle cx='153.5' cy='75.5' r='3' stroke='#9A6C00' />
      <circle cx='96' cy='30' r='4.5' stroke='#9A6C00' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M110.934 40.3628C110.861 40.0416 111.06 39.721 111.382 39.6532C115.769 38.7311 120.328 39.1499 124.486 40.8646C128.828 42.655 132.524 45.7713 135.069 49.7892C137.615 53.807 138.886 58.5314 138.712 63.3193C138.545 67.9037 137.059 72.3331 134.447 76.0555C134.256 76.3276 133.877 76.3794 133.614 76.1759C133.359 75.9784 133.31 75.6138 133.495 75.3497C135.969 71.8206 137.377 67.6218 137.536 63.2762C137.701 58.7356 136.495 54.2552 134.081 50.4448C131.667 46.6344 128.163 43.6791 124.045 41.9812C120.115 40.3606 115.808 39.9606 111.661 40.8233C111.333 40.8914 111.007 40.6891 110.934 40.3628ZM125.391 81.9808C125.545 82.2752 125.431 82.6387 125.134 82.7814C120.481 85.0145 115.186 85.6112 110.105 84.4642C105.022 83.317 100.463 80.4952 97.1607 76.4647C96.951 76.2088 96.9983 75.8319 97.2588 75.6312C97.5164 75.4327 97.8855 75.4816 98.0932 75.7345C101.224 79.5479 105.542 82.2177 110.355 83.3041C115.167 84.3903 120.181 83.8276 124.59 81.717C124.883 81.5764 125.239 81.6901 125.391 81.9808ZM132.574 78.6111C132.834 78.3423 132.817 77.9132 132.545 77.6596C132.271 77.4041 131.843 77.4236 131.58 77.6938C130.534 78.7703 129.379 79.7334 128.135 80.5676C127.823 80.7763 127.723 81.1945 127.918 81.5134C128.114 81.8332 128.532 81.9319 128.844 81.7231C130.193 80.8223 131.443 79.7791 132.574 78.6111ZM93.6359 62.5488C93.3938 62.4522 93.2364 62.2135 93.2455 61.9517C93.4024 57.4451 94.3521 52.7961 96.8772 49.0576C99.3677 45.3704 102.808 42.4593 106.796 40.6554C107.101 40.5172 107.457 40.6718 107.578 40.9879C107.694 41.2905 107.55 41.6291 107.258 41.7614C103.474 43.4719 100.21 46.2335 97.8468 49.7318C95.5396 53.1475 94.6782 57.8033 94.4888 61.9875C94.4692 62.4197 94.0355 62.7083 93.6359 62.5488Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M110.3 39.947C110.224 39.6186 110.428 39.2897 110.758 39.222C115.31 38.2888 120.04 38.7167 124.357 40.4596C128.862 42.2788 132.698 45.442 135.341 49.5189C137.985 53.5957 139.308 58.3881 139.13 63.2438C138.96 67.8958 137.421 72.3894 134.712 76.164C134.515 76.4379 134.132 76.4903 133.863 76.2867C133.594 76.0832 133.542 75.7007 133.738 75.4266C136.296 71.8522 137.749 67.6005 137.91 63.1992C138.079 58.5943 136.824 54.0494 134.317 50.1831C131.81 46.3168 128.173 43.3169 123.9 41.5917C119.816 39.9427 115.341 39.5349 111.034 40.4117C110.704 40.4789 110.376 40.2753 110.3 39.947ZM125.601 82.6975C125.752 82.9989 125.63 83.3665 125.325 83.5098C120.514 85.77 115.074 86.345 109.887 85.1307C104.699 83.9165 100.08 80.9871 96.7715 76.8263C96.5616 76.5624 96.6159 76.1791 96.8851 75.9761C97.1543 75.7732 97.5365 75.8276 97.7467 76.0911C100.883 80.024 105.256 82.7929 110.165 83.942C115.074 85.0911 120.222 84.5506 124.778 82.4182C125.083 82.2753 125.449 82.3962 125.601 82.6975ZM132.778 78.6575C133.047 78.3822 133.029 77.94 132.746 77.6787C132.462 77.4175 132.022 77.4361 131.752 77.7109C130.665 78.8177 129.465 79.8076 128.171 80.6646C127.85 80.8774 127.748 81.3064 127.951 81.6341C128.153 81.9618 128.584 82.0641 128.906 81.8518C130.306 80.9275 131.604 79.8567 132.778 78.6575ZM93.4063 62.1621C93.1615 62.0643 93.0017 61.8259 93.008 61.5625C93.1157 57.0287 94.0386 52.361 96.5961 48.6288C99.1207 44.9445 102.635 42.0543 106.728 40.2875C107.037 40.1539 107.393 40.3065 107.519 40.6193C107.644 40.9322 107.492 41.2869 107.183 41.4209C103.314 43.0975 99.9915 45.8335 97.6031 49.3189C95.2729 52.7195 94.4391 57.3788 94.2907 61.5787C94.2749 62.0259 93.8218 62.3282 93.4063 62.1621Z'
        fill='#9A6C00'
      />
      <path opacity='0.01' d='M126 52L126 72H106V52L126 52Z' fill='#9A6C00' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M118.408 54.9829L124.8 65.5829C125.272 66.3573 125.288 67.3268 124.842 68.1162C124.331 68.9827 123.397 69.5103 122.392 69.4996H109.608C108.596 69.5034 107.66 68.9622 107.158 68.0829C106.724 67.302 106.74 66.349 107.2 65.5829L113.592 54.9829C114.117 54.1612 115.025 53.6641 116 53.6641C116.975 53.6641 117.883 54.1612 118.408 54.9829ZM122.392 67.8329C122.797 67.8508 123.179 67.6421 123.383 67.2912C123.533 67.0298 123.53 66.708 123.375 66.4496L116.983 55.8496C116.75 55.5416 116.386 55.3607 116 55.3607C115.614 55.3607 115.25 55.5416 115.017 55.8496L108.617 66.4579C108.468 66.7157 108.468 67.0334 108.617 67.2912C108.821 67.6421 109.203 67.8508 109.608 67.8329H122.392Z'
        fill='#9A6C00'
      />
      <circle cx='116' cy='65.3333' r='0.833333' fill='#9A6C00' />
      <path
        d='M116 58.666C115.54 58.666 115.167 59.0391 115.167 59.4993V62.8327C115.167 63.2929 115.54 63.666 116 63.666C116.46 63.666 116.833 63.2929 116.833 62.8327V59.4993C116.833 59.0391 116.46 58.666 116 58.666Z'
        fill='#9A6C00'
      />
    </svg>
  );
};

export default WarningImage;
