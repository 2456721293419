import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import DownloadLocalButton from 'src/components/atoms/DownloadLocalButton';
import UploadButton from 'src/components/atoms/UploadButton';
import ActionMenus, { ActionMenusHandler } from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import {
  APPLICATION_DOCUMENT_TYPE,
  KYC_FOREIGN_DOCUMENT_ACCEPT_TYPE,
  MAX_ADDITIONAL_DOCUMENT_UPLOAD,
} from 'src/constants/applications';
import {
  useDeleteVerifiedIdDocument,
  useDownloadAdditionalDocument,
  useVerifyIdDocument,
} from 'src/modules/applications/hooks';
import { IVerifyIndividualItem } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useUploadDocument } from 'src/modules/common/hooks';
import { IAlertInfo, IDocument } from 'src/modules/common/type';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

interface IForeignResidentDocumentProps extends IVerifyIndividualItem {}

interface ITableData extends IDocument {
  documentType: number;
}

const DEFAULT_DOCUMENT_DESCRIPTION = 'Certified ID document';

const ForeignResidentDocument: FC<IForeignResidentDocumentProps> = ({
  appId,
  uboId = '',
  verifiedIdDocuments,
}) => {
  const actionMenusRef = useRef<ActionMenusHandler>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });

  const { data: currentUser } = useGetUserInfo();
  const { mutate: uploadDocument, isLoading: isUploadingDocument } = useUploadDocument();
  const { mutate: verifyIdDocument, isLoading: isVerifyingDocument } = useVerifyIdDocument();
  const { mutate: downloadDocument } = useDownloadAdditionalDocument();
  const { mutate: deleteVerifiedDocument, isLoading: isDeletingDocument } =
    useDeleteVerifiedIdDocument();

  const { control } = useFormContext();

  const {
    fields: documents,
    append: addDocument,
    remove: removeDocument,
  } = useFieldArray({
    control,
    name: 'documents',
    keyName: 'key',
  });

  const handleAddDocument = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    uploadDocument(file, {
      onSuccess: (data: any) => {
        verifyIdDocument(
          {
            appId,
            body: {
              uboId,
              verifiedIdDocument: {
                ...data,
                modifiedById: currentUser?.id,
              },
            },
          },
          {
            onSuccess: (documentId) => {
              handleShowAlertModal({
                title: 'You did it !',
                description: 'File uploaded successfully.',
                isError: false,
              });

              const newData = {
                ...data,
                id: documentId,
                uploadedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
                uploadedDate: dayjs().toString(),
                isAddNew: true,
                localUrl: URL.createObjectURL(file),
                description: DEFAULT_DOCUMENT_DESCRIPTION,
                documentType: APPLICATION_DOCUMENT_TYPE.VerifiedId,
              };

              addDocument(newData);
            },
            onError: handleErrorFromServer,
          },
        );
      },
      onError: handleErrorFromServer,
    });
  };

  const handleShowAlertModal = (alertInfo: IAlertInfo) => {
    setAlertInfo(alertInfo);
    alertRef.current?.open();
  };

  const handleDeleteDocument = (row: ITableData, index: number) => {
    deleteVerifiedDocument(
      {
        appId,
        documentId: row.id || '',
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const fileColumns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable className='line-clamp-1' value={row.description || ''} />
      ),
    },
    {
      title: 'File Name',
      key: 'fileName',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(row.fileType);
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='line-clamp-1 pl-2 break-all' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File Size',
      key: 'fileSize',
      sx: { width: '18%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Date Uploaded',
      key: 'dateUploaded',
      sx: { width: '19%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={String(utcToLocalTimezone(row.uploadedDate || ''))} />
      ),
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      sx: { width: '23%' },
      renderNode: (row: ITableData, index: number) => {
        const menus = [
          {
            icon: <DownloadIcon width='16' height='16' />,
            label: (
              <DownloadLocalButton
                downloadUrl={''}
                fileName={row?.fileName || ''}
                sx={{
                  width: '100%',
                }}
              >
                Download
              </DownloadLocalButton>
            ),
            onAction: () => {
              downloadDocument({
                id: appId,
                fileName: row.fileName,
                documentId: row.id || '',
                type: row.documentType,
              });
            },
          },

          {
            icon: <DeleteIcon />,
            label: 'Delete',
            onAction: () => handleDeleteDocument(row, index),
          },
        ];
        return (
          <Box className='flex items-center justify-between'>
            <StringNodeTable value={String(row.uploadedBy || '')} />
            <ActionMenus ref={actionMenusRef} menus={menus} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box className='mx-[-24px] pt-4'>
        <Box className='flex items-end justify-end'>
          <UploadButton
            className='w-fit'
            startIcon={<UploadFilesIcon />}
            label={'Upload Files'}
            inputProps={{
              accept: KYC_FOREIGN_DOCUMENT_ACCEPT_TYPE,
            }}
            handleFileChange={handleAddDocument}
            validate={{
              fileSize: MAX_ADDITIONAL_DOCUMENT_UPLOAD,
              onError: () =>
                handleShowAlertModal({
                  title: '',
                  isError: true,
                  description: `Selected file exceeds the maximum allowed size of 20 MB.<br />Please choose a different file or reduce its size.`,
                }),
            }}
            sx={{
              px: 2,
              width: '100%',
            }}
            disabled={isUploadingDocument || isVerifyingDocument || isDeletingDocument}
          />
        </Box>
        <Box className='pt-6'>
          <Typography className='pb-2' variant='body2' fontWeight='medium'>
            Uploaded Files
          </Typography>
          <CustomTableContainer>
            <CustomTable
              rows={documents as any}
              columns={fileColumns}
              hasPagination={false}
              stickyHeader
              displayEmpty
              hiddenHorizontalRule
              customEmpty={
                <EmptyData
                  isTable
                  description='No document.'
                  icon={<DocumentOnePageIcon width={22} height={27} />}
                  marginY='34px'
                />
              }
              selectedRows={[]}
            />
          </CustomTableContainer>
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          isError={alertInfo?.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default ForeignResidentDocument;
