/* eslint-disable no-restricted-imports */
import { Box, OutlinedInput } from '@mui/material';
import { Fundraising } from 'src/assets/icons/Fundraising';
import { PeopleIcon } from 'src/assets/icons/PeopleIcon';
import CustomMultiSelect from 'src/components/atoms/CustomMultiSelect';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { useGetOverarchingFilter } from 'src/modules/common/hooks';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';

const customSx = {
  height: '32px !important',
  fontSize: 14,
  color: 'neutral.ne400',
};

const FundHeaderFilter = () => {
  const { data: overarchingFilter } = useGetOverarchingFilter();
  const {
    isMultiSelectClient,
    selectedClients,
    selectedFunds,
    onChangeClient,
    onChangeFund,
    isFmPortal,
    fundOptions,
  } = useFundPortalContext();

  const clientOptions = () => {
    return overarchingFilter.clients.map((it) => ({ label: it.name, value: it.id }));
  };

  const getShouldSelectedAll = (key: 'clients' | 'funds') => {
    const overarchingFilerFromStorage = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.OVERARCHING_FILTER) || '{}',
    );

    if (key === 'clients') {
      return selectedClients?.length === 0 && !overarchingFilerFromStorage?.clients?.length;
    }

    return selectedFunds?.length === 0 && !overarchingFilerFromStorage?.funds?.length;
  };

  const handleMultipleClientChange = (value: string | string[]) => {
    onChangeClient(value);
  };

  const handleFundsChange = (value: string | string[]) => {
    onChangeFund(value);
  };

  return (
    <Box className='flex flex-col min-[960px]:flex-row' gap={1.5}>
      {isFmPortal ? null : !isMultiSelectClient ? (
        <CustomSelect
          value={selectedClients}
          onChange={(e: any) => onChangeClient(e.target.value)}
          input={
            <OutlinedInput
              className='h-[32px] w-[200px] rounded-lg'
              sx={{
                borderRadius: '0px',
                fontSize: 14,
                color: 'neutral.ne800',
              }}
            />
          }
          displayEmpty
          renderValue={(value) => {
            return (
              clientOptions()?.find((it) => it.value === value)?.label || (
                <Box color='neutral.ne800' display='flex' alignItems='center'>
                  <Box mr={0.75} fontSize='12px'>
                    <PeopleIcon />
                  </Box>
                  Select Clients
                </Box>
              )
            );
          }}
        >
          {clientOptions()?.map((option: { value: string; label: string }) => (
            <CustomMenuItem key={option.value} value={option.value}>
              {option.label}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      ) : (
        <CustomMultiSelect
          shouldSelectAll={getShouldSelectedAll('clients')}
          seletedItem={selectedClients}
          options={clientOptions()}
          onChange={handleMultipleClientChange}
          customSx={customSx}
          renderValue={(value) => {
            return (
              <Box color='neutral.ne800' display='flex' alignItems='center'>
                <Box mr={0.75} fontSize='12px'>
                  <PeopleIcon />
                </Box>
                Select Clients ({(value as any).length})
              </Box>
            );
          }}
          fixedPaperOnScroll
        />
      )}
      <CustomMultiSelect
        shouldSelectAll={getShouldSelectedAll('funds')}
        seletedItem={selectedFunds}
        options={fundOptions}
        onChange={handleFundsChange}
        customSx={customSx}
        renderValue={(value) => {
          return (
            <Box color='neutral.ne800' display='flex' alignItems='center'>
              <Box mr={0.75} fontSize='14px'>
                <Fundraising color='currentColor' width='1em' height='1em' />
              </Box>
              Select Funds ({(value as any).length})
            </Box>
          );
        }}
        fixedPaperOnScroll
      />
    </Box>
  );
};

export default FundHeaderFilter;
