import { useCallback, useEffect } from 'react';

interface IUseCustomPopoverPositionProps {
  anchorEl: HTMLDivElement | null;
  onPositionChange?: (top: number) => void;
}

/**
 * A custom hook that manages the positioning of a popover element relative to its anchor element.
 * It handles scroll and resize events to maintain correct positioning and automatically scrolls
 * to the top when the popover is opened.
 *
 * @param {IUseCustomPopoverPositionProps} props - The hook props
 * @param {HTMLDivElement | null} props.anchorEl - The reference element to which the popover will be anchored
 * @returns {Object} An object containing the calculated top position
 * @returns {number} top - The calculated top position in pixels for the popover paper
 */
export const useCustomPopoverPosition = ({
  anchorEl,
  onPositionChange,
}: IUseCustomPopoverPositionProps) => {
  // const [top, setTop] = useState(0);
  const isOpen = !!anchorEl;

  // Scroll to the top when the filter is open
  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  /**
   * Calculates and sets the top position of the popover paper based on the anchor element's position
   * @param {HTMLDivElement} element - The reference element
   */
  const handleSetPaperPosition = useCallback(
    (element: HTMLDivElement) => {
      if (!isOpen) return;
      if (element) {
        const newTop = (element.getBoundingClientRect()?.top || 0) + element.clientHeight;
        onPositionChange?.(newTop);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (!anchorEl || !isOpen) return;

    const handleScroll = () => handleSetPaperPosition(anchorEl);
    const handleResize = () => handleSetPaperPosition(anchorEl);

    // Initial position calculation
    handleSetPaperPosition(anchorEl);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [anchorEl, handleSetPaperPosition, isOpen]);

  // return {
  //   top,
  // };
};
