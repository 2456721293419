/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { FundType, InvestorRelationsContact } from 'src/constants/funds-management';
import { removeSpacesInString } from 'src/utils/common';
import * as Yup from 'yup';
import { YupBase } from './common';

export const fundsDetailsSchema = Yup.object().shape({
  name: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  code: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.string()
      : Yup.string()
          .required(REQUIRE_MESSAGE)
          .test('invalidCode', 'Fund code must have from 3 to 6 characters.', (code) => {
            const rawCode = removeSpacesInString(code)?.split('-')[0];
            if ((rawCode?.length as number) >= 3 && (rawCode?.length as number) <= 6) return true;
            return false;
          });
  }),
  offerType: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  type: Yup.number().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  trustType: Yup.number().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  subType: Yup.number().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  abn: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.string()
      : Yup.string()
          .nullable()
          .test('invalidAbn', 'ABN must have 11 digits.', (abn) => {
            if (abn) {
              const rawAbn = removeSpacesInString(abn);
              return rawAbn?.length === 11;
            }
            return true;
          });
  }),
  tfn: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.string()
      : Yup.string()
          .nullable()
          .test('invalidAbn', 'TFN should be between 8 and 9 digits.', (abn) => {
            if (abn) {
              const tfnAbn = removeSpacesInString(abn) as string;
              return tfnAbn.length >= 8 && tfnAbn.length <= 9;
            }
            return true;
          });
  }),
  isSaveDraft: Yup.boolean(),
  currency: Yup.number()
    .when(['isSaveDraft', 'type'], {
      is: (isSaveDraft: boolean, type: number) => !isSaveDraft && type === FundType.Partnership,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  ownershipPercentageLimit: Yup.number()
    .when(['type', 'isSaveDraft'], ([type, isSaveDraft]) => {
      if (type === FundType.Partnership) {
        return Yup.number()
          .test(
            'Greater than 0',
            'Ownership Percentage Limit must be greater than 0',
            function (value) {
              return isNumber(value) ? value > 0 : true;
            },
          )
          .test('required', REQUIRE_MESSAGE, function (value) {
            return isSaveDraft ? true : isNumber(value);
          })
          .nullable();
      }

      return Yup.mixed().nullable();
    })
    .nullable(),
  initialCloseDate: YupBase.date
    .when(['type', 'isSaveDraft'], ([type, isSaveDraft]) => {
      if (type === FundType.Partnership) {
        return Yup.string()
          .test('required', REQUIRE_MESSAGE, function (value) {
            return isSaveDraft ? true : !!value;
          })
          .nullable();
      }

      return Yup.string().nullable();
    })
    .nullable(),
  lateInitialCloseDate: YupBase.date
    .when(['type', 'isSaveDraft'], ([type, isSaveDraft]) => {
      if (type === FundType.Partnership) {
        return Yup.string()
          .test(
            'greater than Initial Close Date',
            'Late Initial Close Date must be greater than Initial Close Date.',
            function (value: any) {
              return value ? dayjs(value).isAfter(dayjs(this.parent.initialCloseDate)) : true;
            },
          )
          .test('required', REQUIRE_MESSAGE, function (value) {
            return isSaveDraft ? true : !!value;
          })
          .nullable();
      }

      return Yup.string().nullable();
    })
    .nullable(),
});

export const contactDetailsSchema = Yup.object().shape({
  contacts: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            position: Yup.string(),
            email: Yup.string().email('Invalid Email format'),
            contactNumber: Yup.string(),
            countryFlagCode: Yup.string(),
          }),
        )
      : Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required(REQUIRE_MESSAGE),
            lastName: Yup.string().required(REQUIRE_MESSAGE),
            position: Yup.string().required(REQUIRE_MESSAGE),
            email: YupBase.email,
            contactNumber: Yup.string().required(REQUIRE_MESSAGE),
            countryFlagCode: Yup.string(),
          }),
        );
  }),
  investorContact: Yup.object().shape(
    {
      firstName: Yup.string().when(['lastName', 'email', 'contactNumber', 'position'], {
        is: (lastName: string, email: string, contactNumber: string, position: string) =>
          lastName || email || contactNumber || position,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
        otherwise: (schema) => schema,
      }),
      lastName: Yup.string().when(['firstName', 'email', 'contactNumber', 'position'], {
        is: (firstName: string, email: string, contactNumber: string, position: string) =>
          firstName || email || contactNumber || position,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
        otherwise: (schema) => schema,
      }),
      email: Yup.string().when(['firstName', 'lastName', 'contactNumber', 'position'], {
        is: (firstName: string, lastName: string, contactNumber: string, position: string) =>
          firstName || lastName || contactNumber || position,
        then: () => YupBase.email,
        otherwise: (schema) => schema.email('Invalid Email format'),
      }),
      contactNumber: Yup.string().when(['firstName', 'lastName', 'email', 'position'], {
        is: (firstName: string, lastName: string, email: string, position: string) =>
          firstName || lastName || email || position,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
        otherwise: (schema) => schema,
      }),
      position: Yup.string().when(['firstName', 'lastName', 'email', 'contactNumber'], {
        is: (firstName: string, lastName: string, email: string, contactNumber: string) =>
          firstName || lastName || email || contactNumber,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
        otherwise: (schema) => schema,
      }),
    },
    [
      ['firstName', 'lastName'],
      ['firstName', 'email'],
      ['firstName', 'contactNumber'],
      ['firstName', 'position'],
      ['lastName', 'firstName'],
      ['lastName', 'email'],
      ['lastName', 'contactNumber'],
      ['lastName', 'position'],
      ['email', 'firstName'],
      ['email', 'lastName'],
      ['email', 'contactNumber'],
      ['email', 'position'],
      ['contactNumber', 'firstName'],
      ['contactNumber', 'lastName'],
      ['contactNumber', 'email'],
      ['contactNumber', 'position'],
      ['position', 'firstName'],
      ['position', 'lastName'],
      ['position', 'email'],
      ['position', 'contactNumber'],
    ],
  ),
  isSaveDraft: Yup.boolean(),
});

export const createSignatoryContactSchema = Yup.object().shape({
  firstName: Yup.string().when(['option'], {
    is: (option: number) => option === InvestorRelationsContact.Organisation,
    then: (shema) => shema.required(REQUIRE_MESSAGE),
    otherwise: () => YupBase.firstName,
  }),
  lastName: Yup.string().when(['option'], {
    is: (option: number) => option === InvestorRelationsContact.Organisation,
    then: (shema) => shema.required(REQUIRE_MESSAGE),
    otherwise: () => YupBase.firstName,
  }),
  email: YupBase.email,
  contactNumber: YupBase.contactNumber,
  position: Yup.string().required(REQUIRE_MESSAGE),
});

export const signatoryContactSchema = Yup.object().shape({
  signatoryContacts: Yup.array().when(['isSaveDraft'], {
    is: true,
    then: (schema) => schema.of(Yup.object()),
    otherwise: (schema) =>
      schema
        .of(
          Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            position: Yup.string(),
            email: Yup.string().email('Invalid Email format'),
            contactNumber: Yup.string(),
            countryFlagCode: Yup.string(),
            isSelected: Yup.boolean(),
          }),
        )
        .compact((v) => !v.isSelected)
        .min(1, 'One person is required')
        .required(REQUIRE_MESSAGE),
  }),
});

export const fundOverviewAndBradingSchema = Yup.object().shape({
  overview: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  correspondenceDisclaimer: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  fundContactId: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
});

export const fundTrusteeAndServiceProvidersSchema = Yup.object().shape({
  trustee: Yup.object().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.object().shape({
          capacity: Yup.string(),
          existing: Yup.string(),
          newExistings: Yup.array(),
          isAddNew: Yup.boolean(),
        })
      : Yup.object().shape({
          capacity: Yup.string().when('isAddNew', ([isAddNew]) => {
            return isAddNew ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
          }),
          existing: Yup.string().when('isAddNew', ([isAddNew]) => {
            return isAddNew ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
          }),
          newExistings: Yup.array(),
          isAddNew: Yup.boolean(),
        });
  }),
  manager: Yup.object().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.object().shape({
          capacity: Yup.string(),
          existing: Yup.string(),
          newExistings: Yup.array(),
          isAddNew: Yup.boolean(),
        })
      : Yup.object().shape({
          capacity: Yup.string().when('isAddNew', ([isAddNew]) => {
            return isAddNew ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
          }),
          existing: Yup.string().when('isAddNew', ([isAddNew]) => {
            return isAddNew ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
          }),
          newExistings: Yup.array(),
          isAddNew: Yup.boolean(),
        });
  }),
  providers: Yup.object().shape({
    capacity: Yup.mixed().nullable(),
    existing: Yup.mixed().nullable(),
    newExistings: Yup.array(),
  }),
  clientId: Yup.string(),
  isSaveDraft: Yup.boolean(),
});

export const upsertProviderSchema = Yup.object().shape({
  displayName: Yup.string().required(REQUIRE_MESSAGE),
  legalName: Yup.string().required(REQUIRE_MESSAGE),
  abn: Yup.string()
    .required(REQUIRE_MESSAGE)
    .test('invalidAbn', 'ACN/ABN must have 9 or 11 digits.', (abn) => {
      const rawAbn = removeSpacesInString(abn);
      if (rawAbn?.length === 9 || rawAbn?.length === 11) return true;
      return false;
    })
    .transform((current) => {
      return (current || '')?.split(' ')?.join('');
    }),
  primaryContacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(REQUIRE_MESSAGE),
      lastName: Yup.string().required(REQUIRE_MESSAGE),
      position: Yup.string().required(REQUIRE_MESSAGE),
      email: YupBase.email,
      phoneNumber: Yup.string().required(REQUIRE_MESSAGE),
      countryFlagCode: Yup.string(),
    }),
  ),
  physicalAddress: Yup.object().shape({
    country: Yup.object(),
    state: Yup.object(),
    manualEntry: Yup.boolean(),
    city: Yup.object().when('manualEntry', ([manualEntry]) => {
      if (manualEntry) return Yup.string();
      return Yup.object();
    }),
    postcode: Yup.string(),
    streetAddress: Yup.string(),
  }),
  postalAddress: Yup.object().shape({
    country: Yup.object(),
    state: Yup.object(),
    manualEntry: Yup.boolean(),
    city: Yup.object().when('manualEntry', ([manualEntry]) => {
      if (manualEntry) return Yup.string();
      return Yup.object();
    }),
    postcode: Yup.string(),
    streetAddress: Yup.string(),
  }),
  hasOtherCapacity: Yup.boolean(),
  capacities: Yup.mixed().when('serviceProvidersSection', ([serviceProvidersSection]) => {
    return serviceProvidersSection
      ? Yup.array().typeError(REQUIRE_MESSAGE).min(1, REQUIRE_MESSAGE)
      : Yup.string().required(REQUIRE_MESSAGE);
  }),
  otherCapacity: Yup.string().when('hasOtherCapacity', ([hasOtherCapacity]) => {
    if (hasOtherCapacity) return Yup.string().required(REQUIRE_MESSAGE);
    return Yup.string().nullable();
  }),
  capacityNames: Yup.array(),
  isUseSameAsPhysicalAddress: Yup.boolean(),
  id: Yup.string(),
  serviceProvidersSection: Yup.boolean(),
  setAsInvestmentManager: Yup.boolean().nullable(),
  additionalCapacities: Yup.number()
    .when('setAsInvestmentManager', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
});

export const financialDetailsSchema = Yup.object().shape({
  investorUnit: Yup.number().required(),
  unitPrice: Yup.number().required(),
  bankAccounts: Yup.array().when(['isSaveDraft'], {
    is: true,
    then: (schema) => schema.of(Yup.object()),
    otherwise: (schema) =>
      schema.of(
        Yup.object().shape({
          nickName: Yup.string().required(REQUIRE_MESSAGE),
          bankName: Yup.string().required(REQUIRE_MESSAGE),
          accountName: Yup.string().required(REQUIRE_MESSAGE),
          bankStateBranchNumber: Yup.string().required(REQUIRE_MESSAGE),
          accountNumber: Yup.string().required(REQUIRE_MESSAGE),
        }),
      ),
  }),
});

export const teamFASchema = Yup.object().shape({
  internalTeams: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.array().of(Yup.string())
      : Yup.array().of(Yup.string()).min(1, REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
});

export const teamFMSchema = Yup.object().shape({
  clientTeams: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.array().of(Yup.string())
      : Yup.array().of(Yup.string()).min(1, REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
});
