import { Alert, AlertProps, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface ICustomAlertProps extends AlertProps {
  customIcon?: React.ReactNode;
  customColor?: string;
  customBackgroundColor?: string;
  textTheme?: 'default' | 'dark';
}

const CustomAlert: React.FunctionComponent<ICustomAlertProps> = ({
  children,
  sx,
  customColor,
  customIcon,
  customBackgroundColor,
  textTheme = 'default',
  ...rest
}) => {
  const theme = useTheme();

  const CUSTOM_BACKGROUND_COLOR_MAPPING = {
    success: theme.palette.success.light,
    info: theme.palette.info.light,
    error: theme.palette.error.light,
    warning: theme.palette.warning.light,
  };

  const CUSTOM_TEXT_COLOR_MAPPING = {
    success: theme.palette.success.main,
    info: theme.palette.info.main,
    error: theme.palette.error.main,
    warning: textTheme === 'dark' ? theme.palette.warning.dark : theme.palette.warning.main,
  };

  return (
    <Alert
      variant='standard'
      sx={{
        backgroundColor:
          customBackgroundColor || CUSTOM_BACKGROUND_COLOR_MAPPING[rest.severity || 'info'],
        borderRadius: '8px',
        padding: '8px 16px',
        alignItems: 'center',
        '& .MuiAlert-icon, .MuiAlert-message': {
          padding: 0,
        },
        '& .MuiAlert-icon': {
          mr: 1,
        },
        ...sx,
      }}
      icon={customIcon}
      {...rest}
    >
      <Typography
        sx={{
          color: customColor || CUSTOM_TEXT_COLOR_MAPPING[rest.severity || 'info'],
        }}
        variant='body3'
      >
        {children}
      </Typography>
    </Alert>
  );
};

export default CustomAlert;
