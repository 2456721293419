import { IssuePriceTab } from 'src/components/pages/administration-investor/investor/components/InvestorDetails/IssueForm/IssueUnitPriceTabs';
import { UnitPriceTabsEnum } from 'src/components/pages/administration-investor/investor/components/InvestorDetails/RedemptionForm/RedemptionUnitPriceTabs';
import { InvestorSourceOfFunds, REQUIRE_MESSAGE } from 'src/constants/common';
import { FundType } from 'src/constants/funds-management';
import { currencyStringToNumber } from 'src/utils/common';
import * as Yup from 'yup';
import { YupBase } from './common';

export const investorDetailsSchema = Yup.object().shape({
  fundType: Yup.number().nullable(),
  isSaveDraft: Yup.boolean(),
  isOptionalInvestorType: Yup.boolean(),
  investmentEntityId: Yup.string().required(REQUIRE_MESSAGE),
  fundId: Yup.string().required(REQUIRE_MESSAGE),
  unitClassId: Yup.string().required(REQUIRE_MESSAGE),
  mannualInvestorNumber: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  investorType: Yup.string()
    .when(['isSaveDraft', 'isOptionalInvestorType'], {
      is: (isSaveDraft: boolean, isOptionalInvestorType: boolean) =>
        !isSaveDraft && !isOptionalInvestorType,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  currentInvestmentAmount: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  netValue: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) =>
        schema
          .test('required', REQUIRE_MESSAGE, function (value) {
            if (this.parent.fundType === FundType.Partnership) return true;
            return !!value;
          })
          .nullable(),
    })
    .nullable(),
});

export const createInvestorTeamContactSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  email: YupBase.email,
  isAddTo: Yup.boolean(),
  // position: Yup.string().required(REQUIRE_MESSAGE),
  teamTypes: Yup.array()
    .when('isAddTo', {
      is: true,
      then: (schema) => schema.of(Yup.number()).min(1, REQUIRE_MESSAGE),
    })
    .nullable(),
});

export const createFundApplicationTeamSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  email: YupBase.email,
  isAddTo: Yup.boolean(),
  teamTypes: Yup.array()
    .when('isAddTo', {
      is: true,
      then: (schema) => schema.of(Yup.number()).min(1, REQUIRE_MESSAGE),
    })
    .nullable(),
  phoneNumber: Yup.string().required(REQUIRE_MESSAGE),
});

export const investmentDetailsSchema = Yup.object().shape({
  sourceOfFundsType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  distributionInstructionType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.number().nullable() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  sourceOfFundsTypeOther: Yup.string()
    .when('sourceOfFundsType', {
      is: InvestorSourceOfFunds.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  isSaveDraft: Yup.boolean(),
});

export const createTeamContactSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  email: YupBase.email,
  position: Yup.string().required(REQUIRE_MESSAGE),
  roleId: Yup.string().required(REQUIRE_MESSAGE),
  userType: Yup.number().required(REQUIRE_MESSAGE),
});

export const createInvestorIssueSchema = Yup.object().shape({
  isTrustInThirdCase: Yup.boolean(),
  isTrustInFirstCase: Yup.boolean(),
  transactionDate: YupBase.transactionDate.required(REQUIRE_MESSAGE),
  investmentAmount: Yup.number().typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE),
  unitPriceRounding: Yup.number().nullable(),
  priceTab: Yup.number().nullable(),
  entryUnitPrice: Yup.number()
    .test(
      'entryUnitPrice',
      'Decimal places do not comply with the fund rule. Please correct this in the unit registry before proceeding.',
      function (value) {
        // By pass the validation if selected price tab is mid price
        if (this.parent.priceTab === IssuePriceTab.CURRENT_UNIT_PRICE) return true;
        /**
         * If decimal places of value > unit price rounding rule, then show error
         * Else return true
         */
        const decimalPlaces = Number(value)?.toString()?.split('.')?.[1]?.length || 0;

        return decimalPlaces <= this.parent.unitPriceRounding;
      },
    )
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
  currentUnitPrice: Yup.number()
    .test(
      'currentUnitPrice',
      'Decimal places do not comply with the fund rule. Please correct this in the unit registry before proceeding.',
      function (value) {
        // By pass the validation if selected price tab is exit unit price
        if (this.parent.priceTab === IssuePriceTab.ENTRY_UNIT_PRICE) return true;
        /**
         * If decimal places of value > unit price rounding rule, then show error
         * Else return true
         */
        const midDecimalPlaces = Number(value)?.toString()?.split('.')?.[1]?.length || 0;

        return midDecimalPlaces <= this.parent.unitPriceRounding;
      },
    )
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
  partiallyPaidUnits: Yup.boolean()
    .when('isTrustInThirdCase', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  capitalCallAmount: Yup.string().when('isTrustInFirstCase', {
    is: false,
    then: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
});

export const createInvestorRedemptionSchema = Yup.object().shape({
  option: Yup.number().required(REQUIRE_MESSAGE),
  transactionDate: YupBase.transactionDate.required(REQUIRE_MESSAGE),
  unitPriceRounding: Yup.number().nullable(),
  selectedPrice: Yup.number().nullable(),
  entryUnitPrice: Yup.number()
    .test(
      'entryUnitPrice',
      'Decimal places do not comply with the fund rule. Please correct this in the unit registry before proceeding.',
      function (value) {
        // By pass the validation if selected price tab is mid price
        if (this.parent.selectedPrice === UnitPriceTabsEnum.CURRENT_UNIT_PRICE) return true;
        /**
         * If decimal places of value > unit price rounding rule, then show error
         * Else return true
         */
        const decimalPlaces = Number(value)?.toString()?.split('.')?.[1]?.length || 0;

        return decimalPlaces <= this.parent.unitPriceRounding;
      },
    )
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
  currentUnitPrice: Yup.number()
    .test(
      'currentUnitPrice',
      'Decimal places do not comply with the fund rule. Please correct this in the unit registry before proceeding.',
      function (value) {
        // By pass the validation if selected price tab is exit unit price
        if (this.parent.selectedPrice === UnitPriceTabsEnum.EXIT_UNIT_PRICE) return true;
        /**
         * If decimal places of value > unit price rounding rule, then show error
         * Else return true
         */
        const midDecimalPlaces = Number(value)?.toString()?.split('.')?.[1]?.length || 0;

        return midDecimalPlaces <= this.parent.unitPriceRounding;
      },
    )
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
  amount: Yup.string().required(REQUIRE_MESSAGE),
  numberOfUnits: Yup.string().required(REQUIRE_MESSAGE),
});

export const createOffmarketTransferSchema = Yup.object().shape({
  transactionDate: YupBase.transactionDate.required(REQUIRE_MESSAGE),
  transferAmount: Yup.string().required(REQUIRE_MESSAGE),
  investorId: Yup.string().required(REQUIRE_MESSAGE),
});

export const createRecordPaymentSchema = Yup.object().shape({
  transactionDate: YupBase.transactionDate.required(REQUIRE_MESSAGE),
  paymentAmount: Yup.string().required(REQUIRE_MESSAGE),
  balance: Yup.string().test(
    'balance',
    'The balance cannot exceed the unpaid amount.',
    (value?: string) => {
      const numberValue = currencyStringToNumber(value || '');
      return numberValue >= 0 || !value;
    },
  ),
});

export const generateStatementSchema = Yup.object().shape({
  transactionDate: YupBase.transactionDate.required(REQUIRE_MESSAGE),
  option: Yup.number().required(REQUIRE_MESSAGE),
  publishOption: Yup.number().when(['isPreview'], {
    is: true,
    then: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
});

export const ceaseSchema = Yup.object().shape({
  reason: Yup.string().required(REQUIRE_MESSAGE),
});
