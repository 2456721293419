/* eslint-disable no-debugger */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { get, isArray, isEmpty, omit, pickBy } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement, IStep } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import {
  Amit,
  FUND_STATUS,
  FundType,
  InvestorRelationsContact,
  Mit,
  SubType,
  TeamUserType,
  TrustType,
  contactDetailsDefaultValues,
  financialDetailsDefaultValues,
  fundOverviewAndBrandingDefaultValues,
  fundTrusteeAndServiceProvidersDefaultValues,
  fundsDetailDefaultValues,
  signatoryContactDefaultValues,
  teamDefaultValues,
} from 'src/constants/funds-management';
import { showToast } from 'src/helpers/toast';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo, IDirtyForms } from 'src/modules/common/type';
import {
  useCheckFundCode,
  useCreateFund,
  useGetFundById,
  useGetFundContacts,
  useGetFundTeamByUserType,
  useGetSignatoryContacts,
  useGetUnitClassList,
  useUpsertFinancialDetails,
  useUpsertFundContactDetails,
  useUpsertFundDetails,
  useUpsertFundTeam,
  useUpsertOverviewAndBranding,
  useUpsertSignatoryContacts,
  useUpsertTrusteeGP,
} from 'src/modules/funds-management/hooks';
import {
  IFinancialDetailsParams,
  IFundContactDetailParams,
  IFundDetailParams,
  IFundOverviewAndBrading,
  ISignatoryContact,
  ISignatoryContactParams,
  ISignatoryContactsForm,
  ITrusteeAndServiceProvidersFormFields,
  ITrusteeGPParams,
  IUpsertFundForm,
  IUpsertFundTeamParams,
  ServiceProviderTypeEnum,
} from 'src/modules/funds-management/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { handleErrorFromServer, removeSpacesInString } from 'src/utils/common';
import { dayjs, getEndOfDateUTC, utcToLocalTimezone } from 'src/utils/time';
import {
  contactDetailsSchema,
  financialDetailsSchema,
  fundOverviewAndBradingSchema,
  fundTrusteeAndServiceProvidersSchema,
  fundsDetailsSchema,
  signatoryContactSchema,
  teamFASchema,
  teamFMSchema,
} from 'src/validations/funds-management';
import ApprovedSignatories from './ApprovedSignatories';
import ContactDetails from './ContactDetails';
import FinancialDetails from './FinancialDetails';
import FundDetails from './FundDetails';
import FundOverviewAndBranding from './FundOverviewAndBranding';
import Teams from './Teams';
import TrusteeAndServiceProviders from './TrusteeAndServiceProviders';
import UnitClass from './UnitClass';
interface IUpsertFundsFormProps extends IUpsertFundForm {
  onClose: (isOpen: boolean) => void;
  id?: string;
  status?: number;
  step?: number;
}

export enum UPSERT_FUND_STEPS {
  FUND_DETAILS,
  CONTACT_DETAILS,
  APPROVED_SIGNATORIES,
  FUND_OVERVIEW_AND_BRANDING,
  TRUSTEE_AND_SERVICE_PROVIDERS,
  FINANCIAL_DETAILS,
  UNIT_CLASS,
  TEAM,
}

const generateDirtyStep = (step: number) => `dirty_step_${step}`;

const UpsertFundsForm: FC<IUpsertFundsFormProps> = ({
  onClose,
  id,
  isEditMode,
  isViewMode,
  status,
  step,
}) => {
  const navigate = useNavigateWithParams();
  const stepperRef = useRef<ICustomStepperElement>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(UPSERT_FUND_STEPS.FUND_DETAILS);
  const [fundId, setFundId] = useState<string>('');
  const [dirtyForms, setDirtyForms] = useState<IDirtyForms>({});

  const {
    mutate: upsertFundDetailsMutate,
    isLoading: upsertFundDetailsLoading,
    mutateAsync: upsertFundDetailsMutateAsync,
  } = useUpsertFundDetails();
  const {
    mutate: upsertFundContactDetailsMutate,
    isLoading: upsertFundContactDetailsLoading,
    mutateAsync: upsertFundContactDetailsMutateAsync,
  } = useUpsertFundContactDetails();
  const {
    mutate: upsertSignatoryContactsMutate,
    isLoading: upsertSignatoryContactsLoading,
    mutateAsync: upsertSignatoryContactsMutateAsync,
  } = useUpsertSignatoryContacts();
  const {
    mutate: upsertFinancialDetailsMutate,
    isLoading: upsertFinancialDetailsLoading,
    mutateAsync: upsertFinancialDetailsMutateAsync,
  } = useUpsertFinancialDetails();
  const { mutate: checkFundCodeMutate, isLoading: checkFundCodeLoading } = useCheckFundCode();
  const {
    mutate: upsertTrusteeGPMutate,
    isLoading: upsertTrusteeLoading,
    mutateAsync: upsertTrusteeGPMutateAsync,
  } = useUpsertTrusteeGP();
  const {
    mutate: upsertOverviewAndBrandingMutate,
    isLoading: upsertOverviewAndBrandingLoading,
    mutateAsync: upsertOverviewAndBrandingMutateAsync,
  } = useUpsertOverviewAndBranding();
  const {
    mutate: upsertFundTeamMutate,
    isLoading: upsertFundTeamLoading,
    mutateAsync: upsertFundTeamMutateAsync,
  } = useUpsertFundTeam();
  const {
    mutate: createFundMutate,
    isLoading: createFundLoading,
    mutateAsync: createFundMutateAsync,
  } = useCreateFund();

  const {
    data: { items: unitClassList = [] },
  } = useGetUnitClassList(fundId, currentStep === UPSERT_FUND_STEPS.UNIT_CLASS);
  const { data: fundDetail, refetch: refetchFundDetail } = useGetFundById(
    fundId,
    currentStep === UPSERT_FUND_STEPS.UNIT_CLASS
      ? UPSERT_FUND_STEPS.FINANCIAL_DETAILS
      : currentStep,
  );
  const { data: { detail: fundDetailStep0 } = {} } = useGetFundById(
    fundId,
    UPSERT_FUND_STEPS.FUND_DETAILS,
  );

  const { data: currentContacts } = useGetSignatoryContacts(
    fundId,
    currentStep === UPSERT_FUND_STEPS.APPROVED_SIGNATORIES,
  );
  const { data: fundContacts } = useGetFundContacts(
    fundId,
    currentStep === UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING,
  );
  const { data: teamUsers } = useGetFundTeamByUserType(
    fundId,
    currentStep === UPSERT_FUND_STEPS.TEAM,
  );
  const { data: currentUser } = useGetUserInfo();
  const { selectedClients, overarchingFilter } = useFundPortalContext();

  const fundDetailsForm = useForm({
    defaultValues: fundsDetailDefaultValues,
    resolver: yupResolver(fundsDetailsSchema),
  });

  const contactDetailsForm = useForm({
    defaultValues: contactDetailsDefaultValues,
    resolver: yupResolver(contactDetailsSchema),
  });

  const approvedSignatoriesForm = useForm({
    defaultValues: signatoryContactDefaultValues,
    resolver: yupResolver(signatoryContactSchema),
  });

  const fundOverviewAndBradingForm = useForm({
    defaultValues: fundOverviewAndBrandingDefaultValues,
    resolver: yupResolver(fundOverviewAndBradingSchema),
  });

  const trusteeAndServiceProvidersForm = useForm<ITrusteeAndServiceProvidersFormFields>({
    defaultValues: fundTrusteeAndServiceProvidersDefaultValues,
    resolver: yupResolver(fundTrusteeAndServiceProvidersSchema),
  });

  const financialDetailsForm = useForm({
    defaultValues: financialDetailsDefaultValues,
    resolver: yupResolver(financialDetailsSchema),
  });

  const teamForm = useForm({
    defaultValues: teamDefaultValues,
    resolver: yupResolver(
      currentUser?.userType === TeamUserType.FAUser ? teamFASchema : teamFMSchema,
    ),
  });

  const watchFundCode = fundDetailsForm.watch('code');
  const watchFundName = fundDetailsForm.watch('name');
  const unitPriceRounding = financialDetailsForm.watch('unitPrice');
  const investorUnitRounding = financialDetailsForm.watch('investorUnit');
  const isCreateMode = !isEditMode && !isViewMode;
  const isLoading: boolean =
    upsertFundDetailsLoading ||
    upsertFundContactDetailsLoading ||
    upsertSignatoryContactsLoading ||
    upsertFinancialDetailsLoading ||
    upsertTrusteeLoading ||
    upsertOverviewAndBrandingLoading ||
    upsertFundTeamLoading ||
    checkFundCodeLoading ||
    createFundLoading;

  const fundStatus = useMemo(() => {
    const fundStatus = Number.isInteger(status) ? status : fundDetailStep0?.status;

    return {
      isDraft: fundStatus === FUND_STATUS.Draft,
      isPending: fundStatus === FUND_STATUS.Pending,
      isActive: fundStatus === FUND_STATUS.Active,
    };
  }, [status, fundDetailStep0?.status]);

  useEffect(() => {
    if (isCreateMode && !fundDetail?.clientId) {
      if (typeof selectedClients === 'string') {
        fundDetailsForm.setValue('clientId', selectedClients);
      } else if (isArray(selectedClients)) {
        const selectedClientId = isEmpty(selectedClients)
          ? overarchingFilter?.clients?.[0]?.id
          : selectedClients?.[0];
        fundDetailsForm.setValue('clientId', selectedClientId);
      }
    }
  }, [selectedClients]);

  useEffect(() => {
    setDirtyForms((prev) => ({
      ...prev,
      [generateDirtyStep(UPSERT_FUND_STEPS.FUND_DETAILS)]: fundDetailsForm.formState.isDirty,
      [generateDirtyStep(UPSERT_FUND_STEPS.CONTACT_DETAILS)]: contactDetailsForm.formState.isDirty,
      [generateDirtyStep(UPSERT_FUND_STEPS.APPROVED_SIGNATORIES)]:
        approvedSignatoriesForm.formState.isDirty,
      [generateDirtyStep(UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING)]:
        fundOverviewAndBradingForm.formState.isDirty,
      [generateDirtyStep(UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS)]: true,
      [generateDirtyStep(UPSERT_FUND_STEPS.FINANCIAL_DETAILS)]:
        financialDetailsForm.formState.isDirty,
      [generateDirtyStep(UPSERT_FUND_STEPS.TEAM)]: teamForm.formState.isDirty,
    }));
  }, [
    fundDetailsForm.formState.isDirty,
    contactDetailsForm.formState.isDirty,
    approvedSignatoriesForm.formState.isDirty,
    fundOverviewAndBradingForm.formState.isDirty,
    trusteeAndServiceProvidersForm.formState.isDirty,
    financialDetailsForm.formState.isDirty,
    teamForm.formState.isDirty,
  ]);

  useEffect(() => {
    id && setFundId(id);
  }, [id]);

  useEffect(() => {
    handleFillData();
  }, [fundDetail, currentContacts, currentStep]);

  useEffect(() => {
    if (isCreateMode && !isEmpty(currentContacts)) {
      approvedSignatoriesForm.reset({ signatoryContacts: currentContacts });
    }
  }, [currentContacts]);

  useEffect(() => {
    if (step && step >= 0) {
      stepperRef.current?.setCurrentStep(step);
    }
  }, [step]);

  const handleFillData = () => {
    if (fundDetail) {
      const clientId = fundDetail.clientId;
      switch (currentStep) {
        case UPSERT_FUND_STEPS.FUND_DETAILS: {
          if (!isEmpty(fundDetail?.detail)) {
            const {
              commencementDate,
              mit,
              amit,
              trustType,
              subType,
              initialCloseDate,
              lateInitialCloseDate,
            } = fundDetail?.detail || {};

            console.log('initialCloseDate', initialCloseDate);

            fundDetailsForm.reset({
              ...fundDetail.detail,
              clientId,
              commencementDate: commencementDate ? dayjs(commencementDate) : commencementDate,
              trustType: trustType !== null ? trustType : TrustType.Discretionary,
              subType: subType !== null ? subType : SubType.VCType,
              mit: mit ? Mit.Yes : Mit.No,
              amit: amit ? Amit.Yes : Amit.No,
              initialCloseDate: initialCloseDate ? utcToLocalTimezone(initialCloseDate, '') : null,
              lateInitialCloseDate: lateInitialCloseDate
                ? utcToLocalTimezone(lateInitialCloseDate, '')
                : null,
            });
          }

          break;
        }
        case UPSERT_FUND_STEPS.CONTACT_DETAILS: {
          handleFillContactDetails(fundDetail?.contactDetails);
          break;
        }
        case UPSERT_FUND_STEPS.APPROVED_SIGNATORIES: {
          if (fundDetail.approvedSignatory) {
            const { signatoryContactIds, signatoryPersons } = fundDetail.approvedSignatory;
            let signatoryContacts = (currentContacts || []).map((item: ISignatoryContact) => {
              const findSelectedItemIndex = (signatoryContactIds || []).findIndex(
                (f: string) => f === item.id,
              );
              return {
                ...item,
                isSelected: findSelectedItemIndex > -1,
              };
            });
            signatoryContacts = signatoryContacts.concat(
              (signatoryPersons || []).map((item: ISignatoryContact) => ({
                ...item,
                isNew: true,
                isSelected: true,
              })),
            );
            approvedSignatoriesForm.reset({ signatoryContacts, isSaveDraft: false });
          }
          break;
        }

        case UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING: {
          if (!isEmpty(fundDetail?.branding)) {
            const formData = { ...fundDetail.branding };
            const { brandingDetails, signatoryPerson } = fundDetail.branding;
            formData.brandingDetails = (brandingDetails || []).map((item: any) => ({
              ...item,
              imgUrl: item.link,
            }));
            if (!isEmpty(signatoryPerson)) {
              formData.fundContactId = signatoryPerson.id;
            }
            fundOverviewAndBradingForm.reset(formData);
          }
          break;
        }
        case UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS: {
          const { trustees } = fundDetail;
          if (!isEmpty(trustees)) {
            handleFillTrusteesFormData(trustees);
          }
          break;
        }
        case UPSERT_FUND_STEPS.FINANCIAL_DETAILS:
        case UPSERT_FUND_STEPS.UNIT_CLASS: {
          !isEmpty(fundDetail?.financialDetails) &&
            financialDetailsForm.reset(fundDetail.financialDetails);
          break;
        }
        default:
          break;
      }
      clientId && trusteeAndServiceProvidersForm.setValue('clientId', clientId);
    }
  };

  const handleFillContactDetails = (data: any) => {
    if (!isEmpty(data) && !isEmpty(data.contacts)) {
      const investorContact = data.investorContact || {};
      if (isEmpty(investorContact)) {
        investorContact.option = InvestorRelationsContact.Individual;
      } else if (investorContact.option === InvestorRelationsContact.Organisation) {
        investorContact.firstName = investorContact.organisationName;
        investorContact.lastName = investorContact.contactName;
      }
      contactDetailsForm.reset({
        ...data,
        investorContact,
      });
    }
  };

  const handleFillTrusteesFormData = (trustees: any[]) => {
    const trusteeFormData = (trustees || []).reduce((prev, item) => {
      const result = { ...prev, clientId: fundDetail?.clientId };
      switch (item?.type) {
        case ServiceProviderTypeEnum.TrusteeGpDetails:
          result.trustee = {
            capacity: (item?.capacities || [])[0],
            existing: item.id,
            newExisting: [],
            type: ServiceProviderTypeEnum.TrusteeGpDetails,
          };
          break;
        case ServiceProviderTypeEnum.InvestmentManager:
          result.manager = {
            capacity: (item?.capacities || [])[0],
            existing: item.id,
            newExisting: [],
            type: ServiceProviderTypeEnum.InvestmentManager,
          };
          break;
        case ServiceProviderTypeEnum.ServiceProviders:
          result.providers = {
            capacity: item?.capacities || [],
            existing: [...(result.providers?.existing || []), item?.id],
            newExisting: [],
            type: ServiceProviderTypeEnum.ServiceProviders,
          };
          break;
        default:
          break;
      }

      return result;
    }, {});

    trusteeAndServiceProvidersForm.reset(trusteeFormData);
  };

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleShowAlert = ({ title, description, isError }: IAlertInfo) => {
    setAlertInfo({ title, description, isError });
    alertRef.current?.open();
  };

  const handleSave = (stepSelected: number, mode: string) => {
    // Clicking "Next" when editing an item that is not in the draft state, then need to move on to the next step.
    const isGoNextStep = isEditMode && !fundStatus.isDraft && mode === SUBMIT_FORM_ACTIONS.DRAFT;
    if (isViewMode || isGoNextStep) {
      return handleGoToNextStep();
    }
    switch (stepSelected) {
      case UPSERT_FUND_STEPS.FUND_DETAILS:
        fundDetailsForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        fundDetailsForm.handleSubmit(() => onSubmitFundsDetail(mode))();
        break;
      case UPSERT_FUND_STEPS.CONTACT_DETAILS:
        contactDetailsForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        contactDetailsForm.handleSubmit(() => onSubmitContactDetails(mode))();
        break;
      case UPSERT_FUND_STEPS.APPROVED_SIGNATORIES:
        approvedSignatoriesForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        approvedSignatoriesForm.handleSubmit(() => onSubmitApprovedSignatory(mode))();
        break;
      case UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING:
        fundOverviewAndBradingForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        fundOverviewAndBradingForm.handleSubmit(() => onSubmitOverviewAndBranding(mode))();
        break;
      case UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS:
        trusteeAndServiceProvidersForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        trusteeAndServiceProvidersForm.handleSubmit(() =>
          onSubmitTrusteeAndServiceProviders(mode),
        )();
        break;
      case UPSERT_FUND_STEPS.FINANCIAL_DETAILS:
        financialDetailsForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        financialDetailsForm.handleSubmit(() => onSubmitIFinancialDetails(mode))();
        break;
      case UPSERT_FUND_STEPS.UNIT_CLASS:
        financialDetailsForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        financialDetailsForm.handleSubmit(() => onSubmitUnitClass())();
        break;
      case UPSERT_FUND_STEPS.TEAM:
        teamForm.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
        teamForm.handleSubmit(() => onSubmitFundTeam(mode))();
        break;
      default:
        break;
    }
  };

  const handleInvalidFundCode = (newCode: string) => {
    const errMessage = `The ${watchFundCode} code is duplicated, a new code ${newCode} has been generated.`;
    showToast(errMessage, 'error');
    fundDetailsForm.setValue('code', newCode);
    setCurrentStep(UPSERT_FUND_STEPS.FUND_DETAILS);
    stepperRef.current?.setCurrentStep(UPSERT_FUND_STEPS.FUND_DETAILS);
  };

  const onSubmitUnitClass = () => {
    if (!unitClassList?.length) {
      handleShowAlert({
        isError: true,
        description: 'At least 1 unit class is required. Please create a new unit class.',
        title: '',
      });
      return;
    }

    if (isEditMode && !fundStatus.isDraft) {
      handleFundSubmitAllSection();
      return;
    }

    handleGoToNextStep();
  };

  const onCheckFundCode = (callback: () => void | Promise<any>) => {
    checkFundCodeMutate(
      {
        params: {
          code: watchFundCode,
          id: fundId,
        },
        fundId,
      },
      {
        onSuccess: (data) => {
          if (!data.isValid && data.code) {
            handleInvalidFundCode(data.code);
          } else {
            callback();
          }
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitFundTeam = (mode: string, isAsync?: boolean) => {
    const params: IUpsertFundTeamParams = teamForm.getValues();
    const submitData = {
      params,
      fundId,
    };
    if (isAsync) return upsertFundTeamMutateAsync(submitData);
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertFundTeamMutate(submitData, {
      onSuccess: () => {
        if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
          handleSuccess(mode);
        } else {
          onCheckFundCode(handleFundSubmit);
        }
      },
      onError: handleErrorFromServer,
    });
  };

  const onSubmitFundsDetail = (mode: string, isAsync?: boolean) => {
    let data: IFundDetailParams = fundDetailsForm.getValues();
    if (dayjs(data.commencementDate).isValid()) {
      data.commencementDate = dayjs(data.commencementDate).utc(true).format();
    } else {
      data = omit(data, ['commencementDate']);
    }
    if (fundId) {
      data.id = fundId;
    }
    data.amit = data.amit === Amit.Yes;
    data.mit = data.mit === Mit.Yes;
    data.offerType = Number(data.offerType);
    data.abn = removeSpacesInString(data.abn);
    data.tfn = removeSpacesInString(data.tfn);
    data.ownershipPercentageLimit = data.ownershipPercentageLimit || null;
    data.initialCloseDate = data.initialCloseDate ? getEndOfDateUTC(data.initialCloseDate) : null;
    data.lateInitialCloseDate = data.lateInitialCloseDate
      ? getEndOfDateUTC(data.lateInitialCloseDate)
      : null;

    if (isAsync) return upsertFundDetailsMutateAsync(data, { onError: handleErrorFromServer });
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertFundDetailsMutate(data, {
      onSuccess: (id: string) => {
        const isSubmitAgainWhenExistingId = !fundId && id && !data?.isSaveDraft; // Need to call the api once to get the ID and then call again to submit with id when clicking Next
        id && setFundId(id);
        if (isSubmitAgainWhenExistingId) {
          upsertFundDetailsMutate(
            { ...data, id },
            {
              onSuccess: () => {
                handleSuccess(mode);
                refetchFundDetail();
              },
              onError: handleErrorFromServer,
            },
          );
        } else {
          handleSuccess(mode);
          refetchFundDetail();
        }
      },
      onError: handleErrorFromServer,
    });
  };

  const onSubmitContactDetails = (mode: string, isAsync?: boolean) => {
    let params: IFundContactDetailParams = contactDetailsForm.getValues();
    if (params.investorContact?.email) {
      if (params.investorContact?.option === InvestorRelationsContact.Organisation) {
        params.investorContact.organisationName = params.investorContact?.firstName;
        params.investorContact.contactName = params.investorContact?.lastName;
        params.investorContact = omit(params.investorContact, ['firstName', 'lastName']);
      } else {
        params.investorContact = omit(params.investorContact, ['organisationName', 'contactName']);
      }
    } else {
      params = omit(params, ['investorContact']);
    }

    if (isAsync)
      return upsertFundContactDetailsMutateAsync({
        params,
        fundId,
      });

    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertFundContactDetailsMutate(
      {
        params,
        fundId,
      },
      {
        onSuccess: (data) => {
          handleFillContactDetails(data.contactDetails);
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitApprovedSignatory = (mode: string, isAsync?: boolean) => {
    const formData: ISignatoryContactsForm = approvedSignatoriesForm.getValues();
    const selectedContacts = (formData?.signatoryContacts || []).filter((x) => x.isSelected);
    const params: ISignatoryContactParams = {
      isSaveDraft: formData.isSaveDraft,
    };
    params.signatoryContactIds = selectedContacts
      .filter((x) => !x.isNew)
      .map((item) => item.id as string);

    params.signatoryPersons = selectedContacts
      .filter((x) => x.isNew)
      .map((item) => omit(item, ['isNew', 'isSelected']));
    if (isAsync)
      return upsertSignatoryContactsMutateAsync({
        params,
        fundId,
      });
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertSignatoryContactsMutate(
      {
        params,
        fundId,
      },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitTrusteeAndServiceProviders = (mode: string, isAsync?: boolean) => {
    const data: ITrusteeAndServiceProvidersFormFields = trusteeAndServiceProvidersForm.getValues();
    const { ...existingData } = data;
    const params: ITrusteeGPParams = {
      isSaveDraft: mode === SUBMIT_FORM_ACTIONS.DRAFT,
      serviceProviders: [],
    };

    Object.entries(existingData).forEach(([key, { existing, newExisting, capacity }]: any) => {
      const typeObj = {
        trustee: ServiceProviderTypeEnum.TrusteeGpDetails,
        manager: ServiceProviderTypeEnum.InvestmentManager,
        providers: ServiceProviderTypeEnum.ServiceProviders,
      };
      const type = get(typeObj, key);

      if (!isEmpty(existing)) {
        existing instanceof Array
          ? (params.serviceProviders = [
              ...params.serviceProviders,
              {
                ids: existing,
                type,
                capacities: capacity instanceof Array ? capacity : [capacity],
              },
            ])
          : (params.serviceProviders = [
              ...params.serviceProviders,
              {
                ids: [existing],
                type,
                capacities: capacity instanceof Array ? capacity : [capacity],
              },
            ]);
      }

      if (newExisting?.length) {
        const item: any = {
          ids: newExisting?.map((it: any) => it?.id),
          type,
          capacities: newExisting?.reduce((prev: number[], current: any) => {
            const results =
              current?.capacityIds instanceof Array
                ? [...prev, ...(current?.capacityIds?.map((it: any) => Number(it)) || [])]
                : [...prev, Number(current?.capacityIds)];
            return [...new Set(results)];
          }, []),
        };
        const existingServiceProvider = params.serviceProviders.find((item) => item.type === type);

        if (existingServiceProvider) {
          existingServiceProvider.capacities = [
            ...new Set([...item.capacities, ...existingServiceProvider.capacities]),
          ] as number[];
          existingServiceProvider.ids = [...new Set([...existingServiceProvider.ids, ...item.ids])];
        } else {
          params.serviceProviders = [...params.serviceProviders, item];
        }
      }
    });

    if (isAsync)
      return upsertTrusteeGPMutateAsync({
        params,
        id: fundId,
      });
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertTrusteeGPMutate(
      { params, id: fundId },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitOverviewAndBranding = (mode: string, isAsync?: boolean) => {
    const formData: IFundOverviewAndBrading = fundOverviewAndBradingForm.getValues();
    let params = pickBy(formData);
    params = omit(params, ['documentType', 'otherDescription']);

    params.brandingDetails = params.brandingDetails?.filter((f) => f.isNew);
    params.documents = params.documents?.filter((f) => f.isNew);

    if (!isEmpty(params.signatoryPerson)) {
      params = omit(params, ['fundContactId']);
      get(params, 'signatoryPerson.id', '').includes('new_') &&
        (params.signatoryPerson = omit(params.signatoryPerson, ['id']));
    } else {
      params = omit(params, ['signatoryPerson']);
    }

    if (isAsync)
      return upsertOverviewAndBrandingMutateAsync({
        params,
        fundId,
      });
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertOverviewAndBrandingMutate(
      { params, fundId },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitIFinancialDetails = (mode: string, isAsync?: boolean) => {
    const params: IFinancialDetailsParams = financialDetailsForm.getValues();
    if (isAsync)
      return upsertFinancialDetailsMutateAsync({
        params,
        id: fundId,
      });
    if (isEditMode && !fundStatus.isDraft) return handleFundSubmitAllSection();
    upsertFinancialDetailsMutate(
      {
        params,
        id: fundId,
      },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSuccess = (mode?: string) => {
    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleShowAlert({
        title: 'Draft saved!',
        description: `You can come back and resume at any time.`,
        isError: false,
      });
      refetchFundDetail();
    } else {
      handleGoToNextStep();
    }
  };

  const handleStepChanged = (stepChanged: number) => {
    setCurrentStep(stepChanged);
  };

  const handleSetErrorSteps = (steps: { step: number; isValid: boolean }[]) => {
    let newErrorSteps = [...errorSteps];
    steps.forEach(({ step, isValid }) => {
      if (isValid) {
        newErrorSteps = newErrorSteps.filter((item) => item !== step);
      } else {
        newErrorSteps.push(step);
      }
    });
    setErrorSteps([...new Set([...newErrorSteps])]);
  };

  const handleClickStep = () => {
    if (isViewMode) return;

    const checkedSteps = [
      { step: UPSERT_FUND_STEPS.FUND_DETAILS, isValid: fundDetailsForm.formState.isValid },
      { step: UPSERT_FUND_STEPS.CONTACT_DETAILS, isValid: contactDetailsForm.formState.isValid },
      {
        step: UPSERT_FUND_STEPS.APPROVED_SIGNATORIES,
        isValid: approvedSignatoriesForm.formState.isValid,
      },
      {
        step: UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING,
        isValid: fundOverviewAndBradingForm.formState.isValid,
      },
      {
        step: UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS,
        isValid: trusteeAndServiceProvidersForm.formState.isValid,
      },
      {
        step: UPSERT_FUND_STEPS.FINANCIAL_DETAILS,
        isValid: financialDetailsForm.formState.isValid,
      },
      {
        step: UPSERT_FUND_STEPS.TEAM,
        isValid: teamForm.formState.isValid,
      },
    ];

    handleSetErrorSteps(checkedSteps);
    fundDetailsForm.trigger();
    contactDetailsForm.trigger();
    approvedSignatoriesForm.trigger();
    fundOverviewAndBradingForm.trigger();
    trusteeAndServiceProvidersForm.trigger();
    financialDetailsForm.trigger();
    teamForm.trigger();
  };

  const handleFundSubmitAllSection = () => {
    const formSections = [
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.FUND_DETAILS)],
        mutate: () => onSubmitFundsDetail(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.CONTACT_DETAILS)],
        mutate: () => onSubmitContactDetails(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.APPROVED_SIGNATORIES)],
        mutate: () => onSubmitApprovedSignatory(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING)],
        mutate: () => onSubmitOverviewAndBranding(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS)],
        mutate: () => onSubmitTrusteeAndServiceProviders(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.FINANCIAL_DETAILS)],
        mutate: () => onSubmitIFinancialDetails(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
      {
        canSubmit: dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.TEAM)],
        mutate: () => onSubmitFundTeam(SUBMIT_FORM_ACTIONS.SUBMIT, true),
      },
    ];

    const canSubmitForms = formSections.filter((item) => item.canSubmit);

    if (isEmpty(canSubmitForms)) {
      onClose(false);
    } else {
      if (dirtyForms[generateDirtyStep(UPSERT_FUND_STEPS.FUND_DETAILS)]) {
        onCheckFundCode(() => onPerformSubmitAll(canSubmitForms));
      } else {
        onPerformSubmitAll(canSubmitForms);
      }
    }
  };

  const onPerformSubmitAll = (canSubmitForms: { canSubmit: boolean; mutate: () => void }[]) => {
    Promise.all([...canSubmitForms.map((item) => item.mutate())])
      .then(() => handleFundSubmitSuccess())
      .catch((error) => {
        console.log('Submit all error: ', error);
      });
  };

  const handleFundSubmit = (isAsync?: boolean) => {
    const mutateOptions = {
      onSuccess: handleFundSubmitSuccess,
      onError: handleErrorFromServer,
    };

    if (isAsync) return createFundMutateAsync(fundId, mutateOptions);
    createFundMutate(fundId, mutateOptions);
  };

  const handleFundSubmitSuccess = () => {
    handleShowAlert({
      title: 'You did it!',
      description: isEditMode
        ? `Fund ${watchFundName} has been updated successfully.`
        : `Fund ${watchFundName} has been created successfully.`,
      isError: false,
    });
  };

  const getDefaultStepProps = (step: number) => ({
    isViewMode,
    isEditMode,
    isDraft: fundStatus.isDraft,
    isLoading,
    onSubmit: () => handleSave(step, SUBMIT_FORM_ACTIONS.SUBMIT),
    onSave: () => handleSave(step, SUBMIT_FORM_ACTIONS.DRAFT),
  });

  const STEPS: IStep[] = [
    {
      key: UPSERT_FUND_STEPS.FUND_DETAILS,
      label: 'Fund Details',
      content: (
        <FormProvider {...fundDetailsForm}>
          <FormSection
            title='Fund Details'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.FUND_DETAILS)}
          >
            <FundDetails
              {...getDefaultStepProps(UPSERT_FUND_STEPS.FUND_DETAILS)}
              currentUser={currentUser}
              isViewMode={isViewMode}
              isEditMode={isEditMode}
              isShowWarningWhenChangeSpecialField={!!fundDetail?.clientId}
              onChangeSpecialFieldConfirm={() => {
                fundDetailsForm.setValue('isSaveDraft', true);
                onSubmitFundsDetail(SUBMIT_FORM_ACTIONS.DRAFT, true);
              }}
              hasActiveInvestor={fundDetail?.hasActiveInvestor}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.CONTACT_DETAILS,
      label: 'Contact Details',
      content: (
        <FormProvider {...contactDetailsForm}>
          <FormSection
            title='Contact Details'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.CONTACT_DETAILS)}
          >
            <ContactDetails isViewMode={isViewMode} isEditMode={isEditMode} />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.APPROVED_SIGNATORIES,
      label: 'Approved Signatories',
      content: (
        <FormProvider {...approvedSignatoriesForm}>
          <FormSection
            title='Approved Signatories'
            description='Please list all persons who are authorised to give Proper Instructions for the Fund'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.APPROVED_SIGNATORIES)}
          >
            <ApprovedSignatories isViewMode={isViewMode} isEditMode={isEditMode} />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING,
      label: 'Fund Overview & Branding',
      content: (
        <FormProvider {...fundOverviewAndBradingForm}>
          <FormSection
            title='Fund Overview & Branding'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.FUND_OVERVIEW_AND_BRANDING)}
          >
            <FundOverviewAndBranding
              fundContacts={fundContacts}
              isViewMode={isViewMode}
              isEditMode={isEditMode}
              fundId={fundId}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS,
      label: 'Trustee/GP & Service Providers',
      content: (
        <FormProvider {...trusteeAndServiceProvidersForm}>
          <FormSection
            title='Trustee/GP & Service Providers'
            fullWidth
            {...getDefaultStepProps(UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS)}
          >
            <TrusteeAndServiceProviders
              {...getDefaultStepProps(UPSERT_FUND_STEPS.TRUSTEE_AND_SERVICE_PROVIDERS)}
              isViewMode={isViewMode}
              isEditMode={isEditMode}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.FINANCIAL_DETAILS,
      label: 'Financial Details',
      content: (
        <FormProvider {...financialDetailsForm}>
          <FormSection
            title='Financial Details'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.FINANCIAL_DETAILS)}
          >
            <FinancialDetails
              isViewMode={isViewMode}
              isEditMode={isEditMode}
              isDraft={getDefaultStepProps(UPSERT_FUND_STEPS.FINANCIAL_DETAILS).isDraft}
              fundDetail={fundDetailStep0}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.UNIT_CLASS,
      label: fundDetailStep0?.type === FundType.Trust ? 'Unit Class' : 'Financial Rules',
      content: (
        <FormSection
          title={fundDetailStep0?.type === FundType.Trust ? 'Unit Class' : 'Financial Rules'}
          fullWidth
          {...getDefaultStepProps(UPSERT_FUND_STEPS.UNIT_CLASS)}
        >
          <UnitClass
            isViewMode={isViewMode}
            isEditMode={isEditMode}
            fundId={fundId}
            fundDetail={fundDetailStep0}
            unitPriceRounding={unitPriceRounding}
            investorUnitRounding={investorUnitRounding}
          />
        </FormSection>
      ),
    },
    {
      key: UPSERT_FUND_STEPS.TEAM,
      label: 'Team',
      content: (
        <FormProvider {...teamForm}>
          <FormSection
            title='Team'
            isLastStep
            createBtnText='Create'
            {...getDefaultStepProps(UPSERT_FUND_STEPS.TEAM)}
          >
            <Teams
              isDraft={fundStatus.isDraft}
              currentUser={currentUser}
              isViewMode={isViewMode}
              isEditMode={isEditMode}
              teamUsers={teamUsers}
              fundId={fundId}
            />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {isViewMode ? 'View Fund' : isEditMode ? 'Edit Fund' : 'Create New Fund'}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 5 }}
            onClickStep={handleClickStep}
            errorSteps={!(!isCreateMode && !fundStatus.isDraft) ? errorSteps : []}
            enableClickStep={!isCreateMode && !fundStatus.isDraft}
            onStepChange={handleStepChanged}
            numItemsOnStep={4}
            destroyInactiveStep={false}
          />
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          isError={alertInfo?.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              !alertInfo?.isError && onClose?.(false);
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default UpsertFundsForm;
