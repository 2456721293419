/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { DocsIcon } from 'src/assets/icons/DocsIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import JpgIcon from 'src/assets/icons/JpgIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import PngIcon from 'src/assets/icons/PngIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import DownloadLocalButton from 'src/components/atoms/DownloadLocalButton';
import FormInput from 'src/components/atoms/FormInput';
import UploadButton from 'src/components/atoms/UploadButton';
import ActionMenus, { ActionMenusHandler } from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
  INVESTMENT_AMOUNT_REQUIRED,
  MAX_ADDITIONAL_DOCUMENT_UPLOAD,
} from 'src/constants/applications';
import { FileTypeEnum } from 'src/constants/common';
import { UnitClassInvestorType } from 'src/constants/unit-class';
import { detectPortalType } from 'src/helpers/common';
import {
  useDeleteAdditionalDocument,
  useDeleteVerifiedIdDocument,
  useDownloadAdditionalDocument,
  useGetInvestmentEntityDocuments,
  useGetWholesaleCertificate,
} from 'src/modules/applications/hooks';
import {
  IAddtionalDocument,
  IApplicationAddtional,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useDownloadFileFromArrayBuffer, useUploadDocument } from 'src/modules/common/hooks';
import { IAlertInfo, IDocument } from 'src/modules/common/type';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import SelectExistingDocuments from './SelectExistingDocuments';

interface IDocumentUploadProps extends IUpsertInvestmentApplicationForm {
  investorType?: number | null;
  investmentAmount?: number;
  applicationReviewDocument?: {
    enable: boolean;
    data?: IDocument;
    setData: (_data: IDocument) => void;
    removeDocument: (_id: string) => void;
  };
}

interface ITableData extends IAddtionalDocument {
  localUrl?: string;
}

const WHOLESALE_CERTIFICATE_FILE_NAME = 'Wholesale Investor Certificate.docx';

const DocumentUpload: FC<IDocumentUploadProps> = ({
  investorType,
  investmentAmount = 0,
  id = '',
  isViewMode,
  investmentEntityId = '',
  currentStep,
  isExistingInvestmentEntity,
  applicationReviewDocument,
  isDisabledEdit,
  isCreateFundApplicationForm,
}) => {
  const actionMenusRef = useRef<ActionMenusHandler>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const { isInvestor } = detectPortalType();

  const isRequiredCertificate =
    UnitClassInvestorType.Wholesale === investorType &&
    investmentAmount < INVESTMENT_AMOUNT_REQUIRED;

  const { mutate: uploadDocument, isLoading: isUploadingDocument } = useUploadDocument();
  const { data: currentUser } = useGetUserInfo();
  const { mutate: downloadWholesaleCertificate, isLoading: isDownloading } =
    useDownloadFileFromArrayBuffer();
  const { mutate: deleteDocumentMutate } = useDeleteAdditionalDocument();
  const { mutate: deleteVerifiedDocument, isLoading: isDeletingDocument } =
    useDeleteVerifiedIdDocument();
  const { mutate: downloadDocument } = useDownloadAdditionalDocument();
  const { data: wholesaleCertificateData } = useGetWholesaleCertificate(id, isRequiredCertificate);
  const { data: investmentEntityDocuments } = useGetInvestmentEntityDocuments(
    investmentEntityId,
    currentStep === INVESTMENT_APPLICATION_STEP.ADDITIONAL,
  );

  const {
    control,
    watch,
    setValue,
    formState: {},
  } = useFormContext<IApplicationAddtional>();

  const {
    fields: documents,
    append: addDocument,
    remove: removeDocument,
  } = useFieldArray({
    control,
    name: 'documents',
    keyName: 'key',
  });

  const {
    fields: existingDocuments,
    append: addExistingDocuments,
    remove: removeExistingDocuments,
  } = useFieldArray({
    control,
    name: 'existingDocuments',
    keyName: 'key',
  });

  const {
    fields: fundApplicationDocuments,
    append: addFundApplicationDocument,
    remove: removeFundApplicationDocument,
  } = useFieldArray({
    control,
    name: 'fundApplicationDocuments',
    keyName: 'key',
  });

  const {
    fields: verifiedIdDocuments,
    append: addVerifiedIdDocuments,
    remove: removeVerifiedIdDocuments,
  } = useFieldArray({
    control,
    name: 'verifiedIdDocuments',
    keyName: 'key',
  });

  const watchDescription = watch('description');

  useEffect(() => {
    if (applicationReviewDocument?.setData) {
      applicationReviewDocument.setData(fundApplicationDocuments?.[0]);
    }
  }, [fundApplicationDocuments]);

  const handleAddDocument = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    uploadDocument(file, {
      onSuccess: (data: IAddtionalDocument) => {
        handleShowAlertModal({
          title: 'You did it !',
          description: 'File uploaded successfully.',
          isError: false,
        });

        const newData = {
          ...data,
          uploadedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
          uploadedDate: dayjs().toString(),
          description: watchDescription,
          isAddNew: true,
          localUrl: URL.createObjectURL(file),
        };

        if (applicationReviewDocument?.enable) {
          addFundApplicationDocument(newData);
        } else {
          addDocument(newData);
        }

        setValue('description', '');
      },
      onError: handleErrorFromServer,
    });
  };

  const handleShowAlertModal = (alertInfo: IAlertInfo) => {
    setAlertInfo(alertInfo);
    alertRef.current?.open();
  };

  const handleDownloadCertificate = () => {
    downloadWholesaleCertificate(
      {
        data: wholesaleCertificateData?.data,
        fileName: WHOLESALE_CERTIFICATE_FILE_NAME,
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const checkIsExistingDocument = (document: IAddtionalDocument) =>
    (existingDocuments || []).map((it) => it?.id).includes(document?.id);

  const checkIsVerifiedIdDocument = (document: IAddtionalDocument) =>
    (verifiedIdDocuments || []).map((it) => it?.id).includes(document?.id);

  const handleDeleteDocument = (row: ITableData, index: number) => {
    const isExistingDocument = checkIsExistingDocument(row);
    const isVerifiedIdDocument = checkIsVerifiedIdDocument(row);

    if (isVerifiedIdDocument) {
      deleteVerifiedDocument(
        {
          appId: id,
          documentId: row.id || '',
        },
        {
          onSuccess: () => removeVerifiedIdDocuments(index),
          onError: handleErrorFromServer,
        },
      );
      return;
    }

    if (isExistingDocument) {
      handleRemoveDoc(row);
      return;
    }

    if (applicationReviewDocument?.enable) {
      if (row?.id) {
        applicationReviewDocument?.removeDocument(row.id || '');
      } else {
        removeFundApplicationDocument(fundApplicationDocuments.length - 1);
      }
      return;
    }
    if (row?.id) {
      deleteDocumentMutate(
        { applicationId: id, data: { documentId: row.id } },
        {
          onSuccess: () => {
            removeDocument(index);
          },
          onError: handleErrorFromServer,
        },
      );
    } else {
      removeDocument(index);
    }
  };

  const handleAddExistingDoc = (item: IAddtionalDocument) =>
    addExistingDocuments({
      ...item,
      uploadedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
    });

  const handleRemoveDoc = (item: IAddtionalDocument) => {
    const itemIndex = existingDocuments?.findIndex((f) => f.id === item.id);
    itemIndex !== -1 && removeExistingDocuments(itemIndex);
  };

  const fileColumns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable className='line-clamp-1' value={row.description || ''} />
      ),
    },
    {
      title: 'File Name',
      key: 'fileName',
      sx: { width: '23%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(row.fileType);
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='line-clamp-1 pl-2 break-all' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File Size',
      key: 'fileSize',
      sx: { width: '18%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Date Uploaded',
      key: 'dateUploaded',
      sx: { width: '17%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={String(utcToLocalTimezone(row.uploadedDate || ''))} />
      ),
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      sx: { width: '23%' },
      renderNode: (row: ITableData, index: number) => {
        const menus = [
          {
            icon: (
              <DownloadLocalButton downloadUrl={row?.localUrl || ''} fileName={row?.fileName || ''}>
                <DownloadIcon width='16' height='16' />
              </DownloadLocalButton>
            ),
            label: (
              <DownloadLocalButton
                downloadUrl={row?.localUrl || ''}
                fileName={row?.fileName || ''}
                sx={{
                  width: '100%',
                }}
              >
                Download
              </DownloadLocalButton>
            ),
            onAction: () => {
              if (!row.localUrl) {
                return downloadDocument({
                  id,
                  fileName: row.fileName,
                  documentId: row.id || '',
                  type: row.documentType,
                });
              }
              return;
            },
          },
          ...(((applicationReviewDocument?.data?.id === row.id ||
            (index + 1 ===
              documents.length + existingDocuments.length + fundApplicationDocuments.length &&
              !!fundApplicationDocuments.length)) &&
            applicationReviewDocument?.enable) ||
          (!isViewMode &&
            !applicationReviewDocument &&
            (checkIsExistingDocument(row) ||
              checkIsVerifiedIdDocument(row) ||
              !isDisabledEdit ||
              row?.isAddNew))
            ? [
                {
                  icon: <DeleteIcon />,
                  label: 'Delete',
                  onAction: () => handleDeleteDocument(row, index),
                },
              ]
            : []),
        ];
        return (
          <Box className='flex items-center justify-between'>
            <StringNodeTable value={String(row.uploadedBy || '')} />
            <ActionMenus ref={actionMenusRef} menus={menus} />
          </Box>
        );
      },
    },
  ];

  const renderRequireDocs = () => (
    <Box mt={4}>
      <Typography variant='body2' fontWeight='medium'>
        Download Wholesale Investor Certificate Template
      </Typography>
      <Box className='px-4 py-5 mt-2 flex justify-between items-center' bgcolor='neutral.ne100'>
        <Box className='flex items-center'>
          <DocsIcon width={32} height={32} />
          <Box paddingLeft={1}>
            <Typography variant='body2'>{WHOLESALE_CERTIFICATE_FILE_NAME}</Typography>
            <Typography variant='body3' color='neutral.ne800'>
              {Math.ceil((wholesaleCertificateData?.fileSize || 0) / 1024)}KB
            </Typography>
          </Box>
        </Box>
        <CustomButton
          size='small'
          onClick={handleDownloadCertificate}
          isLoading={isDownloading}
          sx={(theme) => ({
            bgcolor: theme.palette.neutral.ne200,
            color: theme.palette.primary.main,
            padding: '5px 10px',
            height: 'fit-content',
            borderRadius: '4px',
            '&:hover': {
              color: theme.palette.base.white,
            },
          })}
        >
          Download
        </CustomButton>
      </Box>
    </Box>
  );

  const renderRequiredAlert = () => (
    <CustomAlert
      severity='info'
      sx={{
        mb: 3,
      }}
    >
      Please upload your wholesale investor certificate. Alternatively, it may impact application
      approval and processing time.
    </CustomAlert>
  );

  return (
    <>
      <Box className='mx-[-24px] pt-6'>
        {isRequiredCertificate &&
          (isInvestor || isCreateFundApplicationForm) &&
          renderRequiredAlert()}
        {isExistingInvestmentEntity && (isInvestor || isCreateFundApplicationForm) && (
          <>
            <SelectExistingDocuments
              selectedDocs={existingDocuments}
              documents={investmentEntityDocuments}
              isViewMode={isViewMode}
              onAddNew={handleAddExistingDoc}
              onRemove={handleRemoveDoc}
            />
            <Typography className='mb-4' variant='body2' fontWeight={500}>
              Upload New
            </Typography>
          </>
        )}
        <Box className='flex items-end justify-between'>
          <Box width='76%'>
            <FormInput
              name='description'
              label='Description'
              placeholder='Enter description'
              disabled={isViewMode && !applicationReviewDocument?.enable}
            />
          </Box>
          <Box width='22%'>
            <UploadButton
              startIcon={<UploadFilesIcon />}
              label={'Upload Files'}
              inputProps={{
                accept: ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
              }}
              disabled={
                isUploadingDocument ||
                !watchDescription ||
                (isViewMode && !applicationReviewDocument?.enable) ||
                // if has applicationReviewDocument, allow upload only 1 document
                (applicationReviewDocument?.enable && !!applicationReviewDocument?.data)
              }
              handleFileChange={handleAddDocument}
              validate={{
                fileSize: MAX_ADDITIONAL_DOCUMENT_UPLOAD,
                onError: () =>
                  handleShowAlertModal({
                    title: '',
                    isError: true,
                    description: `Selected file exceeds the maximum allowed size of 20 MB.<br />Please choose a different file or reduce its size.`,
                  }),
              }}
              sx={{
                px: 2,
                width: '100%',
              }}
            />
          </Box>
        </Box>
        <Box className='pt-10'>
          <Typography className='pb-2' variant='body2' fontWeight='medium'>
            Uploaded Files
          </Typography>
          <CustomTableContainer>
            <CustomTable
              rows={[
                ...(documents || []).filter((item) => typeof item?.letterType !== 'number'),
                ...(existingDocuments || []),
                ...(fundApplicationDocuments || []),
                ...(verifiedIdDocuments || []),
              ]}
              columns={fileColumns}
              hasPagination={false}
              stickyHeader
              displayEmpty
              hiddenHorizontalRule
              customEmpty={
                <EmptyData
                  isTable
                  description='No document.'
                  icon={<DocumentOnePageIcon width={22} height={27} />}
                  marginY='34px'
                />
              }
              selectedRows={[]}
            />
          </CustomTableContainer>
        </Box>
        {isRequiredCertificate && renderRequireDocs()}
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          isError={alertInfo?.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default DocumentUpload;

export const getFileTypeIcon = (fileType: number, size?: number) => {
  const iconSize = size || 20;
  switch (fileType) {
    case FileTypeEnum.doc:
    case FileTypeEnum.docx:
      return <DocsIcon width={iconSize} height={iconSize} />;
    case FileTypeEnum.jpeg:
    case FileTypeEnum.jpg:
      return <JpgIcon />;
    case FileTypeEnum.pdf:
      return <PDFIcon width={iconSize} height={iconSize} />;
    case FileTypeEnum.png:
      return <PngIcon />;
    default:
      return <></>;
  }
};
