/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomBreadcrumbs from 'src/components/atoms/CustomBreadcrumbs';
import CustomButton from 'src/components/atoms/CustomButton';
import Header from 'src/components/molecules/Header';
import Sidebar from 'src/components/molecules/Sidebar';
import DuplicateEntity from 'src/components/pages/applications/components/DuplicateEntity';
import NewHoldingPopup from 'src/components/pages/unallocated-holding/NewHoldingPopup';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { detectPortalType } from 'src/helpers/common';
import * as StorageService from 'src/helpers/storage';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { useRole } from 'src/hooks/useRole';
import { useGetDuplicatedEntity, useRejectDuplicatedEntity } from 'src/modules/applications/hooks';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { InvestorViewModeContext } from 'src/providers/InvestorViewModeProvider';
import NotificationContextProvider from 'src/providers/NotificationContextProvider';
import { appRoutes } from 'src/routes/routes';
import { handleErrorFromServer } from 'src/utils/common';

interface ILayoutProps {
  children: ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const newHoldingRef = useRef<IBasicModalElement>(null);
  const duplicateEntityModalConfirmRef = useRef<IBasicModalElement>(null);
  const addUserModalRef = useRef<IBasicModalElement>(null);

  const [isAllowed, setIsAllowed] = useState(false);
  const location = useLocation();
  const navigate = useNavigateWithParams();
  const { allowedPrivatePaths } = useRole();
  const pathname = location.pathname;
  const offerCode = StorageService.getValue(STORAGE_KEYS.OFFER_CODE);
  const { isInvestor, isFunds } = detectPortalType();
  const { disableShowDuplicationPopup } = useContext(InvestorViewModeContext);

  const { data: userProfile } = useGetUserInfo();
  const { duplicateApplication } = userProfile || {};

  const { data: duplicatedEntity } = useGetDuplicatedEntity(
    duplicateApplication?.hasDuplicate ? duplicateApplication?.applicationId : '',
  );
  const { mutate: rejectDuplicatedEntity, isLoading: rejectingDuplicatedEntity } =
    useRejectDuplicatedEntity();

  const handleCheckPermission = (allowedPrivatePaths: string[]) => {
    if (isEmpty(allowedPrivatePaths)) return;
    const publicPaths = appRoutes.filter((it) => !it?.isProtected).map((it) => it.path);
    const isAllowed = allowedPrivatePaths.includes(pathname) || publicPaths?.includes(pathname);
    isAllowed
      ? setIsAllowed(isAllowed)
      : pathname === ROUTES_PATH.HOME
      ? navigate(allowedPrivatePaths[0])
      : navigate(ROUTES_PATH.FORBIDDEN);
  };

  useEffect(() => {
    handleCheckPermission(allowedPrivatePaths);
  }, [allowedPrivatePaths, pathname]);

  /**
   * Existing Offer Code:
   * 1. Navigate to Offer Detail screen
   * 2. Clear Storate
   */
  useEffect(() => {
    if (isInvestor && offerCode) {
      navigate(ROUTES_PATH.INVESTOR_OFFER_DETAILS, { offerCode });
      StorageService.destroyValue(STORAGE_KEYS.OFFER_CODE);
    }
  }, [offerCode]);

  useEffect(() => {
    if (isInvestor && !isEmpty(userProfile?.unallocatedHolding) && location.pathname === '/') {
      newHoldingRef.current?.open();
    }
  }, [userProfile]);

  const handleLinkNow = () => {
    newHoldingRef.current?.close();
    navigate(ROUTES_PATH.UNALLOCATED_HOLDING);
  };

  useEffect(() => {
    /**
     * Only show the popup after user logs in
     * Prevent the popup is shown every time user reload browser
     */
    const isShowDuplicateAppPopup = StorageService.getValue(
      STORAGE_KEYS.IS_SHOW_DUPLICATE_APP_POPUP,
    );
    if (
      duplicatedEntity &&
      duplicateApplication?.hasDuplicate &&
      !disableShowDuplicationPopup &&
      isShowDuplicateAppPopup
    ) {
      duplicateEntityModalConfirmRef.current?.open();
      StorageService.setValue(STORAGE_KEYS.IS_SHOW_DUPLICATE_APP_POPUP, '');
    }
  }, [duplicatedEntity, disableShowDuplicationPopup]);

  const handleCloseAddUserModal = () => {
    addUserModalRef.current?.close();
  };

  const handleRejectDuplicateEntity = () => {
    rejectDuplicatedEntity(duplicateApplication?.applicationId || '', {
      onSuccess: () => duplicateEntityModalConfirmRef.current?.close(),
      onError: handleErrorFromServer,
    });
  };

  return (
    <Box className='flex min-h-screen'>
      <Sidebar />
      <Box className='flex flex-col flex-1 ml-[260px] overflow-x-hidden'>
        <NotificationContextProvider>
          <Header />
        </NotificationContextProvider>
        <Box
          className={clsx('p-6 flex-1', isFunds || isInvestor ? 'mt-[104px]' : 'mt-[72px]', {
            'min-[960px]:mt-[72px]': isFunds,
            'min-[1125px]:mt-[72px]': isInvestor,
          })}
          bgcolor='neutral.ne100'
        >
          <Box
            className={clsx('w-full h-full rounded-[10px] p-6 flex flex-col', {
              'bg-white': pathname !== '/',
            })}
          >
            <CustomBreadcrumbs />
            {isAllowed ? children : <Box className='w-screen h-screen' />}
          </Box>
        </Box>
      </Box>
      <BasicModal ref={newHoldingRef}>
        <NewHoldingPopup
          onLinkNow={handleLinkNow}
          onCancel={() => {
            newHoldingRef.current?.close();
          }}
          data={userProfile?.unallocatedHolding}
        />
      </BasicModal>
      <ConfirmModal
        ref={duplicateEntityModalConfirmRef}
        title='Action Required!'
        content={`<span style="white-space: pre-line">A new application has been initiated by ${duplicatedEntity?.firstName} ${duplicatedEntity?.lastName} for ${duplicatedEntity?.entityName}.\n${duplicatedEntity?.firstName} ${duplicatedEntity?.lastName} is currently not part of your team, and application cannot proceed without your authority.</span>`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleRejectDuplicateEntity}
              disabled={rejectingDuplicatedEntity}
            >
              Reject
            </CustomButton>
            <CustomButton
              onClick={() => {
                duplicateEntityModalConfirmRef?.current?.close();
                addUserModalRef.current?.open();
              }}
              disabled={rejectingDuplicatedEntity}
            >
              Accept
            </CustomButton>
          </>
        }
      />
      <BasicModal
        ref={addUserModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={handleCloseAddUserModal}
      >
        <DuplicateEntity
          onClose={handleCloseAddUserModal}
          appId={duplicateApplication?.applicationId || ''}
        />
      </BasicModal>
    </Box>
  );
};

export default Layout;
