import { FC, SVGProps } from 'react';

interface ICircleCheckIconProps extends SVGProps<SVGSVGElement> {}

const OutlinedCircleCheckIcon: FC<ICircleCheckIconProps> = ({
  width = 34,
  height = 34,
  ...props
}) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.01'
        x='0.333252'
        y='0.333008'
        width='33.3333'
        height='33.3333'
        fill='#C2C4C5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11084 16.9992C3.11084 9.32862 9.32911 3.11035 16.9997 3.11035C20.6833 3.11035 24.216 4.57364 26.8207 7.17831C29.4253 9.78298 30.8886 13.3157 30.8886 16.9992C30.8886 24.6699 24.6703 30.8881 16.9997 30.8881C9.32911 30.8881 3.11084 24.6699 3.11084 16.9992ZM5.88851 16.999C5.88851 23.1355 10.8631 28.1101 16.9996 28.1101C19.9465 28.1101 22.7726 26.9394 24.8564 24.8557C26.9401 22.772 28.1107 19.9458 28.1107 16.999C28.1107 10.8625 23.1361 5.88786 16.9996 5.88786C10.8631 5.88786 5.88851 10.8625 5.88851 16.999Z'
        fill='#C2C4C5'
      />
      <path
        d='M20.75 11.9857L15.5 18.9301L13.2361 15.9996C12.7643 15.3936 11.8907 15.2848 11.2847 15.7565C10.6787 16.2282 10.5699 17.1019 11.0416 17.7079L14.4166 22.0273C14.6817 22.3627 15.0864 22.5574 15.5139 22.5551C15.9437 22.5541 16.3489 22.3541 16.6111 22.0134L22.9583 13.6801C23.4262 13.0665 23.3081 12.1897 22.6944 11.7218C22.0808 11.2539 21.204 11.372 20.7361 11.9857H20.75Z'
        fill='#C2C4C5'
      />
    </svg>
  );
};

export default OutlinedCircleCheckIcon;
