/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  autofix/no-unused-vars*/
import { get, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { actionPermissionKeys } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { SUBSCRIPTION_FEATURE_TYPE } from 'src/constants/subscription-billing';
import { getNavigationList } from 'src/helpers/navigate';
import { IAction } from 'src/interfaces/common';
import { INavigationItem, ISubNavigationItem } from 'src/interfaces/navigation';
import { useAuthenticated, useGetPortalInfo } from 'src/modules/auth/hooks';
import { useGetUserRole } from 'src/modules/role-management/hook';
import { IFeature, IPermissions } from 'src/modules/role-management/type';

interface IUseRole {
  allowedNavigationList: INavigationItem[];
  allowedPrivatePaths: string[];
  permissions: IPermissions | undefined;
  canCreate: boolean;
  canEdit: boolean;
  canDownload: boolean;
  canDelete?: boolean;
  filterActions: (actions: IAction[], isTempLogic?: boolean) => IAction[];
  viewOnly: boolean;
}
let preAllowedNavigationList: INavigationItem[] = [];
export const useRole = (pathname?: string): IUseRole => {
  const [allowedNavigationList, setAllowedNavigationList] = useState<INavigationItem[]>([]);
  const [allowedPrivatePaths, setAllowedPrivatePaths] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<IPermissions>();

  const { isAuthenticated } = useAuthenticated();
  const { data } = useGetUserRole(isAuthenticated);
  const { data: portalInfo } = useGetPortalInfo(isAuthenticated);

  const viewOnly = useMemo(() => {
    const allowedKeys = Object.keys(permissions || {}).filter((key) => get(permissions, key));

    return allowedKeys.length === 1 && allowedKeys[0] === actionPermissionKeys.isView;
  }, [permissions]);

  useEffect(() => {
    if (isEmpty(data)) return;
    getAllowedNavigationList();
  }, [data, portalInfo]);

  useEffect(() => {
    if (allowedNavigationList.length) preAllowedNavigationList = allowedNavigationList;
    if (isEmpty(allowedNavigationList)) return;
    getAllowedPrivatePaths(allowedNavigationList);
  }, [allowedNavigationList]);

  useEffect(() => {
    if (!pathname) return;
    const permissions = getPermissions(pathname);
    setPermissions(permissions);
  }, [pathname, allowedNavigationList]);

  const filterAllowedNavigationList = (
    navigationList: INavigationItem[],
    featureNames: string[],
  ) => {
    const notSubscription = (item: INavigationItem | ISubNavigationItem) => {
      const isSpecialCase =
        item.subscriptionType === SUBSCRIPTION_FEATURE_TYPE.FundraisingKYCBackOffice &&
        portalInfo?.isKYCBOProcessing;

      const isNormalCase =
        (typeof item.subscriptionType === 'number' || typeof item.subscriptionType === 'string') &&
        portalInfo?.subscriptions?.length &&
        !portalInfo?.subscriptions.find(
          (it) => it.featureType === item.subscriptionType || it.ref === item.subscriptionType,
        );

      return !!isNormalCase && !isSpecialCase;
    };

    const allowedNavigationList = navigationList.reduce(
      (acc: INavigationItem[], currentItem: INavigationItem, index: number) => {
        const result = [...acc];
        const newItem = currentItem;

        if (notSubscription(newItem)) {
          return result;
        }

        // Handle for submenu case
        if (newItem?.submenu) {
          const newSubmenu = [...newItem.submenu].filter((item) => {
            let isAllowed =
              featureNames.includes(item.name || '') ||
              newItem?.skipPermission ||
              item?.skipPermission;

            if (notSubscription(item)) {
              isAllowed = false;
            }
            // Handle bulk upload permissions
            if (item.path === ROUTES_PATH.BULK_UPLOAD) {
              // Use same permissions with administration investors
              const permissions = getPermissions(ROUTES_PATH.ADMINISTRATION_INVESTORS);
              isAllowed = !!permissions?.isCreate || !!permissions?.isFullAdmin;
            }

            return isAllowed;
          });

          console.log('newSubmenu', newSubmenu);

          if (!isEmpty(newSubmenu)) {
            result.push({
              ...newItem,
              submenu: newSubmenu,
            });
          }
        } else if (
          featureNames.includes(newItem.name || '') ||
          index === 0 ||
          newItem?.skipPermission
        ) {
          result.push(newItem);
        }

        return result;
      },
      [],
    );

    return allowedNavigationList;
  };

  console.log('allowedNavigationList', allowedNavigationList);

  const getAllowedNavigationList = () => {
    const navigationList = getNavigationList();
    // If all permissions are false, remove that feature
    const features = data?.features.filter((feature) =>
      Object.entries(feature.permissions).some((item) => item[1]),
    );
    const featureNames = features?.map((feature: IFeature) => feature.name) || [];

    const allowedNavigationList: INavigationItem[] = filterAllowedNavigationList(
      navigationList,
      featureNames,
    );

    setAllowedNavigationList(allowedNavigationList);
  };

  const getAllowedPrivatePaths = (allowedNavigationList: INavigationItem[]) => {
    const allowedPrivatePaths = allowedNavigationList.reduce(
      (prev: string[], currentItem: INavigationItem) => {
        let paths = [...prev];

        if (currentItem?.submenu) {
          const submenuPaths = currentItem.submenu.map((submenuItem) => submenuItem.path);
          paths = [...paths, ...submenuPaths];
        } else {
          paths = [...paths, ...(currentItem.subPath || []), currentItem.path];
        }

        return [...paths];
      },
      [],
    );

    setAllowedPrivatePaths(allowedPrivatePaths);
  };

  const getPermissions = (pathname: string) => {
    const navigationItem = allowedNavigationList.find((item) => {
      if (item?.submenu) {
        const subNavItem = item.submenu.find((subItem) => subItem.path === pathname);
        return Boolean(subNavItem);
      }

      return item.path === pathname;
    });

    if (!navigationItem) return;

    const feature = data?.features.find((item) => {
      // Handle find in submenu
      if (navigationItem?.submenu) {
        const subItem = navigationItem.submenu.find((sub) => sub.path === pathname);

        return subItem?.name === item.name;
      }

      return item.name === navigationItem.name;
    });

    return feature?.permissions;
  };

  const permissionActionCheck = (action: IAction) => {
    if (Array.isArray(action?.permissionKey)) {
      return action.permissionKey?.some((it) => get(permissions, it || ''));
    }

    return get(permissions, action?.permissionKey || '');
  };

  const filterActions = (actions: IAction[], isTempLogic = false) => {
    let newActions = [...actions];
    if (permissions?.isFullAdmin) return newActions;

    newActions = [...actions].filter((action: IAction) => {
      // Need to enhance later
      if (isTempLogic && action.key === 'edit') {
        return (
          permissionActionCheck(action) || action.skipPermission || get(permissions, 'isCreate')
        );
      }
      if (isTempLogic && action.key === 'view') {
        return (
          permissionActionCheck(action) ||
          action.skipPermission ||
          get(permissions, 'isCreate') ||
          get(permissions, 'isEdit') ||
          get(permissions, 'isDownload') ||
          get(permissions, 'isDelete')
        );
      }
      return permissionActionCheck(action) || action.skipPermission;
    });

    return newActions || [];
  };

  return {
    allowedPrivatePaths,
    allowedNavigationList: allowedNavigationList.length
      ? allowedNavigationList
      : preAllowedNavigationList,
    permissions,
    canCreate: !!permissions?.isFullAdmin || !!permissions?.isCreate,
    canEdit: !!permissions?.isFullAdmin || !!permissions?.isEdit,
    canDownload: !!permissions?.isFullAdmin || !!permissions?.isDownload,
    canDelete: !!permissions?.isFullAdmin || !!permissions?.isDelete,
    viewOnly,
    filterActions,
  };
};
